import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ResidentialInformation from './ResidentialInformation';
import PersonalDetailsForm from './PersonalDetails';
import VehicleDetailsForm from './VehicleDetails';
import DocumentGallery from '../DocumentGallery';
import { updateInsuranceDetails, leadListData, setInboundPaymentFromResponse } from "@src/store/slices/insurance";
import LeadRemarksButton from '../../common/LeadRemarksButton';
import { STATUS_SUB_STATUS, APP_TYPE_WITH_UCF, ROLE_FEATURES, INS_TYPE_COMPREHENSIVE, REGEX_EMAIL_VALIDATION, PAYMENT_TERM_INSTALLMENT, APP_TYPE_STANDALONE, APP_TYPE_RENEWAL, ENCRYPTED_FIELDS, INBOUND_PAYMENT_FROM_CUSTOMER, INBOUND_PAYMENT_FROM_SALES_TEAM, INBOUND_PAYMENT_FROM_BROKER, APP_TYPE_AGENCY, INBOUND_PAYMENT_FROM_OTO_INSURANCE, INBOUND_PAYMENT_FROM_INSURANCE_PARTNER, AGENCY_STATUS } from "@src/config/constant";
import * as HELPER from "@src/config/helper";
import OtherDetailsForm from './OtherDetails';
import { updateLeadTemplateData } from '@src/store/slices/insurance'
import FullPageLoader from '@src/common/FullPageLoader';
import { getDocumentListing } from '@src/store/slices/common';
import { times } from 'lodash';
import { toast } from 'react-toastify';
interface PersonalDetails {
    customer_name: string,
    alternate_mobile: string,
    mobile: string,
    email: string,
    dob: string,
    customer_type: number,
    payment_method_id: number,
    payment_term_id: number,
    copy_of_insurance: number,
    payment_tenure_id: number,
    expected_installment: object,
    actual_installment: object,
    paid_to_whom: number | string,
    premium_amount: number | string,
    premium_amount_date: string,
    credit_term: number | string;
    expected_credit_term: number | string;
    payment_reference_no: number | string;
    expected_installment_reference_no: object;
    payment_way: number | string;
    paid_from_agency: number | string;
}
interface ResidentialDetails {
    per_house_no: string,
    per_street: string,
    per_region: number,
    per_city: string,
    per_barangay: string,
    per_postal_code: string,
    cur_house_no: string,
    cur_street: string,
    cur_region: string,
    cur_city: string,
    cur_barangay: string,
    cur_postal_code: string,
    per_sameas_cur: number,
    per_full_address: string, 
    cur_full_address: string

}

interface VehicleDetails {
    plate_no: string,
    color: string,
    chassis_number: string,
    machine_number: string,
    fuel_type: string,
    transmission: string,
    seat_capacity: number,
    mv_number:string

}

interface OtherDetails {
    is_under_financing: number, 
    financier_id: number, 
    financing_from_date: string, 
    financing_to_date: string, 
    insurance_exists: number, 
    insurer_name: string, 
    insurance_expire_date: string,
    lead_source: number,
    insurance_lead_source:any,
    other_doc:any,
    referrer_name:any,
    referrer_mobile:any,
    other_detail_dealer_id:any,
    lead_sub_source: number,
    paid_to_whom: number | string,
    paid_by_customer: number | string,
    paid_from_agency: number | string
}


export default function CustomerDetails(props: any): ReturnType<React.FC> {
    const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const insuranceState = useSelector((state: any) => state.insurance);
    const { application_type_id } = insuranceState?.leadDetail || {};
    const commonState = useSelector((state: any) => state.common);

    let [personalDetails, setPersonalDetails] = useState<any>({});
    let [residentialDetails, setResidentialDetails] = useState<any>({});
    let [vehicleDetails, setVehicleDetails] = useState<any>({});
    // eslint-disable-next-line
    let [disable, setDisabled] = useState<boolean>(false);
    let [errors, setErrors] = useState<any>({});
    let [otherDetails, setOtherDetails] = useState<any>({})
    let [originalOtherDetailsData, setOriginalOtherDetailsData] = useState<any>({})
    let [loading, setLoading] = useState<boolean>(false)

    const [insuranceDetails, setInsuranceDetails] = useState<any>({});

    let [permissonId, setPermissonId] = useState("");
    const [insuranceType, setInsuranceType] = useState<any>('');

    useEffect(() => {
        if (insuranceState?.leadDetail && Object.keys(insuranceState?.leadDetail).length) {
            let { customer_name, alternate_mobile, mobile, email, dob, customer_type, expected_payment_method_id, expected_payment_term_id, copy_of_insurance, plate_no, color, chassis_number, machine_number, fuel_type, transmission, vehicle_seat, per_house_no, per_street, per_region, per_city, per_barangay, per_postal_code, cur_house_no, cur_street, cur_region, cur_city, cur_barangay, cur_postal_code, per_sameas_cur, application_type_id, is_under_financing, financier_id, financing_from_date, financing_to_date, insurance_exists, insurer_name, insurance_expire_date,mv_number,insurance_type_id, lead_source, insurance_lead_source, expected_payment_tenure_id, other_doc, referrer_name, referrer_mobile,other_detail_dealer_id, expected_installment, lead_sub_source, paid_to_whom, paid_by_customer, premium_amount, premium_amount_date, credit_term, expected_credit_term, payment_reference_no, per_full_address, cur_full_address, expected_installment_reference_no, paid_from_agency, payment_way, actual_installment} = insuranceState?.leadDetail; 

            let details: PersonalDetails = { customer_name, alternate_mobile, mobile, email, dob, customer_type, payment_method_id: expected_payment_method_id, payment_term_id: expected_payment_term_id, copy_of_insurance, payment_tenure_id: expected_payment_tenure_id, expected_installment, paid_to_whom, premium_amount, premium_amount_date, credit_term, expected_credit_term, payment_reference_no, expected_installment_reference_no, payment_way, paid_from_agency, actual_installment };

            let vehicledetails: VehicleDetails = { plate_no, color, chassis_number, machine_number, fuel_type, transmission,mv_number, seat_capacity: vehicle_seat };

            let Residentialdetails: ResidentialDetails = { per_house_no, per_street, per_region, per_city, per_barangay, per_postal_code, cur_house_no, cur_street, cur_region, cur_city, cur_barangay, cur_postal_code, per_sameas_cur, per_full_address, cur_full_address };

            let OtherDetails: OtherDetails = { is_under_financing, financier_id, financing_from_date, financing_to_date, insurance_exists, insurer_name, insurance_expire_date, lead_source, insurance_lead_source ,other_doc, referrer_name, referrer_mobile, other_detail_dealer_id, lead_sub_source, paid_to_whom, paid_by_customer, paid_from_agency};
    
            let disabled = (application_type_id === APP_TYPE_WITH_UCF) ? true : false;
            setInsuranceDetails(insuranceState.leadDetail);
            setPersonalDetails({ ...details });
            setVehicleDetails({ ...vehicledetails });
            setResidentialDetails({ ...Residentialdetails });
            setDisabled(disabled)
            setInsuranceType(insurance_type_id);
            setOtherDetails({...OtherDetails})
            setOriginalOtherDetailsData({...OtherDetails})
        }
    }, [insuranceState.leadDetail]);
    
    useEffect(() => {
        
        if (props.formType === "personal-details") {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            permissonId = '' + ROLE_FEATURES['personal_details'];
        }
        else if (props.formType === "vehicle-details") {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            permissonId = '' + ROLE_FEATURES['vehicle_details'];

        } else if (props.formType === "residential-details") {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            permissonId = '' + ROLE_FEATURES['residential_details'];
        } else if (props.formType === "other-details") {
            permissonId = '' + ROLE_FEATURES['other_details'];
        } else if (props.formType === "payment-details") {
            permissonId = '' + ROLE_FEATURES['payment_details'];
        }
        setPermissonId(permissonId);

        //for reseting other details data to original data on tab change
        if(originalOtherDetailsData && Object.keys(originalOtherDetailsData).length) {
            setOtherDetails({...originalOtherDetailsData})
        }

    }, [props.formType])
   

    const changeHandler = (data: any, key: string) => {
        let formErrors = errors;
        if (key === 'personal-details') {
            setPersonalDetails({ ...data });
        } else if (key === 'vehicle-details') {
            setVehicleDetails({ ...data });
        } else if(key === 'residential-details'){
            setResidentialDetails({ ...data });
        } else {
            setOtherDetails({...data});
        }

        for (let field in data) {
            if (data[field]) {
                if (['expected_installment', 'actual_installment'].includes(field)) {
                    const insErr = errors[field]
                    const insField = data[field]
                    for (let key in insErr) {
                        insField[key]?.amount && delete insErr[key].amount;
                        insField[key]?.date && delete insErr[key].date;
                    }
                } else {
                    delete formErrors[field]
                }
            }
        }

        setErrors(formErrors);
    }
    /**
    * VALIDATION HANDLERS
    */
    const validateFormData = async (formFields: any) => {
        setErrors({})
        let formErrors: any = {};
        let emaialRegex = REGEX_EMAIL_VALIDATION
        let alphanumericPattern = /^[a-z0-9]+$/i;
        const isAgency = application_type_id === APP_TYPE_AGENCY

        if (formFields && formFields.hasOwnProperty('personalDetails')) {
            let showActualInstallment = props.formType === "payment-details"

            if (!formFields.personalDetails.customer_name || (formFields.personalDetails.customer_name && !formFields.personalDetails.customer_name.trim())) {
                formErrors.customer_name = 'Please Enter customer name';
            }
            // if app type is standalone then email is required
            if (!formFields.personalDetails.email || !formFields.personalDetails.email.trim()) {
                if (insuranceState?.leadDetail?.application_type_id === 2) {
                    formErrors.email = 'Please Enter email';
                }
            } else if (!emaialRegex.test(formFields.personalDetails.email)) {
                formErrors.email = 'Please Enter valid email';
            }        
            if ((application_type_id !== APP_TYPE_AGENCY && !formFields.personalDetails.mobile) || (formFields.personalDetails.mobile && (+formFields.personalDetails.mobile[0] !== 9 || formFields.personalDetails.mobile.length !== 10))) {
                formErrors.mobile = 'No. should start from 9 and of 10 digits.';
            }
            if ((formFields.personalDetails.alternate_mobile && (+formFields.personalDetails.alternate_mobile[0] !== 9 || formFields.personalDetails.alternate_mobile.length !== 10))) {
                formErrors.alt_mobile = 'No. should start from 9 and of 10 digits.';
            }

            if (!formFields.personalDetails.payment_method_id) {
                formErrors.payment_method_id = 'Please select payment method';
            }
            if (!formFields.personalDetails.payment_term_id) {
                formErrors.payment_term_id = 'Please select payment term';
            }
            if (!formFields.personalDetails.copy_of_insurance && insuranceState?.leadDetail?.application_type_id === APP_TYPE_WITH_UCF) {
                formErrors.copy_of_insurance = 'Please select physical copy of insurance required';
            }
            if (formFields.personalDetails.payment_term_id === 2 && !formFields.personalDetails.payment_tenure_id) {
                formErrors.payment_tenure_id = 'Please select payment tenure';
            }
            if (formFields.personalDetails.payment_tenure_id > 0 && [APP_TYPE_STANDALONE, APP_TYPE_RENEWAL, APP_TYPE_AGENCY].includes(insuranceState?.leadDetail?.application_type_id)) {
                const totalInstallments = commonState?.common?.payment_tenure?.find((pt: any) => formFields.personalDetails.payment_tenure_id === pt.value)?.label?.split(' ')?.[0]
                const expectedDateErr: any = {}
                const actualDateErr: any = {}
                const totalPremium = insuranceState?.leadDetail?.total_premium || 0
                let totalInsAmount = 0;
                let actualTotalAmount = 0;
                let totalActualInstallments = formFields?.personalDetails?.actual_installment ? Object.keys(formFields?.personalDetails?.actual_installment)?.filter((e: any) => {
                    let value = formFields?.personalDetails?.actual_installment?.[e]
                    return value.amount || value.date
                })?.length : 0
                let showActualInstallmentErrors = showActualInstallment && +totalActualInstallments === +totalInstallments
                //add errors to empty expected date
                let refNumUnique:any={}
                times(totalInstallments, (indx: number) => {
                    let value = (insuranceState.leadDetail.application_type_id === APP_TYPE_AGENCY) ? formFields.personalDetails?.expected_installment_reference_no?.[indx + 1] : formFields.personalDetails?.expected_installment?.[indx + 1]
                    let actualInstallmentValue = formFields.personalDetails?.actual_installment?.[indx + 1]

                    let errorObj: any = {}
                    let errorObjActualInstallment : any = {}
                    let errorFlag = false
                    totalInsAmount += +value?.amount || 0
                    actualTotalAmount += +actualInstallmentValue?.amount

                    if (showActualInstallment) {
                        if (actualInstallmentValue?.amount && +actualInstallmentValue?.amount === 0) {
                            errorObjActualInstallment['amount'] = `Amount Cannot be 0`
                            errorFlag = true
                        }
                    }

                    if (showActualInstallmentErrors) {
                        if (!actualInstallmentValue?.amount) {
                            errorObjActualInstallment['amount'] = `Please enter ${indx + 1} Actual Amount`
                            errorFlag = true
                        }

                        if (!actualInstallmentValue?.date) {
                            errorObjActualInstallment['date'] = `Please select ${indx + 1} Actual Date`
                            errorFlag = true
                        }

                    }

                    

                    if(insuranceState.leadDetail.application_type_id === APP_TYPE_AGENCY){

                        if(props.formType === "payment-details"){

                            if(value?.reference_no?.trim()){
                                refNumUnique[value?.reference_no] = !refNumUnique[value?.reference_no] ? 1 : refNumUnique[value?.reference_no]+1;
                                if(refNumUnique[value?.reference_no]>1){
                                    errorObj[`reference_no`] = `Reference no. should be unique`
                                    errorFlag = true

                                }
                            }

                            if ((!value?.date) && (value?.reference_no)) {
                                errorObj[`date`] = `Please select Date & Time ${indx + 1}`
                                errorFlag = true
                            }
                            
                            if (!value?.reference_no && value?.date) {
                                errorObj[`reference_no`] = `Please enter premium reference no ${indx + 1}`
                                errorFlag = true
                            }

                        }

                    }else{
                        if (!value?.amount) {
                            errorObj['amount'] = `Please enter ${indx + 1} Expected Amount`
                            errorFlag = true
                        }
    
                        if (+value?.amount === 0) {
                            errorObj['amount'] = `Amount Cannot be 0`
                            errorFlag = true
                        }
    
                        if (!value?.date) {
                            errorObj['date'] = `Please select ${indx + 1} Expected Date`
                            errorFlag = true
                        }
                    }

                    if (errorFlag) {
                        if(Object.keys(errorObj).length) {
                            expectedDateErr[indx + 1] = { ...errorObj }
                        } else if (Object.keys(errorObjActualInstallment).length) {
                            actualDateErr[indx + 1] = { ...errorObjActualInstallment }
                        }
                    }
                })
                
                if(totalPremium !== +(totalInsAmount).toFixed(2) && insuranceState.leadDetail.application_type_id !== APP_TYPE_AGENCY) {
                    toast.error("Sum of Expected Installments should be equal to Total Premium")
                    formErrors['error'] = true
                }

                if(showActualInstallment && +totalInstallments === +totalActualInstallments && +formFields.personalDetails.premium_amount !== +(actualTotalAmount).toFixed(2)) {
                    toast.error("Total Premium Amount should be equal to sum of Actual Installments")
                    formErrors['error'] = true
                }
                
                if (Object.keys(expectedDateErr).length) formErrors['expected_installment'] = { ...expectedDateErr }
                if (Object.keys(actualDateErr).length) formErrors['actual_installment'] = { ...actualDateErr }
            }

            if(props.formType === "payment-details"){
                if(insuranceState.leadDetail.application_type_id !== APP_TYPE_AGENCY){
                    if ((!formFields.personalDetails.premium_amount || +formFields?.personalDetails?.premium_amount === 0)) {
                        formErrors.premium_amount = 'Please enter total premium amount received';
                    }
                    if (!formFields.personalDetails.payment_tenure_id && !formFields.personalDetails.premium_amount_date) {
                        formErrors.premium_amount_date = 'Please select premium amount received date and time';
                    }
                }

                if (+formFields?.personalDetails?.premium_amount > +insuranceState?.leadDetail?.car_fmv) {
                    formErrors.premium_amount = "Total Premium Amount should not be greater than FMV"
                }

                // if (!formFields?.personalDetails?.credit_term) {
                //     formErrors.credit_term = "Total select credit term"
                // }

                // if (!formFields.personalDetails.expected_installment_reference_no && !formFields?.personalDetails?.payment_reference_no  && insuranceState.leadDetail.application_type_id === APP_TYPE_AGENCY) {
                //     formErrors.payment_reference_no = "Please enter payment reference no."
                // }
            }
            // return false
            if(insuranceState.leadDetail.application_type_id === APP_TYPE_AGENCY){
                if (+formFields?.personalDetails?.paid_from_agency === 1) {
                    if (!formFields?.personalDetails?.paid_to_whom) {
                        formErrors.paid_to_whom = "Please select paid to whom"
                    }
                    if (!formFields?.personalDetails?.payment_way) {
                        formErrors.payment_way = "Please select payment way"
                    }
                }
            }

            
        } else if (formFields && formFields.hasOwnProperty('vehicleDetails')) {

            if(insuranceType===INS_TYPE_COMPREHENSIVE){
                if (!formFields.vehicleDetails.plate_no || (formFields.vehicleDetails.plate_no && !formFields.vehicleDetails.plate_no.trim()) || !alphanumericPattern.test(formFields.vehicleDetails.plate_no)) {
                    formErrors.plate_no = 'Plate number should be alphanumeric and not blank';
                }
            }else{
                if(!formFields.vehicleDetails.plate_no && !formFields.vehicleDetails.mv_number){
                    formErrors.plate_no = 'Please enter Plate number or MV file number ';
                    formErrors.mv_number = 'Please enter Plate number or MV file number';
                }else if(formFields.vehicleDetails.plate_no && !alphanumericPattern.test(formFields.vehicleDetails.plate_no)){
                    formErrors.plate_no = 'Plate number should be alphanumeric and not blank';
                }
            }

            if (isAgency) {
                let keysToValidate = ['chassis_number', 'machine_number', 'transmission']
                keysToValidate.forEach((key: string) => {
                    if (!formFields?.vehicleDetails?.[key]) {
                        let errorKeyName = key.replace('_', " ")
                        let msg = `Please enter ${errorKeyName}`
                        if (key === 'transmission') {
                            msg = msg.replace('enter', 'select')
                        }

                        formErrors[key] = msg
                    }
                })
            }
            
        } else if (formFields && formFields.hasOwnProperty('residentialDetails')) {

            if ((!formFields.residentialDetails.per_full_address || (formFields.residentialDetails.per_full_address && !formFields.residentialDetails.per_full_address.trim())) && formFields.apptype === APP_TYPE_AGENCY) {
                formErrors.per_full_address = 'Please enter full address';
            }
            if (!formFields.residentialDetails.per_house_no || (formFields.residentialDetails.per_house_no && !formFields.residentialDetails.per_house_no.trim())) {
                formErrors.per_house_no = 'Please enter house no';
            }
            if (!formFields.residentialDetails.per_street || (formFields.residentialDetails.per_street && !formFields.residentialDetails.per_street.trim())) {
                formErrors.per_street = 'Please enter street';
            }
            if (!formFields.residentialDetails.per_region) {
                formErrors.per_region = 'Please enter region';
            }
            if (!formFields.residentialDetails.per_city) {
                formErrors.per_city = 'Please enter city';
            }

            if ((!formFields?.residentialDetails?.per_barangay || (formFields?.residentialDetails?.per_barangay && !('' + formFields?.residentialDetails?.per_barangay).trim()))) {
                formErrors.per_barangay = 'Please enter barangay';
            }
            if (!formFields.residentialDetails.per_postal_code || (formFields.residentialDetails.per_postal_code && !formFields.residentialDetails.per_postal_code.trim())) {
                formErrors.per_postal_code = 'Please enter postal code';
            }

            if ((!formFields.residentialDetails.cur_full_address || (formFields.residentialDetails.cur_full_address && !formFields.residentialDetails.cur_full_address.trim())) && formFields.apptype === APP_TYPE_AGENCY) {
                formErrors.cur_full_address = 'Please enter full address';
            }

            if (!formFields.residentialDetails.cur_house_no || (formFields.residentialDetails.cur_house_no && !formFields.residentialDetails.cur_house_no.trim())) {
                formErrors.cur_house_no = 'Please enter house no';
            }
            if (!formFields.residentialDetails.cur_street || (formFields.residentialDetails.cur_street && !formFields.residentialDetails.cur_street.trim())) {
                formErrors.cur_street = 'Please enter street';
            }
            if (!formFields.residentialDetails.cur_region) {
                formErrors.cur_region = 'Please enter region';
            }
            if (!formFields.residentialDetails.cur_city) {
                formErrors.cur_city = 'Please enter city';
            }
            if (!formFields.residentialDetails.cur_barangay) {
                formErrors.cur_barangay = 'Please enter barangay';
            }
            if (!formFields.residentialDetails.cur_postal_code || (formFields.residentialDetails.cur_postal_code && !formFields.residentialDetails.cur_postal_code.trim())) {
                formErrors.cur_postal_code = 'Please enter postal code';
            }

            if(isAgency && !formFields.residentialDetails.per_full_address) {
                formErrors.per_full_address = 'Please enter permanent address';
            }
            if(isAgency && !formFields.residentialDetails.cur_full_address) {
                formErrors.cur_full_address = 'Please enter current address';
            }
        } else if(formFields && formFields.hasOwnProperty('otherDetails')) {
            if(formFields.otherDetails.is_under_financing === 1) {
                if(!isAgency && !formFields.otherDetails.financier_id){
                    formErrors.financier_id = "Please select Financier"
                }
                if(!isAgency && !formFields.otherDetails.financing_from_date) {
                    formErrors.financing_from_date = "Please select financing from date"
                }
                if(!isAgency && !formFields.otherDetails.financing_to_date) {
                    formErrors.financing_to_date = "Please select financing till date"
                }
            }
            if(formFields.otherDetails.insurance_exists === 1) {
                if(!isAgency && !formFields.otherDetails.insurer_name){
                    formErrors.insurer_name = "Please enter Insurance Policy Insurer"
                }
                if(!isAgency && !formFields.otherDetails.insurance_expire_date) {
                    formErrors.insurance_expire_date = "Please select Insurance Policy Expiration Date"
                }
            }
            if (formFields.otherDetails.insurance_lead_source === 3 || formFields.otherDetails.insurance_lead_source === 4) {
                if (!formFields.otherDetails.referrer_name || formFields.otherDetails.referrer_name.trim() === "") {
                    formErrors.referrer_name = "Please enter Referrer Name"
                }
                if ((!formFields.otherDetails.referrer_mobile) || (formFields.otherDetails.referrer_mobile && (+formFields.otherDetails.referrer_mobile[0] !== 9 || formFields.otherDetails.referrer_mobile.length !== 10)  )) {
                    formErrors.referrer_mobile = 'No. should start from 9 and of 10 digits';
                }
            }
            if (formFields.otherDetails.insurance_lead_source === 5) {
                if (!formFields.otherDetails.referrer_name || formFields.otherDetails.referrer_name.trim() === "") {
                    formErrors.referrer_name = "Please enter agency Name"
                }
            }  
            if (formFields.otherDetails.insurance_lead_source === 7) {
                if (!formFields.otherDetails.lead_sub_source) {
                    formErrors.lead_sub_source = "Please select marketing lead category"
                }
            }
            if (+formFields?.otherDetails?.paid_by_customer === 1) {
                if (!formFields?.otherDetails?.paid_to_whom) {
                    formErrors.paid_to_whom = "Please select paid to whom"
                }
            }
            if (+formFields?.otherDetails?.paid_from_agency === 1) {
                if (!formFields?.otherDetails?.paid_to_whom) {
                    formErrors.paid_to_whom = "Please select paid to whom"
                }
            }
            if ([APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(insuranceState?.leadDetail?.application_type_id) && !formFields?.otherDetails?.paid_by_customer) {
                formErrors.paid_to_whom = "Please select paid by customer"
            }
            
            if ([APP_TYPE_AGENCY].includes(insuranceState?.leadDetail?.application_type_id) && !formFields?.otherDetails?.paid_from_agency) {
                formErrors.paid_to_whom = "Please select paid from agency"
            }
        }
        return formErrors;
    }

    /**
    * SUBMIT FORM HANDLER
    */
    const submitHandler = async (key: string) => {
        setLoading(true)
        let params: any = { leadId: props.leadHashId,insurance_type:insuranceType, apptype: application_type_id }
        if (key === 'personal-details' || key === 'payment-details' ) {
            verifyExptdInsDate(personalDetails)
            params = { ...params, application_type_id: insuranceDetails['application_type_id'], ...{ personalDetails: personalDetails } }
        } else if (key === 'vehicle-details') {
            params = { ...params, ...{ vehicleDetails: vehicleDetails } }
        } else if(key === 'residential-details') {
            params = { ...params, ...{ residentialDetails: residentialDetails } }
        } else {
            params = {...params, ...{ otherDetails: otherDetails } }
        }

        let formError = await validateFormData(params);
        let newObj = params;
         // IF value is null then value remove from object
        // personalDetails and remove keys with null values
        if (newObj?.personalDetails) {
            let avoidNullCheckForKeys = ["payment_tenure_id", "alternate_mobile", "email"]
            for (const key in newObj?.personalDetails) {
                if (!avoidNullCheckForKeys.includes(key) && (newObj.personalDetails[key] === null || newObj.personalDetails[key] ==='') ) {
                    delete newObj.personalDetails[key];
                }
            }

            //remove extra expected installment dates if any
            if (personalDetails.payment_tenure_id) {

                if(personalDetails.expected_installment){

                    let updatedInstallmentDate = { ...personalDetails.expected_installment }
                    const totalInstallments = commonState?.common?.payment_tenure?.find((pt: any) => personalDetails.payment_tenure_id === pt.value)?.label?.split(' ')[0]

                    //add actual installment keys in expected installment obj 
                    if (props.formType !== 'personal-details' && personalDetails.actual_installment) {
                        for (let key in personalDetails.actual_installment) {
                            let val = personalDetails.actual_installment[key]
                            updatedInstallmentDate[key] = { ...updatedInstallmentDate[key], actual_date: val.date, actual_amount: +val.amount }
                        }
                    }

                    for (let key in updatedInstallmentDate) {
                        if (+key > totalInstallments) delete updatedInstallmentDate[key]
                    }
                    
                    personalDetails.expected_installment = updatedInstallmentDate
                }

                //IN CASE OF AGENCY
                if(personalDetails.expected_installment_reference_no) {

                    let updatedInstallmentDate = JSON.parse(JSON.stringify(personalDetails.expected_installment_reference_no))
                    const totalInstallments = commonState?.common?.payment_tenure?.find((pt: any) => personalDetails.payment_tenure_id === pt.value)?.label?.split(' ')[0]

                    for (let key in updatedInstallmentDate) {
                        if (+key > totalInstallments) updatedInstallmentDate[key]['status'] = 0;
                        else updatedInstallmentDate[key]['status'] = 1;
                    }
                    personalDetails.expected_installment_reference_no = updatedInstallmentDate
                }
            }else{
                //IN CASE OF AGENCY STATUS 0
                if(personalDetails.expected_installment_reference_no) {

                    let updatedInstallmentDate = JSON.parse(JSON.stringify(personalDetails.expected_installment_reference_no))
                    for (let key in updatedInstallmentDate) {
                       updatedInstallmentDate[key]['status'] = 0;
                    }
                    personalDetails.expected_installment_reference_no = updatedInstallmentDate
                }
            }

            //if one time payment, delete all expected installment dates
            if(personalDetails.payment_tenure_id === 0) delete personalDetails.expected_installment
        }

        //vehicleDetails and remove keys with null values
        if (newObj?.vehicleDetails) {
            for (const key in newObj?.vehicleDetails) {
                if (newObj.vehicleDetails[key] === null) {
                    delete newObj.vehicleDetails[key];
                }
            }
        }

        // residentialDetails and remove keys with null values
        if (newObj?.residentialDetails) {
            for (const key in newObj?.residentialDetails) {
                if (newObj.residentialDetails[key] === null) {
                    delete newObj.residentialDetails[key];
                }
            }
        }

         // otherDetails and remove keys with null values
        if (newObj?.otherDetails) {
            if(newObj.otherDetails.is_under_financing === 2) {
                newObj.otherDetails.financier_id = 0
                newObj.otherDetails.financing_from_date = null
                newObj.otherDetails.financing_to_date = null
            }

            if(newObj.otherDetails.insurance_exists === 2) {
                newObj.otherDetails.insurer_name = null
                newObj.otherDetails.insurance_expire_date = null
            }
        }
        
        if (!Object.keys(formError).length) {
            //delete actual installment object from request
            delete newObj?.personalDetails?.actual_installment

            // if other doc is string then convert array  
            if (newObj?.otherDetails) {
                newObj.otherDetails.other_doc = ((newObj?.otherDetails?.other_doc && newObj?.otherDetails?.other_doc?.length && typeof newObj?.otherDetails?.other_doc === 'string') ? newObj?.otherDetails?.other_doc.split(',') : newObj?.otherDetails?.other_doc) || undefined
            }
            // remove extra space
            if(newObj?.otherDetails?.referrer_name ){
                newObj.otherDetails.referrer_name = newObj.otherDetails.referrer_name.trim();
            }

            if(newObj?.personalDetails?.customer_name){
                newObj.personalDetails.customer_name = HELPER.EncodetoBase64(newObj?.personalDetails.customer_name);  
            }   
            const residentialFields: any = ENCRYPTED_FIELDS.filter((fields:any)=> fields !== "name");
            
            residentialFields.forEach((field: any) => {
                if(newObj?.residentialDetails?.[field]){
                    newObj.residentialDetails[field] = HELPER.EncodetoBase64(newObj.residentialDetails[field]);
                }
            });       

            if(+newObj?.otherDetails?.paid_by_customer === 1 || +newObj?.personalDetails?.paid_from_agency === 1){
                let getInboundPaymentFrom = newObj?.otherDetails?.paid_to_whom || +newObj?.personalDetails?.paid_to_whom;
                if (+getInboundPaymentFrom === INBOUND_PAYMENT_FROM_CUSTOMER) {
                    getInboundPaymentFrom = "customer";
                } else if (+getInboundPaymentFrom === INBOUND_PAYMENT_FROM_SALES_TEAM) {
                    getInboundPaymentFrom = "sales_team";
                } else if (+getInboundPaymentFrom === INBOUND_PAYMENT_FROM_BROKER) {
                    getInboundPaymentFrom = "broker";
                } else if (+getInboundPaymentFrom === INBOUND_PAYMENT_FROM_OTO_INSURANCE) {
                    getInboundPaymentFrom = "oto_insurance";
                } else if (+getInboundPaymentFrom === INBOUND_PAYMENT_FROM_INSURANCE_PARTNER) {
                    getInboundPaymentFrom = "insurance_partner";
                }
                dispatch(setInboundPaymentFromResponse(getInboundPaymentFrom));
            }
            // return false;
            updateInsuranceDetails(newObj).then((res: any) => {
                if (res && res.status === 200) {
                    let data = { ...insuranceState?.leadDetail, ...res.data }

                    dispatch(leadListData(data));
                    // here handle with not send during empty value, and send only when it has some data   
                    const payload:any = {
                        leadId: props.leadHashId,
                        source: 'web'
                    };
                   
                    dispatch(getDocumentListing(payload));
                    dispatch(updateLeadTemplateData([]))
                    navigateTo(key);
                }
                setLoading(false)
            }).catch((error) => setLoading(false));
        } else {
            setErrors({ ...formError });
            setLoading(false)
        }
    }

    const navigateTo = (key: string) => {
        let nav_url: string = '';
        if (key === 'personal-details') {
            nav_url = `/details/residential-details/${props.leadHashId}`;
        } else if (key === 'residential-details') {
            nav_url = `/details/vehicle-details/${props.leadHashId}`;
        } else if(key === 'vehicle-details') {
            if(insuranceState.leadDetail.application_type_id === APP_TYPE_AGENCY){
                if(+otherDetails?.paid_from_agency === 1 || +personalDetails?.paid_from_agency === 1){

                    nav_url = `/details/payment-details/${props.leadHashId}`;
                }else{
                    nav_url = `/details/sent-to-insurance-broker/${props.leadHashId}`;

                }
            }else{
                nav_url = `/details/other-details/${props.leadHashId}`;
            }
        } else { //VEHICLE DETAILS
            if(key === 'other-details'&& (+otherDetails?.paid_by_customer === 1 || +otherDetails?.paid_from_agency === 1 || +personalDetails?.paid_from_agency === 1)){
                nav_url = `/details/payment-details/${props.leadHashId}`;
            }else{
                nav_url = `/details/sent-to-insurance-broker/${props.leadHashId}`;
            }
        }
        navigate(nav_url);
    }

    /**
     * function to clean expected installment date object
     * @param params 
     */
    const verifyExptdInsDate = (params: any) => {
        let { expected_installment, payment_tenure_id, payment_term_id } = params
        if (payment_term_id === PAYMENT_TERM_INSTALLMENT) {
            let updatedDates = { ...expected_installment }
            let totalInstallments = commonState?.common?.payment_tenure?.find((pt: any) => payment_tenure_id === pt.value)?.label?.split(' ')?.[0]
            let totalExptdDate = updatedDates && Object.keys(updatedDates)?.length

            if (totalInstallments && totalExptdDate && +totalExptdDate !== +totalInstallments) {
                //delete extra installment keys if exists
                for (let key in updatedDates) {
                    if (+key > +totalInstallments) delete updatedDates?.[key]
                }
            }
            expected_installment = { ...updatedDates }
        }
    }

    let isLeadEditable = (((insuranceDetails && (insuranceDetails['status_id'] >= STATUS_SUB_STATUS['status']['sent_to_insurance_broker'] || +insuranceDetails['application_type_id'] === APP_TYPE_WITH_UCF))) || !HELPER.isUserHasAccessPage({ permissionID: permissonId, accessType: 'edit', assignUserId: insuranceState.leadDetail.assign_user_id }));

    isLeadEditable = (insuranceDetails?.application_type_id === APP_TYPE_AGENCY && insuranceDetails?.agency_id && [AGENCY_STATUS['inprogress'], AGENCY_STATUS['rejected']].includes(insuranceDetails?.agency_status)) || isLeadEditable
    
    return (
        <div className="image-form-outer">
            <FullPageLoader show={loading} />
            <div className="image-tab-panel">
                <DocumentGallery formType={props.formType} />
            </div>
            <div className="form-tab-right-panel">
                <div className="lead-detail-from-outer">
                    <div className='lead-form-heading'>
                        <span className="main-heading">Customer Details</span>
                    
                    

                        {
                            props.formType === "personal-details" && Object.keys(personalDetails).length > 0 && <PersonalDetailsForm personalDetails={personalDetails} disable={isLeadEditable} errors={errors} changeHandler={changeHandler} formType={props.formType}/>
                        }
                        {
                            props.formType === "vehicle-details" && Object.keys(vehicleDetails).length > 0 && <VehicleDetailsForm vehicleDetails={vehicleDetails} disable={isLeadEditable} insuranceType={insuranceType} errors={errors} changeHandler={changeHandler} />
                        }
                        {
                            props.formType === "residential-details" && Object.keys(residentialDetails).length > 0 && <ResidentialInformation residentialDetails={residentialDetails} disable={isLeadEditable} errors={errors} changeHandler={changeHandler} setLoading={setLoading} application_type_id={insuranceDetails['application_type_id']}/>
                        }
                        {
                            props.formType === "other-details" && Object.keys(otherDetails).length > 0 && <OtherDetailsForm otherDetails={otherDetails} disable={isLeadEditable} errors={errors} changeHandler={changeHandler}/>
                        }
                        {
                            props.formType === "payment-details" && Object.keys(personalDetails).length > 0 && <PersonalDetailsForm personalDetails={personalDetails} disable={isLeadEditable} errors={errors} changeHandler={changeHandler} formType={props.formType}/>
                        }

                        { insuranceState.leadDetail && insuranceState.leadDetail.lead_id && <div className="btn-save-remarks m-lg-t">
                            {
                                // isLeadEditable
                                 HELPER.isUserHasUpdateAccess({ permissionID: permissonId, accessType: 'edit', leadDetails: insuranceState.leadDetail, pageSlug:"customer_details" }) && +insuranceState?.leadDetail?.sub_status_id !== 3
                                // HELPER.isUserHasAccessPage({ permissionID: permissonId, accessType: 'edit', assignUserId: insuranceState.leadDetail.assign_user_id })

                                    ?
                                    <button type="button" className="btn-primary" onClick={() => { submitHandler(props.formType) }}>Verify & Next</button>
                                    :
                                    <button type="button" className="btn-primary" disabled> Next</button>
                            }

                            {insuranceState?.leadDetail?.leadId && <LeadRemarksButton leadId={insuranceState.leadDetail.leadId} />}

                        </div> }
                    
                    </div>
                </div>
            </div>
        </div>

    )
}
