import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Captcha from "@src/common/Captcha";
import { CaptchaData } from "@src/common/Captcha";
import { createOtp, googleWithLogin } from "@src/store/slices/user";
import VerifyOtp from "@src/component/auth/VerifyOtp";
import secureStorage from "@src/config/encrypt";
import GoogleLoginComponent from './GoogleLogin';
import { useSearchParams } from 'react-router-dom';
import * as HELPER from "@src/config/helper";
import { ROLE_FEATURES } from '@src/config/constant';
import { useDispatch } from 'react-redux';
import FullPageLoader from '@common/FullPageLoader';
import { toast } from 'react-toastify';

interface EmailUserProps {
    setShowMobileLinks: any;
    setShowEmailLinks: any;
    showCaptcha: any;
}

function EmailUser(props: EmailUserProps) {


    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i); // eslint-disable-line

    const [otp, setOtp] = useState("");
    const [loading, setLoading] = useState(false);
    const [inputField, setInputField] = useState({
        email: "",
        password: "",
        captcha: "",
        hash: "",
        mobile: ""
    });
    const [errField, setErrField] = useState<any>({
        email: "",
        password: "",
        isCaptchaInvalid: false,
        captchaError: "",
        captcha: "",
        otp: ""
    });
    const [showVerify, setShowVerify] = useState<boolean>(false);
    const [userId, setUserId] = useState<string>('');
    const navigate = useNavigate();
    const [reloadCaptcha, setReloadCaptcha] = useState(1);
    const [googleLogin, setGoogleLogin] = useState(false);
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();


    const validForm = () => {
        let formIsValid = true;
        setErrField({
            email: "",
            password: "",
            isCaptchaInvalid: false,
            captchaError: "",
            captcha: "",
            otp: ""
        });

        if (inputField.email === "") {

            formIsValid = false;
            setErrField((prevState: any) => ({
                ...prevState,
                email: "Email is required",
            }));
        } else if (!validEmailRegex.test(inputField.email)) {

            formIsValid = false;
            setErrField((prevState: any) => ({
                ...prevState,
                email: "Please enter valid email",
            }));
        }
        if (inputField.password === "") {

            formIsValid = false;
            setErrField((prevState: any) => ({
                ...prevState,
                password: "Password is required",
            }));
        }
        if (!inputField['captcha'].trim()) {

            formIsValid = false;
            setErrField((prevState: any) => ({
                ...prevState,
                captcha: "Captcha is required",
            }));
            setReloadCaptcha((prev) => prev + 1)
        }

        return formIsValid;
    };

    const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === "otp") {
            setOtp(value);
        }
        else {
            setInputField({ ...inputField, [name]: value });
        }
        errField[name] = '';
        setErrField({ ...errField });
    };

    useEffect(() => {
        const loggedInUser = secureStorage.getItem("authUserInfo");
        if (loggedInUser && loggedInUser.user_data) {
            navigate('/dashboard');
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps



    const submitButton = async () => {

        setLoading(true);
        if (validForm()) {

            createOtp(inputField).then((res) => {
                setLoading(false)
                if (res && res.data) {
                    //DELETE CAPTCHA HASH FROM CACHE 
                    setUserId(res && res.data && res.data.user_id);
                    setShowVerify(true);
                    props.setShowEmailLinks(true);
                    props.setShowMobileLinks(false);

                }
                setReloadCaptcha((prev) => prev + 1)
                setInputField({ ...inputField, email: inputField.email, password: inputField.password, captcha: '' })

            });
        } else {
            setReloadCaptcha((prev) => prev + 1)
            setLoading(false);
        }
    };

    const captchaFiledHandler = (captchaValue: string, captchaImage: CaptchaData) => {
        if (captchaImage.data) {
            setInputField({ ...inputField, captcha: captchaValue, hash: captchaImage.data.value });
        }
    };


    useEffect(() => {
        const userDataParam: any = searchParams.get('userdata');

        if (userDataParam) {
            try {
                setLoading(true);
                const parsedData = JSON.parse(decodeURIComponent(userDataParam));

                if ((!parsedData.domain && parsedData.email) || parsedData.domain !== "Invalid") {
                    setLoading(true);
                    setInputField({ ...inputField, email: parsedData.email, password: "", captcha: '' });

                    googleWithLogin({...parsedData, "loginType": "googleLogin"}, dispatch)
                        .then(async (result: any) => {
                            if (result.status === 200) {

                                if (HELPER.isUserHasAccessPage({permissionID: ROLE_FEATURES['dashboard_page'], accessType: 'isVisible'})) {
                                    navigate('/dashboard');
                                }
                                else if (HELPER.isUserHasAccessPage({permissionID: ROLE_FEATURES['listing_page'], accessType: 'isVisible'})) {
                                    navigate('/insurance-list');
                                }
                                else {
                                    navigate('/rule-engine');
                                }
              
                            }else{
                                setLoading(false);
                                setInputField({ ...inputField, email: "", password: "", captcha: '' });
                                navigate('/login');
                            }
                        })
                } else {
                    setLoading(false);
                    toast.error("This Domain is not allowed.")
                    navigate('/login');

                }

            } catch (error) {
                setLoading(false);
                toast.error("This Domain is not allowed.")
                navigate('/login');
            }
        }
    }, [searchParams]);

    return (
        <div className="login-form">

            <FullPageLoader show={loading} />

            {showVerify ?

                (<VerifyOtp submitButton={submitButton} userId={userId} inputField={inputField} otp={otp} />)
                :
                <form autoComplete="on">
                    <fieldset className="">
                        <div className={"material " + (errField.email ? "error" : "")}    >
                            <input
                                type="text"
                                placeholder=" "
                                name="email"
                                value={inputField.email}
                                className="form-input"
                                onChange={inputHandler}
                            />
                            <i className="ic-email1 icn-login"></i>
                            <label data-label="Email" className="form-label"></label>
                            {errField.email && (
                                <span className="error-txt">
                                    {errField['email'] || ""}
                                </span>
                            )}
                        </div>
                    </fieldset>
                    <fieldset className="">
                        <div className={"material " + (errField.password ? "error" : "")}>
                            <input
                                type="password"
                                placeholder=" "
                                name="password"
                                value={inputField.password}
                                className="form-input password-txt"
                                onChange={inputHandler}
                            //   onKeyPress={handleKeyPress}
                            />
                            <i className="ic-password icn-login"></i>
                            <label data-label="Password" className="form-label"></label>
                            {errField.password && (
                                <span className="error-txt">
                                    {errField['password'] || ""}
                                </span>
                            )}
                        </div>
                    </fieldset>
                    <fieldset className=" captcha-filed">
                        <Captcha onKeyPressCaptcha={captchaFiledHandler} errField={errField} inputField={inputField} reloadCaptcha={reloadCaptcha || props.showCaptcha} />

                    </fieldset>

                    <div className="language-selectors">

                        <button type="button" className="btn-primary" onClick={submitButton} disabled={loading}>
                            Login
                        </button>


                        <GoogleLoginComponent setUserId={setUserId} setShowVerify={setShowVerify} setGoogleLogin={setGoogleLogin} setInputField={setInputField} />


                    </div>

                </form>
            }
        </div>
    );
}

export default EmailUser;