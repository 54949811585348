import secureStorage from './encrypt';
import { APP_TYPE_AGENCY, CURRENCY_SYMBOL, STATUS_SUB_STATUS, AGENCY_STATUS, DOC_UPLOAD_VALIDATION } from './constant';
import forge from 'node-forge';
import {Buffer} from 'buffer';
import { toast } from 'react-toastify';

const ENCRYPTION_AES_ENC_KEY_OPTIONS = {
    iterations: 100,
    keySize: 16
}

export const isUserHasAccessPage = (params: { permissionID: string | number, accessType: any, assignUserId?: string | null | undefined }) => {
    let { permissionID, accessType, assignUserId } = params;

    let isAuthorized = false;
    let authUserInfo = secureStorage.getItem('authUserInfo');
    let userDetails = authUserInfo && authUserInfo.user_data;

    if (permissionID) {
        const accessParams = (userDetails && userDetails.feature_access) ? userDetails.feature_access.filter((feature: any) => +feature.assigned_feature_ids === +permissionID) : [];
        // if(permissionID == 35) console.log("accessParamsaccessParams", accessType,accessParams, permissionID, ''+assignUserId, ''+userDetails.id, highestRoleid);
        if (accessParams && accessParams[0]) {

            // let checkEditAccess = accessParams.filter((feature: any)=> ([1,2].includes(+highestRoleid) && feature.canEdit) || (assignUserId && [3].includes(+feature.assign_role_id) && ''+assignUserId === ''+userDetails.id) || (![1,2,3].includes(+highestRoleid) && feature.canEdit))
            // let checkViewAccess = accessParams.filter((feature: any)=> ([1,2].includes(+highestRoleid) && feature.canView) || (assignUserId && [3].includes(+feature.assign_role_id) && ''+assignUserId === ''+userDetails.id) || (![1,2,3].includes(+highestRoleid) && feature.canEdit))
            // if(permissionID == 17) console.log(checkEditAccess)

            // let checkEditAccess = accessParams.filter((feature: any)=> (([1,2].includes(+highestRoleid) && [1,2].includes(+feature.assign_role_id) && feature.canEdit) || (''+assignUserId && [3].includes(+feature.assign_role_id) && ''+assignUserId === ''+userDetails.id && feature.canEdit) || (![1,2,3].includes(+highestRoleid) && feature.canEdit)))
            // let checkViewAccess = accessParams.filter((feature: any)=> ([1,2].includes(+highestRoleid) && [1,2].includes(+feature.assign_role_id) && feature.canView) || (''+assignUserId && [3].includes(+feature.assign_role_id) && ''+assignUserId === ''+userDetails.id && feature.canView) || (![1,2,3].includes(+highestRoleid) && feature.canView))

            let checkEditAccess = accessParams.filter((feature: any) => ([1, 2].includes(+feature.assigned_role_id) && feature.canEdit) || (assignUserId && [3].includes(+feature.assigned_role_id) && '' + assignUserId === '' + userDetails.id && feature.canEdit) || (![1, 2, 3].includes(+feature.assigned_role_id) && feature.canEdit))
            let checkViewAccess = accessParams.filter((feature: any) => ([1, 2].includes(+feature.assigned_role_id) && feature.canView) || (assignUserId && [3].includes(+feature.assigned_role_id) && '' + assignUserId === '' + userDetails.id && feature.canView) || (![1, 2, 3].includes(+feature.assigned_role_id) && feature.canView))

            // if(permissionID == 35) console.log("accessParamsaccessParams", checkEditAccess);

            // if(accessType === 'edit' && accessParams[0]['canEdit']){
            if (accessType === 'edit' && checkEditAccess.length) {
                isAuthorized = true;

                // if(assignUserId !== undefined && ''+assignUserId !== ''+userDetails.id && ![1,2].includes(+highestRoleid)) {
                //     isAuthorized = false;
                // }
            }
            else if (accessType === 'view' && checkViewAccess.length && userDetails.assigned_role_ids.indexOf("3") >= 0) {
                // else if(accessType === 'view' && accessParams[0]['canView'] && userDetails.assigned_role_ids.indexOf("3")>=0){
                if (+accessParams[0].assigned_feature_ids === 35) isAuthorized = true
                isAuthorized = true;
            }
            else if (accessType === 'isVisible') {
                // else if(accessType === 'isVisible' && (checkEditAccess.length || checkViewAccess.length)){
                isAuthorized = true;
            }
        }
    }

    return isAuthorized;
}

//function to convert file into base 64 for previewing image
export const toBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
    })
}

/**
 * function to detect media type of base 64 buffer/array
 * @param {Array} b64 
 * @returns {String} match media type
 */
export const detectMimeType = (b64: any, others?: { allowedExtensions: Array<any> }) => {
    return new Promise((resolve, reject) => {
        if (!b64) resolve(false)

        let fileType = b64.split(';')[0]
        b64 = b64.split(',')[1]

        const signatures: any = {
            iVBORw0KGgo: "image/png",
            "/9j/": "image/jpg",
            ffd8ffe0: "image/jpeg",
            ffd8ffe1: "image/jpeg",
            ffd8ffe2: "image/jpeg",
            ffd8ffe3: "image/jpeg",
            ffd8ffe8: "image/jpeg"
        }

        for (const s in signatures) {
            if (b64.indexOf(s) === 0) {
                return resolve(signatures[s])
            }
        }

        //if not image
        if (others && others.allowedExtensions) {
            if (others.allowedExtensions.includes(fileType)) {
                return resolve(true)
            }
        }

        return resolve(false)
    })
}


export const addCurrencyFormatting = (number: any) => {
    number = number ? number.toString().replace(/,/g, '') : 0;
    return CURRENCY_SYMBOL + ' ' + (+number ? (+number).toLocaleString("en-EU") : 0);
}

export const removeCurrencyFormatting = (number: any) => {
    if (number) {
        var result = ('' + number).replace(/[Rp]+/g, "").replace(/[₱]+/g, "").replace(/[AED]+/g, "").replace(/,/g, "");
        return result.toString().trim()
    }
}

export const isUserHasUpdateAccess = (params: any) => {
    let isAccessAllowed = true;
    let { permissionID, accessType, leadDetails, pageSlug, pageChildSlug, selectedTab } = params;
    let assignUserId = leadDetails?.assign_user_id;

    //INCASE OF AGENCY LEAD ALLOW ONLY IF AGENCY IS ACTIVE
    if (accessType === 'edit' && leadDetails?.application_type_id === APP_TYPE_AGENCY && leadDetails?.agency_id && [AGENCY_STATUS['inprogress'], AGENCY_STATUS['rejected']].includes(leadDetails?.agency_status)) {
            
        return false;
    }

    //CHECK ROLE ACCESS/INSURANCE DISABLE FLAG/STATUS CHECK
    if (!isUserHasAccessPage({ permissionID, accessType, assignUserId }) || +leadDetails.status_id === STATUS_SUB_STATUS['status']['lost'] || +leadDetails.doc_status_id !== 3) {

        if (pageSlug && !["customer_details"].includes(pageSlug)) {
            isAccessAllowed = false;

        }
        if (pageSlug && ["customer_details"].includes(pageSlug) && (+leadDetails.sub_status_id > STATUS_SUB_STATUS['subStatus']['sent_to_insurance_broker'] || !isUserHasAccessPage({ permissionID, accessType, assignUserId }) || leadDetails.doc_status_id < 2)) { //if doc status is pending or requested
            isAccessAllowed = false;
        }

        if (pageSlug === "sent_to_insurance_broker" && +leadDetails.status_id !== STATUS_SUB_STATUS['status']['lost']) {
            isAccessAllowed = true;
        }
    } else if (pageSlug) {

        // if (pageSlug === "sent_to_insurance_broker" && leadDetails.sub_status_id >= STATUS_SUB_STATUS['subStatus']['sent_to_insurance_broker']) {
        if (pageSlug === "sent_to_insurance_broker" && leadDetails?.policy_shared_broker_source?.trim()) {
            isAccessAllowed = false;
        } else if (pageSlug === "policy_shared") {
            if(leadDetails?.application_type_id === APP_TYPE_AGENCY){//IN CASE OF AGENCY CHECK SELECTED TAB
                if(selectedTab === 'first' && leadDetails?.is_policy_shared_sfa){ //CHECK SENT TO SFA MARKED
                    isAccessAllowed = false;
                }else if(selectedTab === 'second' && leadDetails?.policy_shared_customer_source){ //CHECK IF SHARED VIA EMAIL/WHATSAPP
                    isAccessAllowed = false;
                }

            }else if(leadDetails.policy_shared_customer_source){ //EXISTING FLOW
                isAccessAllowed = false;
            }
        } else if (pageSlug === "soft-approval-received" && leadDetails.sub_status_id >= STATUS_SUB_STATUS['subStatus']['soft_approval_received']) {
            isAccessAllowed = false;
        } else if (pageSlug === "insurance-policy-generated" && leadDetails.sub_status_id >= STATUS_SUB_STATUS['subStatus']['policy_generated']) {
            isAccessAllowed = false;
        }else if(pageSlug === "inbound_payment" && leadDetails.inbound_id) { // && leadDetails.inbound_amount
            let payTenure = params.pay_tenure
            if (pageChildSlug !== 'broker' && payTenure && leadDetails.installment_details) {
                let installment = leadDetails.installment_details
                let totalInstallments = Object.keys(installment).filter((ins: any) => installment[ins]?.amount || installment[ins]?.date).length || -1
                if (payTenure && (totalInstallments - 1) !== +payTenure.split(" ")[0]) {
                    isAccessAllowed = true
                }
                else isAccessAllowed = false
            }
            // else if (!leadDetails.inbound_amount || (leadDetails.prev_status_sub_status_ids.indexOf("4-8") === -1)) isAccessAllowed = true
            else if (!leadDetails.inbound_amount) isAccessAllowed = true
            else isAccessAllowed = false;

        }else if(pageSlug === "outbound_payment" && pageChildSlug) {
            if(pageChildSlug === "broker" && leadDetails.outbound_broker_id){

                isAccessAllowed = false;
            } else if (pageChildSlug === "sales_team" && leadDetails.outbound_sales_team_id) {

                isAccessAllowed = false;
            }
        } else if (["customer_details"].includes(pageSlug) && (+leadDetails.sub_status_id >= STATUS_SUB_STATUS['subStatus']['sent_to_insurance_broker'] || leadDetails.doc_status_id < 2)) {
            isAccessAllowed = false;
        } else if (pageSlug === "payment_from_customer_to_partner_broker" && leadDetails.pay_from_cust_to_partner_broker_id) {
            let payTenure = params.pay_tenure
            let installment = leadDetails.installment_details
            let totalInstallments = (installment && Object.keys(installment).filter((ins: any) => installment[ins]?.amount || installment[ins]?.date)?.length) || -1

            if (payTenure && (totalInstallments - 1) !== +payTenure.split(" ")[0]) {
                isAccessAllowed = true
            }
            else isAccessAllowed = false
        } else if(["payment_from_agency_to_oto_insurance", "payment_from_agency_to_insurance_partner"].includes(pageSlug) ) {

            let payTenure = params.pay_tenure
            if (payTenure && leadDetails?.payment_reference_arr?.length) {
                let installment = leadDetails.payment_reference_arr

                let totalInstallments = Object.keys(installment).filter((ins: any) => installment[ins]?.payment_reference_no || installment[ins]?.date).length || -1

                if (payTenure && totalInstallments === +payTenure.split(" ")[0] && leadDetails.insurance_agency_payment_id) {
                    isAccessAllowed = false
                }
                else if(!payTenure && leadDetails.insurance_agency_payment_id) isAccessAllowed = false

            }else if(leadDetails.insurance_agency_payment_id) isAccessAllowed = false;

        } else if(["payment_from_oto_to_insurance_partner", "payment_from_insurance_partner_to_oto"].includes(pageSlug)  && leadDetails.insurance_agency_commission_id) {
            isAccessAllowed = false

        } else if(pageSlug === "referral_fee_payout" && leadDetails.referral_fee_payout_id) {
            isAccessAllowed = false

        }
    }

    return isAccessAllowed;

}

export const nthNumber = (number: number) => {
    if (number > 3 && number < 21) return ("th");
    switch (number % 10) {
        case 1:
            return ("st");
        case 2:
            return ("nd");
        case 3:
            return ("rd");
        default:
            return ("th");
    }
};

//GENERATE AES KEY HANDLER
export const generateAesKey = () => {
    const aesSalt = forge.random.getBytesSync(ENCRYPTION_AES_ENC_KEY_OPTIONS.keySize);
    const keyPassPhrase = forge.random.getBytesSync(ENCRYPTION_AES_ENC_KEY_OPTIONS.keySize);
    const aesKey = forge.pkcs5.pbkdf2(
        keyPassPhrase,
        aesSalt,
        ENCRYPTION_AES_ENC_KEY_OPTIONS.iterations, // use according to your requirement
        ENCRYPTION_AES_ENC_KEY_OPTIONS.keySize, // use according to your requirement
    );
    return aesKey;
};

//ENCRYPTION HANDLER
export const encryptAesKey = (params: any) => {
    try {
        let {encPubKey, body, URL} = params;
        let symmetricKey = forge.random.getBytesSync(16);
        let iv = forge.random.getBytesSync(16);
        var encryptedReqData : any;
        let encryptedSymmetricKey: any;
        let isBodyLength = false;
        let formObj: any = {};
        let updatedFormData = new FormData();

        if(URL.indexOf('/addDocumentWeb') >= 0 || URL.indexOf('/uploadCsv') >= 0 || URL.indexOf('/uploadAddOnCsv') >= 0 || URL.indexOf('/addInsurancePartner') >= 0 || URL.indexOf('/updateInsurancePartner') >= 0 || URL.indexOf('/addAgencyWebDocument') >= 0 || URL.indexOf('/paymentProofDocs') >= 0 || URL.indexOf('/uploadAndReadDocument') >= 0) {
            for(var pair of body.entries()) 
                {
                    if(pair[0] !== "files"){                        
                        formObj[pair[0]] = pair[1]
                    }else{
                        updatedFormData.append('files', pair[1])
                    }
                }

                // updatedFormData.set('formData', formObj)//storage.encrypt(JSON.stringify(formObj), generateAesKey))

                // body = formObj//{...body, ...updatedFormData};
                isBodyLength = true;
        }else{
            isBodyLength = (body && Object.keys(body)?.length);
            // body = JSON.stringify(body)
        }

        if(isBodyLength){

            let cipher = forge.cipher.createCipher('AES-CBC', symmetricKey);
            cipher.start({iv: iv});
            cipher.update(forge.util.createBuffer(JSON.stringify(body), 'utf8'));
            cipher.finish();
            // encryptedReqData = cipher.output.toHex();
            encryptedReqData = forge.util.encode64(cipher.output.getBytes());

            if(Object.keys(formObj).length){
                updatedFormData.append('formData', encryptedReqData)
                encryptedReqData = body//updatedFormData
                
            }


            const publicKey = forge.pki.publicKeyFromPem(encPubKey);
            encryptedSymmetricKey = publicKey.encrypt(symmetricKey, 'RSA-OAEP');
            encryptedSymmetricKey = forge.util.encode64(encryptedSymmetricKey);

        }

        return {encryptedReqData, iv: forge.util.encode64(iv), symmetricKey: encryptedSymmetricKey};
    } catch (error) {
        throw error;
    }
};


// Decrypt encrypted data using AES decryption
const DecryptData = (encryptedData: any, iv: string, symmetricKey: string) => {
    try{
        const decipher = forge.cipher.createDecipher('AES-CBC', forge.util.decode64(symmetricKey));
        decipher.start({ iv: forge.util.decode64(iv) });
        decipher.update(forge.util.createBuffer(forge.util.decode64(encryptedData), 'raw'));
        decipher.finish();
        return JSON.parse(decipher.output.toString());
    }catch(err){
        throw new Error('Unable to decrypt data');
    }
}

export const DecryptResponse = (response: any) => {
    let decryptRespose = response;
    if(response && response.data && response.data.data){

        let decryptDataResp = DecryptData(response.data.data, response.headers.responseiv, response.headers.responseaeskey);
        if(decryptDataResp?.data){ //IF MULTIPLE KEYS EXISTS

            decryptRespose = {...response.data, ...decryptDataResp};
        }else{ //IF SINGLE ARRAY
            decryptRespose = {...response.data, data: decryptDataResp}; 

        }

    }
    return decryptRespose;
}

export async function convertImgsToFiles(files: Array<{ url: string, fileName: string, doc_id: any }>) {
    try {
        const filePromises = files.map(async ({ url, fileName, doc_id }) => {
            const response = await fetch(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const blob = await response.blob();
            return {
                file: new File([blob], fileName, {
                    type: blob.type,
                    lastModified: Date.now()
                }),
                doc_id
            }
        });

        return await Promise.all(filePromises);
    } catch (error) {
        console.error('Error fetching the files:', error);
        throw error;
    }
}

export const EncodetoBase64 = (data: any) => {
    try {
        if (data) {
            const stringData = JSON.stringify(data) 

            return Buffer.from(stringData, 'utf-8').toString('base64')
        }
        else return ''
    } catch (error) {
        return ''
    }
}

export const ValidateFileInput = (files: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            let errorFlag = false
            let allowedExtensions = ['data:application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'data:application/pdf', 'data:application/msword', 'data:text/csv']
            let allowedExtensionsXls = ['data:application/vnd.ms-excel', 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'data:application/excel', 'data:application/x-excel', 'data:application/x-msexcel'];

            for (let file of files) {
                const base64PreviewImg = await toBase64(file)
                const filetype: any = await detectMimeType(base64PreviewImg, { allowedExtensions: [...allowedExtensions, ...allowedExtensionsXls] })
                const fileSize = file.size

                if (!filetype) {
                    toast.error("File format should be jpg, jpeg, png, pdf, doc, docx, csv, xls, xlsx")
                    errorFlag = true
                    break
                } else if (fileSize < DOC_UPLOAD_VALIDATION['minSize'] || fileSize > DOC_UPLOAD_VALIDATION['maxSize']) {
                    if(fileSize < DOC_UPLOAD_VALIDATION['minSize'] && allowedExtensionsXls.includes('data:'+file.type)){
                    //     // continue;
                    }else{
                        if(allowedExtensionsXls.includes('data:'+file.type)){
                            toast.error("Xls file size should be upto 5mb")
                        }else{
                            toast.error("file size should be between 25kb to 5mb")
                        }
                        errorFlag = true
                        break
                    }
                }
            }

            return resolve(errorFlag)
        } catch (error) {
            return resolve(true)
        }
    })
}