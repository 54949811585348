import { Tab } from 'react-bootstrap';
import LoginForm from './LoginForm';

const loginLeft = {
    backgroundImage: `url("images/login_bg_pattern.png")`,
    backgroundColor: "#008CF0",
    backgroundRepeat: "repeat-y",
    backgroundPosition: "center",
    borderRadius: "25px"
}

function OcrLogin(props:any) {


    return (
        <div className="container-fluid">
            <div className="row">
                <div className="login-outer">
                    <div className="login-left-panel" style={loginLeft}>
                        <div className="login-bg" style={{background: "none"}}>
                            <div className="login-txt t-center t-center ocr-login-left">
                                <span style={{"fontSize":"40", color: "#fff"}}>Welcome.</span>
                                <h1 style={{"fontSize":"40", color: "#fff"}}> Please sign in to OTO Insurance</h1>
                            </div>
                            <img src={'images/ocr_login_bg.png'} className="login-bg-img ocr-login-bg" alt='logo-bg' />
                        </div>
                    </div>
                    <div className="login-right-panel t-center">
                        <div className="logo-img m-xl-b">
                            <img src={'images/ocr_logo.png'} className="" alt='logo' />
                        </div>
                        <div className="login-heading m-md-t">
                            <h2>Sign In</h2>
                        </div>
                        <div className="p-lg-t">
                            <label>Enter your credentials to access your account</label>
                        </div>
                        <Tab.Container id="" defaultActiveKey="first">
                            <div className="login-tab">

                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <LoginForm setAuthorized={props.setAuthorized} showCaptcha={true} />

                                    </Tab.Pane>
                                    
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OcrLogin