import { uploadAndReadDocument } from "@src/store/slices/insurance";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import FullPageLoader from "../../common/FullPageLoader";
import { toast } from "react-toastify";
import OcrLogin from "./ocrlogin";
import secureStorage from "@src/config/encrypt";
import OcrHeader from "./OcrHeader";

export default function OcrReadDocument(props: any): ReturnType<React.FC> {

    const [loading, setLoading] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const [fileType, setFileType] = useState("");
    const [file, setFile] = useState(new Blob([], {}));

    const dispatch: any = useDispatch()

    let ocrAuth = secureStorage.getItem("ocrAuth")

    const [fields, setFields] = useState([
        { label: "Plate Number", key: "plate_number", value: "" },
        { label: "Chassis Number", key: "chassis_number", value: "" },
        { label: "Machine Number/Engine Number", key: "machine_number", value: "" },
        { label: "Make Year", key: "make_year", value: "" },
        { label: "Brand", key: "make", value: "" },
        { label: "Color", key: "color", value: "" },
        { label: "Fuel Type", key: "fuel_type", value: "" },
        { label: "Seat Capacity", key: "seat_capacity", value: "" },
        { label: "Owner Name", key: "name", value: "" },
        { label: "Permanent Full Address", key: "per_full_address", value: "" }
    ])

    const onFileSelect = async (e: any) => {
        let selectedFile = e.target.files;
        let formdata = new FormData()

        let validType = ['image/jpeg', 'image/png', 'image/jpg']

        if (!validType.includes(selectedFile[0]?.type)) {
            toast.error("Please select valid image file");
            return false;
        }

        setFile(selectedFile[0]);

        formdata.append('user_id', '1')
        formdata.append('files', selectedFile[0])

        setLoading(true);
        let uploadDocument: any = await dispatch(uploadAndReadDocument(formdata))

        setLoading(false);

        if (uploadDocument?.status === 200) {
            setFileType(selectedFile[0]?.type);

            let formFields = [...fields];


            for (let field of formFields) {
                field['value'] = ""

                if (uploadDocument?.data[field?.key]) {
                    field['value'] = uploadDocument?.data[field?.key]
                }
            }

            setFields(formFields)
        }

        e.target.value = "";

    }

    const removeSelectedFile = () => {
        setFileType("");
        setFile(new Blob([], {}));

        let formFields = fields?.map((fileEl: any) => { return { ...fileEl, value: "" } })

        setFields(formFields)
    }

    const copyClipBoard = async (text: any) => {
        if (text?.trim()) {
            toast.success("Text copied")
            await navigator.clipboard.writeText(text);
        }
    }

    useEffect(() => {
        setAuthorized(false)
       
        if (ocrAuth > new Date().getTime()) {
            setAuthorized(true)
        }

    }, [ocrAuth])

    return (

        <>
            {authorized && <OcrHeader />}

            <div className="container-fluid">

                {
                    authorized ?
                        <>
                            <FullPageLoader show={loading} />

                            <div className="lead-detail-tabs m-xl-t">
                                {
                                    <div className="detail-form-sec">
                                        <div className="menu-tab-left-panel" style={{ "width": "40%" }}>
                                            <div className="dealer-tab-left-panel nav">
                                                <h1>Documents</h1>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="upload-heading-text ">
                                                        <h3>ORCR Documents</h3>
                                                    </div>
                                                </div>
                                                {
                                                    !fileType &&
                                                    <div className="col-md-4 p-lg-t">
                                                        <div role="presentation" className="add-document-proof">
                                                            <input type="file" id="upload-image-input-1" name="documents[]" title="" onChange={onFileSelect} />
                                                            <i className="ic-add"> </i>
                                                            <span>Upload</span>
                                                        </div>


                                                    </div>
                                                }

                                                <div className="col-md-12 p-lg-t">
                                                    {
                                                        fileType &&
                                                        <div role="presentation" className="upload-image-proof" style={{ height: "auto" }}>
                                                            <span className='close-icn' onClick={removeSelectedFile} style={{ height: "23px", width: "23px" }}>
                                                                <i className='ic-clearclose' style={{ fontSize: "20px" }}></i></span>
                                                            {(fileType === 'image/jpg' || fileType === 'image/jpeg' || fileType === 'image/png') ? <img src={URL.createObjectURL(file)} alt="" /> : <a href={URL.createObjectURL(file)} target="_blank" rel="noreferrer"><i className={`ic-${fileType}-file icons-normal`}></i></a>}

                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="route-files" style={{ "width": "60%" }}>
                                            <div className=" insurance-create-outer relative ">
                                                <div className="loan-expect-left-panle">
                                                    <div className="loan-form">
                                                        <div className={`loan-form-filed-scroll`}>
                                                            {
                                                                <div className="loan-filed-sec">

                                                                    <h2>Document Details</h2>


                                                                    <div className="row">

                                                                        {
                                                                            fields?.map((elm: any, key: number) =>
                                                                                <fieldset className="col-md-6 m-xl-b" key={key}>
                                                                                    <div className={"material"} style={{ display: "flex" }}>
                                                                                        <input
                                                                                            type="text"
                                                                                            placeholder=" "
                                                                                            name={elm.name}
                                                                                            value={elm.value}
                                                                                            className="form-input"
                                                                                            readOnly
                                                                                        />
                                                                                        <i className="ic-content_copy icn-content_copy cursor-pointer" style={{ margin: "10px", "fontSize": "16px" }} onClick={() => copyClipBoard(elm.value)}></i>
                                                                                        <label data-label={elm.label} className="form-label"></label>

                                                                                    </div>
                                                                                </fieldset>
                                                                            )
                                                                        }

                                                                    </div>
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </>

                        :
                        <OcrLogin setAuthorized={setAuthorized} />

                }

            </div>
        </>

    )
}
