import { forwardRef, useImperativeHandle, useState } from 'react';
import { toast } from 'react-toastify';
import * as HELPER from '../../../config/helper';
import { DocumentViewForAgency } from '../create/DocumentForm';
import { DOCUMENT_CATEGORY } from '@src/config/constant';

export const DocumentForm = forwardRef((props: any, ref) => {
    const [errField, setErrField] = useState(props.errors)
    const [inputField, setInputField] = useState<any>({ ...props?.documents })

    const handleFileChange = async (e: any, fieldItem: any) => {
        let selectedFile = e.target.files;
        let validateFile = await HELPER.ValidateFileInput(selectedFile)

        if (validateFile) {
            e.target.value = null
            return
        }
        const maxSelectedFiles = inputField[fieldItem.value] ? Array.from(inputField[fieldItem.value]).concat(Array.from(selectedFile)) : selectedFile

        if (fieldItem.max_upload) {
            if (maxSelectedFiles.length > fieldItem.max_upload) {
                setErrField({ ...errField, [fieldItem.value]: `Maximum ${fieldItem.max_upload} Documents are allowed` })
                e.target.value = null
            }
            else {
                const value = { ...inputField, [fieldItem.value]: maxSelectedFiles }
                setInputField(value)
                // props?.setDocuments(value)
                setErrField({ ...errField, [fieldItem.value]: '' })

                if(fieldItem.value === DOCUMENT_CATEGORY['firstSubmit']['childs']['orcr']) {
                    props?.setOrcrApiCall(false)
                }
            }
        }
    }

    const updateSelectedFiles = (files: any) => {
        setInputField({ ...files })
        // props?.setDocuments(files)
    }

    useImperativeHandle(ref, () => ({
        getData: () => inputField
    }))

    return (
        <div className='col-md-12'>
            <DocumentViewForAgency docList={props.docList} errField={errField} handleFileChange={handleFileChange} filesData={inputField} updateSelectedFiles={updateSelectedFiles} for={"agency"} setOrcrApiCall={props.setOrcrApiCall}/>
        </div>
    )
})