import { useState } from 'react';
import { toast } from "react-toastify";
import FullPageLoader from '../../common/FullPageLoader';
import secureStorage from "@src/config/encrypt";


function LoginForm(props: any) {

    const [loading, setLoading] = useState(false);
    const [inputField, setInputField] = useState<any>({
        email: "",
        password: ""
    });
    const [errField, setErrField] = useState<any>({
        email: "",
        password: "",
    });

    const validForm = () => {
        let formIsValid = true;
        setErrField({
            email: "",
            password: ""
        });

        if (inputField.email === "") {

            formIsValid = false;
            setErrField((prevState:any) => ({
                ...prevState,
                email: "Username is required",
            }));
        }
        if (inputField.password === "") {

            formIsValid = false;
            setErrField((prevState:any) => ({
                ...prevState,
                password: "Password is required",
            }));
        }

        return formIsValid;
    };

    const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        
        setInputField({ ...inputField, [name]: value });

        errField[name] = '';
        setErrField({ ...errField });
    };

    const submitButton = async () => {

        setLoading(true);
        props.setAuthorized(false)
    
        if (validForm()) {
            
            if(inputField?.password?.trim() === "Oto@Insurance#01" && inputField?.email?.trim() == "Oto_User"){
                var newDate = new Date();

                // add a day
                newDate.setDate(newDate.getDate() + 1);

                secureStorage.setItem('ocrAuth', new Date(newDate).getTime());
                toast.success("Login Successfully")
                props.setAuthorized(true)
            }else{
                toast.error("Invalid Credentials")
            }
            setLoading(false);
        } else {
            setLoading(false);
        }
    };



    return (
        <div className="login-form">
            <FullPageLoader show={loading} />
            {
            
                <form autoComplete="on">
                   
                    
                    <fieldset className="">
                        <div className={"material " + (errField.email ? "error" : "")}    >
                            <input
                                type="text"
                                placeholder=" "
                                name="email"
                                value={inputField.email}
                                className="form-input"
                                onChange={inputHandler}
                            />
                            <i className="ic-email1 icn-login"></i>
                            <label data-label="Username" className="form-label"></label>
                            {errField.email && (
                                <span className="error-txt">
                                    {errField['email'] || ""}
                                </span>
                            )}
                        </div>
                    </fieldset>
                    <fieldset className="">
                        <div className={"material " + (errField.password ? "error" : "")}>
                            <input
                                type="password"
                                placeholder=" "
                                name="password"
                                value={inputField.password}
                                className="form-input password-txt"
                                onChange={inputHandler}
                            />
                            <i className="ic-password icn-login"></i>
                            <label data-label="Password" className="form-label"></label>
                            {errField.password && (
                                <span className="error-txt">
                                    {errField['password'] || ""}
                                </span>
                            )}
                        </div>
                    </fieldset>
                    
                    <button type="button" className="btn-primary w-100" onClick={submitButton} disabled={loading}>
                        Login
                    </button>


                     

                </form>
            }
        </div>
    );
}

export default LoginForm;