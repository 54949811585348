import { useState, useEffect } from 'react'
import { InsuranceDate, ExpiryEffectivityDate } from '@src/common/DatePicker';
import DocumentGallery from './DocumentGallery';
import { fetchLeadData, saveInsurancePolicy, updateLeadDetailsInStore } from '@src/store/slices/insurance';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom';
import DocumentForm from "../detail/inbound-outbound-payment/Documents";
import FullPageLoader from '@common/FullPageLoader';
import { SOFT_APPROVAL_RECEIVED_TYPE, POLICY_GENERATED_TYPE, DOCUMENT_CATEGORY, ROLE_FEATURES, WITH_RULE_ENGINE_ID, WITHOUT_RULE_ENGINE, EFFECTIVITY_EXPIRY_INTERVAL, API_TYPES, STATUS_SUB_STATUS, AGENCY_VAL } from "@src/config/constant";
import LeadRemarksButton from '../common/LeadRemarksButton';
import * as HELPER from "../../../config/helper";
import moment from 'moment';

// Define the structure of error fields for validation
type ErrFieldState = {
    insPolicyNumber: string;
    InsPolicyGenerateDate: string;
    expiry_date: string;
    effectivity_date: string
};


const UsePolicyForm = (props: any) => {

    const [insurancePolicyNumber, setInsurancePolicyNumber] = useState('');
    const [insurancePolicyDate, setInsurancePolicyDate] = useState<any>(null);
    const [refrenceNote, setRefrenceNote] = useState('');
    const [inputFields, setInputFields] = useState<{effectivity_date: any, expiry_date: any}>({effectivity_date: null, expiry_date: null});

    const [errField, setErrField] = useState<ErrFieldState>({
        insPolicyNumber: "",
        InsPolicyGenerateDate: "",
        expiry_date: "",
        effectivity_date: ""
    });

    let [loading, setLoading] = useState<boolean>(false);
    const [policyId, setPolicyId] = useState<number>();



    // Redux hooks to dispatch actions and access state
    const dispatch: any = useDispatch()
    const insuranceState = useSelector((state: any) => state.insurance)

    const navigate = useNavigate();
    const queryParams: any = useParams();

    const formType = props.formType;

    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const [leadDetails, setLeadDetails] = useState<any>({});

    const [filesCount, setFilesCount] = useState<number>(0);
    const [minExpiryDate, setMinExpiryDate] = useState(moment().toDate())


    // Function to save the insurance policy template
    const saveInsurancePolicyTemplate = () => {
        setSubmitClicked(false);

        if (validForm()) {
            setLoading(true);

            const formData: any = {};

            // Append other form data to the FormData
            formData['lead_id'] = queryParams.id;
            formData['policy_number'] = insurancePolicyNumber;
            formData['policy_date_time'] = insurancePolicyDate ? insurancePolicyDate.toISOString() : '';
            if (refrenceNote) {
                formData['notes'] = refrenceNote;
            }
            if (formType === 'soft-approval-received') {
                formData['type'] = SOFT_APPROVAL_RECEIVED_TYPE;
            }
            if (formType === 'insurance-policy-generated') {
                formData['type'] = POLICY_GENERATED_TYPE;
                formData['expiry_date'] = inputFields.expiry_date;
                formData['effectivity_date'] = inputFields.effectivity_date;
            }


            formData['id'] = policyId ? policyId : '';
            

            saveInsurancePolicy(formData).then(async (res: any) => {

                if (res.status === 200) {
                    toast.success(res.message);


                    if (res.data && Object.keys(res.data).length) {
                        setSubmitClicked(true);
                        setLeadDetails({ ...insuranceState.leadDetail, ...res.data });

                    } else {
                        setLoading(false);

                    }
                } else {
                    toast.error('Failed to Update');
                    setLoading(false);

                }
            }).catch((error) => {
                setLoading(false);

                toast.error('An error occurred');
            });
        }
    };

    // Function to handle changes in input fields
    const onChangeHandler = (value: any, type: any) => {

        if (type === 'insurancePolicyNumber') {
            if (value && value.trim()) {
                setInsurancePolicyNumber(value);
            }
            else {
                setInsurancePolicyNumber('');
            }     
            setErrField((prev)=>({
                ...prev,
                insPolicyNumber: ""
            }));
        }
        if (type === 'refrenceNote') {
            setRefrenceNote(value);
        }
        if (type === 'PolicyDate') {
            setInsurancePolicyDate(value);

            setErrField((prev)=>({
                ...prev,
                InsPolicyGenerateDate: ""
            }));
        }

        if(['effectivity_date', 'expiry_date'].includes(type)) {
            let errorObj: any = { [type]: "" }
            //reset expiry date error
            if (type === 'effectivity_date' && value && inputFields.expiry_date && !moment(inputFields.expiry_date).isBefore(value)) {
                errorObj = { ...errorObj, expiry_date: "" }
            }

            if(type === 'effectivity_date' && value) {
                if(inputFields.expiry_date && moment(inputFields.expiry_date).startOf('day').diff(moment(value).startOf('day'), 'days') < EFFECTIVITY_EXPIRY_INTERVAL) setInputFields((prev: any) => { return {...prev, expiry_date: null}})
                setMinExpiryDate(moment(value).add(EFFECTIVITY_EXPIRY_INTERVAL, 'days').set({h: 0, m: 0, s: 0}).toDate())
            }
            setInputFields((prev) => {
                return {...prev, [type]: value}
            })
            setErrField((prev)=>({
                ...prev,
                ...errorObj
            }));
        }

    }

    // Validate the form fields before submitting
    const validForm = () => {
        let formIsValid = true;
        setErrField({
            insPolicyNumber: "",
            InsPolicyGenerateDate: "",
            effectivity_date: "",
            expiry_date: ""
        });

        if (insurancePolicyNumber === "" && formType === 'insurance-policy-generated') {

            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                insPolicyNumber: "Insurance Policy Number is required",
            }));
        }
        if (!insurancePolicyDate) {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                InsPolicyGenerateDate: "Insurance policy generated date is required",
            }));
        }

        if(formType === 'insurance-policy-generated') {
            let fieldsToValidate = ['expiry_date', 'effectivity_date']
            fieldsToValidate.forEach((field: any) => {
                if(!inputFields[field as keyof typeof inputFields]) {
                    let msg = field.replaceAll('_', " ")
                    msg = `Insurance policy ${msg} is Required`
                    setErrField((prevState) => ({
                        ...prevState,
                        [field]: msg
                    }));
                }
            })

            if(inputFields.expiry_date && inputFields.effectivity_date) {
                let checkIfExpiryValid = moment(inputFields.expiry_date).isBefore(inputFields.effectivity_date)
                let checkIfEffDateValid = moment(inputFields.expiry_date).startOf('day').diff(moment(inputFields.effectivity_date).startOf('day'), 'days') < 60 ? true : false
                let errorMessage = checkIfEffDateValid ? `Interval between Expiry date and Effectivity date should be of ${EFFECTIVITY_EXPIRY_INTERVAL} days`: null

                if(checkIfExpiryValid || checkIfEffDateValid) {
                    formIsValid = false
                    setErrField((prevState) => ({
                        ...prevState,
                        expiry_date: errorMessage ? errorMessage : `The Expiry date cannot be earlier than the Effectivity date.`
                    }));
                }
            }
        }


        let getPolicySharedDoc = insuranceState?.leadDetail?.images.filter((docs: any) => docs.doc_type_id === DOCUMENT_CATEGORY['disbursal']['childs']['policy_shared']);

        let getSoftApprovalDoc = insuranceState?.leadDetail?.images.filter((docs: any) => docs.doc_type_id === DOCUMENT_CATEGORY['disbursal']['childs']['soft_approval']);

        //SOFT APPROVAL DOC CHECK IN CASE OF WITHOUT RULE ENGINE
        if (!getSoftApprovalDoc.length && !filesCount && formType === 'soft-approval-received' && +insuranceState.leadDetail.rule_engine_id === WITHOUT_RULE_ENGINE) {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                policyDocument: "Please select at least one soft approval document",
            }));
        }

        //POLICY SHARED DOC CHECK
        if (!getPolicySharedDoc.length && !filesCount && formType === 'insurance-policy-generated') {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                policyDocument: "Please select at least one policy document",
            }));
        }

        return formIsValid;
    };



    // Redirect to the next page after successful update
    const nextPageRedirect = () => {

        if (formType === 'insurance-policy-generated') {
            navigate(`/details/insurance-policy-shared/${queryParams.id}`);
        }
        if (formType === 'soft-approval-received') {
            navigate(`/details/insurance-policy-generated/${queryParams.id}`);
        }

    }


    //  Fetch insurance details when the component mounts  - type 2 is for Insurance Policy Generated
    useEffect(() => {
        if (insuranceState && insuranceState.leadDetail) {

            if (formType === 'soft-approval-received') {
                setRefrenceNote((insuranceState.leadDetail.soft_approval_notes) || "");
                setPolicyId((insuranceState.leadDetail.soft_approval_id) || "");
                const policyDate = insuranceState.leadDetail.soft_approval_date_time && new Date(insuranceState.leadDetail.soft_approval_date_time);
                setInsurancePolicyDate(policyDate);
            }
            if (formType === 'insurance-policy-generated') {
                setRefrenceNote((insuranceState.leadDetail.policy_generate_notes) || "");
                setInsurancePolicyNumber((insuranceState.leadDetail.policy_number) || "");
                setPolicyId(insuranceState.leadDetail.policy_generate_id);
                const policyDate = insuranceState.leadDetail.policy_generate_date_time && new Date(insuranceState.leadDetail.policy_generate_date_time);
                setInsurancePolicyDate(policyDate);

                let effDate = insuranceState.leadDetail.policy_effectivity_date ? new Date(insuranceState.leadDetail.policy_effectivity_date) : null
                let expDate = insuranceState.leadDetail.policy_expiry_date ? new Date(insuranceState.leadDetail.policy_expiry_date) : null
                let dates = {effectivity_date : effDate, expiry_date: expDate}
                setInputFields(dates)
            }
            setErrField((prev)=>({
                InsPolicyGenerateDate:"",
                insPolicyNumber: "",
                expiry_date: "",
                effectivity_date: ""
            }));
        }
        // eslint-disable-next-line
    }, [formType, insuranceState]);


    useEffect(() => {
        if (formType && insuranceState.leadDetail.customer_api_call) {
            setLoading(true)
            let reqst: any = { lead_id: queryParams.id }
            let apiType = formType === 'soft-approval-received' ? API_TYPES['softApprovalData'] : API_TYPES['policyGeneratedData']

            fetchLeadData({ ...reqst, apiType }).then((res: any) => {
                if (res && res.data && Object.keys(res.data).length) {
                    dispatch(updateLeadDetailsInStore({ ...insuranceState.leadDetail, ...res.data }))
                }
            }).finally(() => setLoading(false))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formType, insuranceState.leadDetail.customer_api_call])


    const resolveFileUpload = (filesStatus: any) => {
        setLoading(false);

        if (filesStatus.status) {
            dispatch(updateLeadDetailsInStore({ ...leadDetails, images: [...leadDetails.images, ...filesStatus.data] }));
            nextPageRedirect();

        }

    }

    const handleSoftApprovalSkip = (e: any) => {
        //SKIP IF NOT ALREADY SAVED
        if (!insuranceState?.leadDetail.soft_approval_id) {
            setLoading(true);
            e.preventDefault();

            let formData: any = {};

            formData['lead_id'] = queryParams.id;
            formData['type'] = SOFT_APPROVAL_RECEIVED_TYPE;
            formData['isSkip'] = true;


            saveInsurancePolicy(formData).then(async (res: any) => {
                if (res.status === 200) {

                    dispatch(updateLeadDetailsInStore({ ...insuranceState.leadDetail, ...res.data }));

                    navigate(`/details/insurance-policy-generated/${queryParams.id}`);

                }
                setLoading(false);

            }).catch(err => setLoading(false));
        }
    }

    const updatedFileCount = (param:any) => {
        setFilesCount(param);
        setErrField((prev)=> ({...prev, policyDocument: ''}))
    }

    const pastSixMonths = new Date(new Date().getFullYear(), new Date().getMonth() - 6, new Date().getDate())

    return (
        <div className="image-form-outer">
            <FullPageLoader show={loading} />
            <div className="image-tab-panel">
                <DocumentGallery formType={props?.formType} />
            </div>
            <div className="form-tab-right-panel wrapperPosition inspolicyshared">
                <div className="lead-detail-from-outer">
                    <div className="lead-form-heading">
                        <h2>
                            {formType === "soft-approval-received" ? "Soft Approval Received" :
                                formType === "insurance-policy-generated" ? "Insurance Policy Generated" :
                                    ""}
                        </h2>
                        {(((+insuranceState?.leadDetail?.rule_engine_id === WITH_RULE_ENGINE_ID) || (+insuranceState?.leadDetail?.application_type_id === AGENCY_VAL)) && formType === "soft-approval-received") && !insuranceState.leadDetail.soft_approval_id && +insuranceState?.leadDetail?.status_id !== STATUS_SUB_STATUS['status']['lost'] &&
                            <NavLink className="skip-txt" to={`/details/insurance-policy-generated/${queryParams.id}`} onClick={handleSoftApprovalSkip}>Skip</NavLink>
                        }
                    </div>
                    <div className="lead-form-filed">

                        <div className="row">
                            {formType === "insurance-policy-generated"
                                &&
                                <fieldset className="col-md-12">
                                    <div className="material" title = {insurancePolicyNumber}>
                                        <input value={insurancePolicyNumber} onChange={(e: any) => onChangeHandler(e.target.value, 'insurancePolicyNumber')} id="" type="text" placeholder=" " name="RoleName" className="form-input" />
                                        <label data-label="Insurance Policy Number *" className="form-label"></label>
                                        {errField.insPolicyNumber && (
                                            <span className="error-txt">
                                                {errField['insPolicyNumber']}
                                            </span>
                                        )}
                                    </div>
                                </fieldset>
                            }

                            <fieldset className="col-md-12">
                                <div className={(insurancePolicyDate) ? "active-label material animation-effect" : "material animation-effect "}>
                                    <InsuranceDate
                                        onChange={(e: Date) => onChangeHandler(e, 'PolicyDate')}
                                        placeholder=""
                                        text={formType === "insurance-policy-generated" ? "Insurance Policy Generated Date *" : "Insurance Acceptance Received Date *"}
                                        insurancePolicyDate={insurancePolicyDate}
                                    />
                                    {errField.InsPolicyGenerateDate && (
                                        <span className="error-txt">
                                            {errField['InsPolicyGenerateDate']}
                                        </span>
                                    )}
                                </div>

                            </fieldset>

                            {
                                formType === "insurance-policy-generated"
                                    ?
                                    <>
                                        <fieldset className="col-md-12">
                                            <div className={(inputFields?.effectivity_date) ? "active-label material animation-effect" : "material animation-effect "}>
                                                <ExpiryEffectivityDate
                                                    onChange={(e: Date) => onChangeHandler(e, 'effectivity_date')}
                                                    placeholder=""
                                                    text={"Insurance Policy Effectivity Date *"}
                                                    insurancePolicyDate={inputFields?.effectivity_date || new Date()}
                                                    minDate = {pastSixMonths}
                                                    maxDate={inputFields.expiry_date && !inputFields.effectivity_date && moment(inputFields.expiry_date).startOf('day').diff(moment().startOf('day').toDate(), 'days') < EFFECTIVITY_EXPIRY_INTERVAL ? new Date(0) : undefined}
                                                    flag = 'effectivity_date'
                                                />
                                                {errField.effectivity_date && (
                                                    <span className="error-txt">
                                                        {errField['effectivity_date']}
                                                    </span>
                                                )}
                                            </div>

                                        </fieldset>

                                        <fieldset className="col-md-12">
                                            <div className={(inputFields?.expiry_date) ? "active-label material animation-effect" : "material animation-effect "}>
                                                <ExpiryEffectivityDate
                                                    onChange={(e: Date) => onChangeHandler(e, 'expiry_date')}
                                                    placeholder=""
                                                    text={"Insurance Policy Expiry Date *"}
                                                    insurancePolicyDate={inputFields?.expiry_date}
                                                    minDate={new Date(minExpiryDate)}
                                                    flag = 'expiry_date'
                                                    effectivity_date = {inputFields?.effectivity_date || new Date()}
                                                />
                                                {errField.expiry_date && (
                                                    <span className="error-txt">
                                                        {errField['expiry_date']}
                                                    </span>
                                                )}
                                            </div>

                                        </fieldset>
                                    </>
                                    : ""
                            }
                            <fieldset className="col-md-12">
                                <div className="material" title = {refrenceNote}>
                                    <input value={refrenceNote} id="" onChange={(e: any) => onChangeHandler(e.target.value, 'refrenceNote')} type="text" placeholder=" " name="RoleName" className="form-input" maxLength={500} />
                                    <label data-label="Reference Notes" className="form-label"></label>
                                </div>
                            </fieldset>
                        </div>


                    </div>


                    <DocumentForm docTypeId={[DOCUMENT_CATEGORY['disbursal']['doc_type_id']]} docId={(formType === 'soft-approval-received') ? [DOCUMENT_CATEGORY['disbursal']['childs']['soft_approval']] : [DOCUMENT_CATEGORY['disbursal']['childs']['policy_shared']]} formType={props.formType} resolveFileUpload={resolveFileUpload} submitClicked={submitClicked} errField={errField} updatedFileCount={updatedFileCount}  />


                    <div className="btn-save-remarks m-xl-t">
                        {
                            // !HELPER.isUserHasAccessPage({ permissionID: (formType === 'soft-approval-received' ? ROLE_FEATURES['soft_approval_received'] : ROLE_FEATURES['insurance_policy_generated']), accessType: 'edit', assignUserId: insuranceState.leadDetail.assign_user_id })

                            HELPER.isUserHasUpdateAccess({ permissionID: (formType === 'soft-approval-received' ? ROLE_FEATURES['soft_approval_received'] : ROLE_FEATURES['insurance_policy_generated']), accessType: 'edit', leadDetails: insuranceState.leadDetail, pageSlug: formType })

                                ?
                                <button type="button" className="btn-primary" onClick={saveInsurancePolicyTemplate}>Save</button>
                                :
                                <button type="button" className="btn-primary" disabled>Save</button>

                        }
                        <LeadRemarksButton leadId={queryParams.id} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UsePolicyForm;