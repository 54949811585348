import React, { useState, useEffect } from 'react';
import { Dropdown, Tab, Nav } from 'react-bootstrap';
import { NavLink, useParams } from 'react-router-dom';
import CustomerDetails from './customer-detail';
import SentToInsuranceBroker from '../detail/SentToInsuranceBroker';
import InsurancePolicyShared from '../detail/InsurancePolicyShared';
import UsePolicyForm from '../detail/UsePolicyForm';
import PaymentType from './inbound-outbound-payment/PaymentType';

import LoanExpected from '../quotes/Index';
import DocumentUpload from './DocumentUpload';
import { useSelector } from 'react-redux';
import { APP_TYPE_WITH_UCF, STATUS_SUB_STATUS, ROLE_FEATURES, APP_TYPE_STANDALONE, APP_TYPE_RENEWAL, APP_TYPE_AGENCY, INBOUND_PAYMENT_FROM_OTO_INSURANCE, INBOUND_PAYMENT_FROM_INSURANCE_PARTNER, REFERRAL_FEE_PAYOUT, INSURANCE_PAYMENT_FROM_INSURANCE_PARTNER_TO_OTO, INSURANCE_PAYMENT_FROM_AGENCY_TO_INSURANCE_PARTNER, INSURANCE_PAYMENT_FROM_OTO_TO_INSURANCE_PARTNER, INSURANCE_PAYMENT_FROM_AGENCY_TO_OTO_INSURANCE, REFERRAL_FEE_PAYOUT_OUTBOUND, AGENCY_STATUS } from "@src/config/constant";
import MarkAsLostPopup from './MarkAsLostPopup';
import ReopenPopup from './ReopenPopup';
import * as HELPER from "@src/config/helper";
import AgencyInboundOutBoundPayment from './inbound-outbound-payment/AgencyInboundOutBoundPayment';


export default function LeadDetailTabs(props: any): ReturnType<React.FC> {

    const queryParams = useParams();
    const [markLostPopup, setMarkLostPopup] = useState(false);
    const [reopenPopup, setReopenPopup] = useState(false);
    const [subRoute, setSubRoute] = useState<any>();
    const [leadHashId, setLeadHashId] = useState(props.leadHashId);
    const [leftSideTabs, setLeftSideTabs] = useState<any>([]);
    const tabVisiblity: any = {
        "withUcf": {
            "customer": { "outbound": ["broker", "sales_team"] },
            "broker": { "outbound": ["sales_team"] },
            "sales_team": { "outbound": ["broker"] }
        },
        "standalone": {
            "customer": { "outbound": ["broker", "sales_team"] },
            "broker": { "outbound": ["sales_team"] }
        },
        "agency": {
            "oto_insurance": { "outbound": ["broker", "sales_team"] },
            "insurance_partner": { "outbound": ["sales_team"] }
        }
    };

    let [activeTab, setActiveTab] = useState<string>('first')
    const [menuExpanded, setMenuExpanded] = useState<any>({ customer_details: true, inbound_payment: true, outbound_payment: true });

    const insuranceState = useSelector((state: any) => state.insurance);
    let statusId = (insuranceState.leadDetail && insuranceState.leadDetail.status_id) ? +insuranceState.leadDetail.status_id : 0;
    let prevStatusId = insuranceState?.leadDetail?.prev_status_id;


    useEffect(() => {
        if (!leadHashId) {
            setLeadHashId(props.leadHashId);
        }
        // eslint-disable-next-line
    }, [props.leadHashId])

    useEffect(() => {
        if (queryParams && queryParams.type && (subRoute !== queryParams["type"])) {
            setSubRoute(queryParams["type"]);
        }
        // eslint-disable-next-line
    }, [queryParams]);

    let allStatus = insuranceState?.leadDetail?.prev_status_sub_status_ids
    
    useEffect(() => {
        let appType = ""

        if (insuranceState.leadDetail && insuranceState.leadDetail.application_type_id) {
            if ((+insuranceState.leadDetail.application_type_id === APP_TYPE_WITH_UCF)) {
                appType = "withUcf"
            }
            if (([APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(+insuranceState.leadDetail.application_type_id))) {
                appType = "standalone"
            }
            if ((+insuranceState.leadDetail.application_type_id === APP_TYPE_AGENCY)) {
                appType = "agency"
            }

        }

        let updatedLeftBar: any = [
            {
                name: "Customer Details",
                key: "customer_details",
                isVisible: true,
                order: 1,
                url: `/details/personal-details/${insuranceState.leadDetail.hashId}`,
                activeRoutes: ['personal-details', 'residential-details', 'vehicle-details'],
                // activeOnSubStatus: STATUS_SUB_STATUS['subStatus']['customer_details_filled'],
                // clickableOnSubStatus: STATUS_SUB_STATUS['subStatus']['customer_details_filled'],
                isClickable: true,
                completedOnSubStatus: true,//STATUS_SUB_STATUS['subStatus']['customer_details_filled'],
                isLinkAccessible: ROLE_FEATURES['customer_details'],
                child: [
                    {
                        name: "Personal Details",
                        key: "personal_details",
                        isVisible: true,
                        url: `/details/personal-details/${insuranceState.leadDetail.hashId}`,
                        activeRoutes: ['personal-details'],
                        // activeOnSubStatus: STATUS_SUB_STATUS['subStatus']['customer_details_filled'],
                        completedOnSubStatus: true,//STATUS_SUB_STATUS['subStatus']['customer_details_filled'],
                        isClickable: true,
                        isLinkAccessible: ROLE_FEATURES['personal_details'],

                    },
                    {
                        name: "Residential Information",
                        key: "residential_information",
                        isVisible: true,
                        url: `/details/residential-details/${insuranceState.leadDetail.hashId}`,
                        activeRoutes: ['residential-details'],
                        // activeOnSubStatus: STATUS_SUB_STATUS['subStatus']['customer_details_filled'],
                        completedOnSubStatus: true,//STATUS_SUB_STATUS['subStatus']['customer_details_filled'],
                        isClickable: true,
                        isLinkAccessible: ROLE_FEATURES['residential_details'],

                    },
                    {
                        name: "Vehicle Details",
                        key: "vehicle_details",
                        isVisible: true,
                        url: `/details/vehicle-details/${insuranceState.leadDetail.hashId}`,
                        activeRoutes: ['vehicle-details'],
                        // activeOnSubStatus: STATUS_SUB_STATUS['subStatus']['customer_details_filled'],
                        completedOnSubStatus: true,//STATUS_SUB_STATUS['subStatus']['customer_details_filled'],
                        isClickable: true,
                        isLinkAccessible: ROLE_FEATURES['vehicle_details'],

                    },
                    {
                        name: "Other Details",
                        key: "other_details",
                        isVisible: (insuranceState.leadDetail.application_type_id === APP_TYPE_AGENCY) ? false : true,
                        url: `/details/other-details/${insuranceState.leadDetail.hashId}`,
                        activeRoutes: ['other-details'],
                        // activeOnSubStatus: STATUS_SUB_STATUS['subStatus']['customer_details_filled'],
                        completedOnSubStatus: true,//STATUS_SUB_STATUS['subStatus']['customer_details_filled'],
                        isClickable: true,
                        isLinkAccessible: ROLE_FEATURES['vehicle_details'],

                    },
                    {
                        name: "Payment Details",
                        key: "payment_details",
                        isVisible: (+insuranceState?.leadDetail?.paid_by_customer === 1 || +insuranceState?.leadDetail?.paid_from_agency === 1) ? true : false,
                        url: `/details/payment-details/${insuranceState.leadDetail.hashId}`,
                        activeRoutes: ['payment-details'],
                        // activeOnSubStatus: STATUS_SUB_STATUS['subStatus']['customer_details_filled'],
                        completedOnSubStatus: true,//STATUS_SUB_STATUS['subStatus']['customer_details_filled'],
                        isClickable: true,
                        isLinkAccessible: ROLE_FEATURES['payment_details'],

                    }
                ]
            },
            {
                name: "Sent to Insurance Broker",
                key: "sent_to_insurace_broker",
                isVisible: true,
                order: 2,
                child: [],
                url: `/details/sent-to-insurance-broker/${insuranceState.leadDetail.hashId}`,
                activeRoutes: ['sent-to-insurance-broker'],
                // activeOnSubStatus: STATUS_SUB_STATUS['subStatus']['sent_to_insurance_broker'],
                // clickableOnSubStatus: STATUS_SUB_STATUS['subStatus']['customer_details_filled'],
                isClickable: true,
                completedOnSubStatus: (allStatus && allStatus.includes('2-4')) || ((insuranceState.leadDetail.is_policy_shared_broker_skipped || insuranceState.leadDetail.policy_shared_broker_source) ? true : false),//STATUS_SUB_STATUS['subStatus']['sent_to_insurance_broker'],
                isLinkAccessible: ROLE_FEATURES['send_to_insurance_broker'],

            },
            {
                name: "Soft Approval Received",
                key: "soft_approval_received",
                isVisible: true,
                order: 3,
                child: [],
                url: `/details/soft-approval-received/${insuranceState.leadDetail.hashId}`,
                activeRoutes: ['soft-approval-received'],
                // activeOnSubStatus: insuranceState.leadDetail.soft_approval_id ? true : false,//STATUS_SUB_STATUS['subStatus']['soft_approval_received'],
                // clickableOnSubStatus: insuranceState.leadDetail.soft_approval_id ? true : false,//STATUS_SUB_STATUS['subStatus']['sent_to_insurance_broker'],
                isClickable: (allStatus && allStatus.includes('2-4')) || ((insuranceState.leadDetail.is_policy_shared_broker_skipped || insuranceState.leadDetail.policy_shared_broker_source) ? true : false),
                isSkippable: true,
                completedOnSubStatus: (allStatus && allStatus.includes('2-5')) || insuranceState.leadDetail.soft_approval_id ? true : false,//STATUS_SUB_STATUS['subStatus']['soft_approval_received'],
                isLinkAccessible: ROLE_FEATURES['soft_approval_received'],

            },
            {
                name: "Insurance Policy Generated",
                key: "insuance_policy_generated",
                isVisible: true,
                order: 4,
                child: [],
                url: `/details/insurance-policy-generated/${insuranceState.leadDetail.hashId}`,
                activeRoutes: ['insurance-policy-generated'],
                // activeOnSubStatus: STATUS_SUB_STATUS['subStatus']['policy_generated'],
                // clickableOnSubStatus: STATUS_SUB_STATUS['subStatus']['soft_approval_received'],
                isClickable: (allStatus && allStatus.includes('2-5')) || insuranceState.leadDetail.soft_approval_id ? true : false,//,
                completedOnSubStatus: (allStatus && allStatus.includes('3-6')) || insuranceState.leadDetail.policy_generate_id ? true : false,//STATUS_SUB_STATUS['subStatus']['policy_generated'],
                isLinkAccessible: ROLE_FEATURES['insurance_policy_generated'],

            },
            {
                name: "Insurance Policy Shared",
                key: "insurance_policy_shared",
                isVisible: true,
                order: (insuranceState.getInboundPaymentFrom !== "broker") ? 6 : 5,
                child: [],
                url: `/details/insurance-policy-shared/${insuranceState.leadDetail.hashId}`,
                activeRoutes: ['insurance-policy-shared'],
                // activeOnSubStatus: STATUS_SUB_STATUS['subStatus']['policy_shared'],
                // clickableOnSubStatus: STATUS_SUB_STATUS['subStatus']['policy_generated'],
                isClickable: ((allStatus && allStatus.includes('3-6')) || insuranceState.leadDetail.policy_generate_id) ? true : false,//,
                completedOnSubStatus: ((allStatus && allStatus.includes('3-7')) || insuranceState.leadDetail.policy_shared_customer_source || insuranceState.leadDetail.is_policy_shared_sfa) ? true : false,//STATUS_SUB_STATUS['subStatus']['policy_shared'],
                isLinkAccessible: ROLE_FEATURES['insurance_policy_shared']
            },
            {
                name: "Payment from Customer to Partner/Broker",
                key: "payment_from_customer_to_broker",
                isVisible: false,
                order: 5,
                child: [],
                url: `/details/payment-from-customer-to-partner-broker/${insuranceState.leadDetail.hashId}`,
                activeRoutes: ['payment-from-customer-to-partner-broker'],
                // activeOnSubStatus: STATUS_SUB_STATUS['subStatus']['payment_from_customer_to_partner_broker'],
                // clickableOnSubStatus: STATUS_SUB_STATUS['subStatus']['policy_shared'],
                isClickable: (allStatus && allStatus.includes('3-7')) || insuranceState.leadDetail.policy_shared_customer_source ? true : false,
                completedOnSubStatus: (allStatus && allStatus.includes('4-21')) || (insuranceState.leadDetail.pay_from_cust_to_partner_broker_id || insuranceState.leadDetail?.installment_details?.['0_installment']?.amount) ? true : false, //STATUS_SUB_STATUS['subStatus']['payment_from_customer_to_partner_broker'], 
                isLinkAccessible: ROLE_FEATURES['payment_from_customer_to_partner_broker']
            },
            {
                name: "Inbound Payment",
                key: "inbound_payment",
                isVisible: false,
                order: 6,
                url: `/details/payment-customer/${insuranceState.leadDetail.hashId}`,
                activeRoutes: ['payment-customer', 'payment-sales-team', 'payment-broker'],
                // activeOnSubStatus: STATUS_SUB_STATUS['subStatus']['payment_from_customer'],
                // clickableOnSubStatus: STATUS_SUB_STATUS['subStatus']['payment_from_customer_to_partner_broker'],
                isClickable: false,
                completedOnSubStatus: (allStatus && (allStatus.includes('4-8') || allStatus.includes('4-9') || allStatus.includes('4-10') || allStatus.includes('5-11'))) || (insuranceState.leadDetail.inbound_id || (!insuranceState.leadDetail.pay_from_cust_to_partner_broker_id && !insuranceState.leadDetail.pay_from_cust_to_partner_broker_payment_from && insuranceState.leadDetail?.installment_details?.['0_installment']?.amount)) ? true : false,//STATUS_SUB_STATUS['subStatus']['payment_from_customer'],
                isLinkAccessible: ROLE_FEATURES['inbound_payment'],
                child: [
                    {
                        name: "Payment from Customer",
                        key: "payment_from_customer",
                        isVisible: false,
                        url: `/details/payment-customer/${insuranceState.leadDetail.hashId}`,
                        activeRoutes: ['payment-customer'],
                        // activeOnSubStatus: STATUS_SUB_STATUS['subStatus']['sent_to_insurance_broker'],
                        completedOnSubStatus: (allStatus && allStatus.includes('4-8')) || (insuranceState.leadDetail.inbound_id || insuranceState.leadDetail?.installment_details?.['0_installment']?.amount) ? true : false,//STATUS_SUB_STATUS['subStatus']['payment_from_customer'],
                        isLinkAccessible: ROLE_FEATURES['payment_from_customer']


                    },
                    {
                        name: "Payment from Sales Team",
                        key: "payment_from_sales_team",
                        isVisible: false,
                        url: `/details/payment-sales-team/${insuranceState.leadDetail.hashId}`,
                        activeRoutes: ['payment-sales-team'],
                        // activeOnSubStatus: STATUS_SUB_STATUS['subStatus']['payment_from_sales_team'],
                        completedOnSubStatus: (allStatus && allStatus.includes('4-9')) || (insuranceState.leadDetail.inbound_id || insuranceState.leadDetail?.installment_details?.['0_installment']?.amount) ? true : false,//STATUS_SUB_STATUS['subStatus']['payment_from_sales_team'],
                        isLinkAccessible: ROLE_FEATURES['payment_from_sales_team']


                    },
                    {
                        name: "Payment from Insurance Broker",
                        key: "payment_from_broker",
                        isVisible: false,
                        url: `/details/payment-broker/${insuranceState.leadDetail.hashId}`,
                        activeRoutes: ['payment-broker'],
                        // activeOnSubStatus: STATUS_SUB_STATUS['subStatus']['payment_from_customer_to_partner_broker'],
                        completedOnSubStatus: ((allStatus && allStatus.includes('5-11')) || insuranceState.leadDetail.inbound_id) ? true : false,//STATUS_SUB_STATUS['subStatus']['payment_from_broker'],
                        // completedOnSubStatus: ((allStatus && allStatus.includes('5-11'))) ? true : false,//STATUS_SUB_STATUS['subStatus']['payment_from_broker'],
                        isLinkAccessible: ROLE_FEATURES['payment_from_insurance_broker']

                    }
                ],

            },
            {
                name: "Outbound Payment",
                key: "outbound_payment",
                isVisible: false,
                order: 7,
                url: `/details/payment-to-broker/${insuranceState.leadDetail.hashId}`,
                activeRoutes: ['payment-to-broker', 'payment-to-sales-team'],
                // activeOnSubStatus: STATUS_SUB_STATUS['subStatus']['payment_to_insurance_broker'],
                // clickableOnSubStatus: STATUS_SUB_STATUS['subStatus']['payment_from_customer'],
                isClickable: false,
                completedOnSubStatus: ((allStatus && allStatus.includes('5-12')) || insuranceState.leadDetail.outbound_sales_team_id) ? true : false,//STATUS_SUB_STATUS['subStatus']['payment_to_insurance_broker'],
                isLinkAccessible: ROLE_FEATURES['outbound_payment'],
                child: [
                    {
                        name: "Payment to Insurance Broker",
                        key: "payment_to_broker",
                        isVisible: false,
                        isClickable: true,
                        url: `/details/payment-to-broker/${insuranceState.leadDetail.hashId}`,
                        activeRoutes: ['payment-to-broker'],
                        // activeOnSubStatus: STATUS_SUB_STATUS['subStatus']['payment_to_insurance_broker'],
                        completedOnSubStatus: ((allStatus && allStatus.includes('4-10')) || insuranceState.leadDetail.outbound_broker_id) ? true : false,//STATUS_SUB_STATUS['subStatus']['payment_from_broker'],
                        // clickableOnSubStatus: STATUS_SUB_STATUS['subStatus']['payment_to_insurance_broker'],
                        isLinkAccessible: ROLE_FEATURES['payment_to_insurance_broker'],

                    },
                    {
                        name: "Payment to Sales Team",
                        key: "payment_to_sales_team",
                        isClickable: false,
                        isVisible: false,
                        url: `/details/payment-to-sales-team/${insuranceState.leadDetail.hashId}`,
                        activeRoutes: ['payment-to-sales-team'],
                        // activeOnSubStatus: STATUS_SUB_STATUS['subStatus']['payment_to_sales_team'],
                        completedOnSubStatus: ((allStatus && allStatus.includes('5-12')) || insuranceState.leadDetail.outbound_sales_team_id) ? true : false,//STATUS_SUB_STATUS['subStatus']['payment_to_sales_team'],
                        // clickableOnSubStatus: STATUS_SUB_STATUS['subStatus']['payment_to_insurance_broker'],
                        isLinkAccessible: ROLE_FEATURES['payment_to_sales_team'],

                    }
                ]
            },
            {
                name: "Payment from Agency to OTO Insurance",
                key: "payment_from_agency_to_oto_insurance",
                isVisible: false,
                order: 6,
                child: [],
                url: `/details/payment-from-agency-to-oto-insurance/${insuranceState.leadDetail.hashId}`,
                activeRoutes: ['payment-from-agency-to-oto-insurance'],
                isClickable: (allStatus?.includes('3-7') || insuranceState.leadDetail?.is_policy_shared_sfa) ? true : false,
                completedOnSubStatus: (allStatus?.includes('4-22') || insuranceState.leadDetail.insurance_agency_payment_id ) ? true : false, 
                isLinkAccessible: ROLE_FEATURES['payment_from_customer_to_partner_broker']
            },
            {
                name: "Payment from OTO to Insurance Partner",
                key: "payment_from_oto_to_insurance_partner",
                isVisible: false,
                order: 7,
                child: [],
                url: `/details/payment-from-oto-to-insurance-partner/${insuranceState.leadDetail.hashId}`,
                activeRoutes: ['payment-from-oto-to-insurance-partner'],
                isClickable: (allStatus?.includes('4-22') || insuranceState.leadDetail.insurance_agency_payment_id ) ? true : false,
                completedOnSubStatus: (allStatus?.includes('4-23') || insuranceState.leadDetail.insurance_agency_commission_id ) ? true : false,
                isLinkAccessible: ROLE_FEATURES['payment_from_customer_to_partner_broker']
            },
            {
                name: "Payment from Agency to Insurance Partner",
                key: "payment_from_agency_to_insurance_partner",
                isVisible: false,
                order: 6,
                child: [],
                url: `/details/payment-from-agency-to-insurance-partner/${insuranceState.leadDetail.hashId}`,
                activeRoutes: ['payment-from-agency-to-insurance-partner'],
                isClickable: (allStatus?.includes('3-7') || insuranceState.leadDetail?.is_policy_shared_sfa) ? true : false,
                completedOnSubStatus: (allStatus?.includes('4-24')  || insuranceState.leadDetail.insurance_agency_payment_id) ? true : false, 
                isLinkAccessible: ROLE_FEATURES['payment_from_customer_to_partner_broker']
            },
            {
                name: "Payment from Insurance Partner to OTO",
                key: "payment_from_insurance_partner_to_oto",
                isVisible: false,
                order: 7,
                child: [],
                url: `/details/payment-from-insurance-partner-to-oto/${insuranceState.leadDetail.hashId}`,
                activeRoutes: ['payment-from-insurance-partner-to-oto'],
                isClickable: (allStatus?.includes('4-24') || insuranceState.leadDetail.insurance_agency_payment_id) ? true : false,
                completedOnSubStatus: (allStatus?.includes('4-25')) || (insuranceState.leadDetail.insurance_agency_commission_id) ? true : false, 
                isLinkAccessible: ROLE_FEATURES['payment_from_customer_to_partner_broker']
            },
            {
                name: "Referral Fee Payout",
                key: "referral_fee_payout",
                isVisible: false,
                order: 8,
                child: [],
                url: `/details/referral-fee-payout/${insuranceState.leadDetail.hashId}`,
                activeRoutes: ['referral-fee-payout'],
                isClickable: (allStatus?.includes('4-23') || allStatus?.includes('4-25') || insuranceState.leadDetail.insurance_agency_commission_id) ? true : false,
                completedOnSubStatus: (allStatus?.includes('5-26') || insuranceState.leadDetail.referral_fee_payout_id) ? true : false,
                isLinkAccessible: ROLE_FEATURES['payment_from_customer_to_partner_broker']
            }
        ];

        let updatedSideBar: any = [];
        let subStatusId = +insuranceState.leadDetail.sub_status_id;
        let statusId = +insuranceState.leadDetail.status_id;
        for (let elm of updatedLeftBar) {
            // let elmData = elm;
            if ((insuranceState.getInboundPaymentFrom) && appType) {
                if (insuranceState.leadDetail.application_type_id === APP_TYPE_AGENCY) {
                    if(insuranceState.getInboundPaymentFrom === "oto_insurance"){

                        if (elm.key === "payment_from_agency_to_oto_insurance") {
                            elm = {
                                ...elm, isVisible: true,                                
                            }
                            
                        }
                        if (elm.key === "payment_from_oto_to_insurance_partner") {
                            elm = {
                                ...elm, isVisible: true,                                
                            }
                            
                        }
                    }else if(insuranceState.getInboundPaymentFrom === "insurance_partner"){

                        if (elm.key === "payment_from_agency_to_insurance_partner") {
                            elm = {
                                ...elm, isVisible: true,                                
                            }
                            
                        }
                        if (elm.key === "payment_from_insurance_partner_to_oto") {
                            elm = {
                                ...elm, isVisible: true,                                
                            }
                            
                        }
                    }

                    if (elm.key === "referral_fee_payout") {
                        elm = {
                            ...elm, isVisible: true,                            
                        }
                        
                    }
                } else {

                    if (elm.key === "inbound_payment") {
                        elm = {
                            ...elm, isVisible: true,
                            order: (insuranceState.getInboundPaymentFrom !== "broker" && insuranceState.leadDetail.application_type_id === APP_TYPE_WITH_UCF) ? 5 : elm.order, //IF NOT BROKER THEN PAYMENT PROOF REQUIRED
                            child: elm.child.map((el: any) =>
                                (el.key === `payment_from_${insuranceState.getInboundPaymentFrom}`) ?
                                    {
                                        ...el, isVisible: true,
                                        isClickable: (+insuranceState.leadDetail.application_type_id === 1 && (['customer', 'sales_team'].includes(insuranceState.getInboundPaymentFrom) || (allStatus && allStatus.includes('3-7') && allStatus.includes('4-21')))) ?
                                            true
                                            :
                                            (
                                                (['broker'].includes(insuranceState.getInboundPaymentFrom) && (insuranceState.leadDetail.pay_from_cust_to_partner_broker_id || insuranceState.leadDetail.pay_from_cust_to_partner_broker_payment_from))
                                                    ?
                                                    true
                                                    :
                                                    (!['broker'].includes(insuranceState.getInboundPaymentFrom) && (insuranceState.leadDetail.inbound_id || insuranceState.leadDetail.policy_shared_customer_source)
                                                        ?
                                                        true
                                                        :
                                                        false)
                                            )
                                    }
                                    :
                                    { ...el, isVisible: false }
                            )
                        }
                    }
                    else if (elm.key === "insurance_policy_shared") {
                        elm = {
                            ...elm,
                            isClickable: (
                                +insuranceState.leadDetail.application_type_id === 1 && ['customer', 'sales_team'].includes(insuranceState.getInboundPaymentFrom)
                                    ?
                                    (((insuranceState.leadDetail.application_type_id === 1 && (allStatus && (allStatus.includes('4-8') || allStatus.includes('4-9')))) || insuranceState.leadDetail.inbound_amount) ? true : false)
                                    :
                                    elm.isClickable
                            )


                        }

                    }
                    else if (elm.key === "outbound_payment") {

                        elm = {
                            ...elm, isVisible: true,
                            completedOnSubStatus: (+insuranceState.leadDetail.application_type_id === 1 && +insuranceState.leadDetail.inbound_payment_from === 2) ? (insuranceState.leadDetail.outbound_broker_id ? true : false) : elm.completedOnSubStatus,
                            child: elm.child.map((el: any, key: number) => {
                                el = (tabVisiblity[appType][insuranceState.getInboundPaymentFrom]['outbound'].includes(el.key.replace("payment_to_", ""))) ?
                                    {
                                        ...el, isVisible: true,
                                        isClickable: (
                                            (+insuranceState.leadDetail.application_type_id === 1 && insuranceState.getInboundPaymentFrom === "customer")
                                                ?
                                                (el.key === "payment_to_broker" && (insuranceState.leadDetail.policy_shared_customer_source || (allStatus && allStatus.includes('3-7')))) ? true : (insuranceState.leadDetail.outbound_broker_id ? true : false)
                                                :
                                                (
                                                    (+insuranceState.leadDetail.application_type_id === 1 && insuranceState.getInboundPaymentFrom === "broker" && el.key === "payment_to_sales_team")
                                                        ?
                                                        (((allStatus && (allStatus.includes('5-11') || allStatus.includes('5-12'))) || insuranceState.leadDetail.inbound_amount) ? true : false)
                                                        :
                                                        (
                                                            (+insuranceState.leadDetail.application_type_id === 1 && insuranceState.getInboundPaymentFrom === "sales_team" && el.key === "payment_to_broker")
                                                                ?
                                                                (((allStatus && allStatus.includes('3-7')) || insuranceState.leadDetail.policy_shared_customer_source) ? true : false)
                                                                :
                                                                (
                                                                    ([APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(+insuranceState.leadDetail.application_type_id) && insuranceState.getInboundPaymentFrom === "customer")
                                                                        ?
                                                                        (el.key === "payment_to_broker")
                                                                            ?
                                                                            (((allStatus && allStatus.includes('4-8')) || insuranceState.leadDetail.inbound_id) ? true : false)
                                                                            :
                                                                            (((allStatus && allStatus.includes('4-10')) || insuranceState.leadDetail.outbound_broker_id) ? true : false)
                                                                        :
                                                                        (
                                                                            ([APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(+insuranceState.leadDetail.application_type_id) && insuranceState.getInboundPaymentFrom === "broker" && el.key === "payment_to_sales_team")
                                                                                ?
                                                                                (((allStatus && allStatus.includes('5-11')) || insuranceState.leadDetail.inbound_amount) ? true : false)
                                                                                :
                                                                                el.isClickable
                                                                        )
                                                                )
                                                        )
                                                )
                                        ),



                                    }
                                    :
                                    { ...el, isVisible: false }
                                return el
                            }
                            )
                        }

                    }
                    else if (elm.key === "payment_from_customer_to_broker") {
                        //SHOW ONLY FOR VALID VALUES IF STATUS NOT CROSSED FROM INBOUND
                        // if((insuranceState.leadDetail.pay_from_cust_to_partner_broker_id && insuranceState.leadDetail.inbound_amount) || (insuranceState.getInboundPaymentFrom === "broker" && !insuranceState.leadDetail.inbound_amount) ) {
                        if ((insuranceState.leadDetail.pay_from_cust_to_partner_broker_id && insuranceState.leadDetail.inbound_amount) || (insuranceState.getInboundPaymentFrom === "broker")) {
                            elm = { ...elm, isVisible: true }
                        }

                    }
                }

            }

            if (+statusId === STATUS_SUB_STATUS['status']['lost']) {
                subStatusId = insuranceState?.leadDetail?.prev_sub_status_id;
            }

            //MAKE NAVLINK CLICKABLE BASED ON SUBSTATUS
            if (subStatusId >= elm.activeOnSubStatus) {
                // elm['isClickable'] = true;
                // elm['isVisible'] = true;

            }

            if (subStatusId >= elm.clickableOnSubStatus) {
                // elm['isClickable'] = true;
                // elm['isVisible'] = true;
            }

            if (+insuranceState?.leadDetail['doc_status_id'] !== 3 && !["customer_details", "sent_to_insurace_broker"].includes(elm['key'])) {
                elm['isClickable'] = false;
            }
            updatedSideBar.push(elm)
        }
        
        updatedSideBar = updatedSideBar.sort((a: any, b: any) => a.order - b.order);
        setLeftSideTabs([...updatedSideBar])
        // }
        // eslint-disable-next-line
    }, [insuranceState.getInboundPaymentFrom, insuranceState.leadDetail])

    const showModalMarkAsLost = (set: boolean) => {
        setMarkLostPopup(set)
    }

    const showReopenPopup = (set: boolean) => {
        setReopenPopup(set)
    }
    const pageSwitch = (param: string, cases: { [key: string]: any }) => {
        if (cases[param]) {
            return cases[param]
        } else {
            return cases.default
        }
    }

    const navClickHandler = (e: any, navElement: any) => {

        if (!navElement.isClickable) e.preventDefault();

        if (navElement.type && navElement.type === "toggleHandler") {
            e.preventDefault();

            setMenuExpanded({ ...menuExpanded, [navElement.key]: !menuExpanded[navElement.key] })

        }
    }

    const getTabClass = (elm: any) => {

        // let subStatusId = (insuranceState.leadDetail && insuranceState.leadDetail.sub_status_id) ? +insuranceState.leadDetail.sub_status_id : 0;
        let prevStatusId = (insuranceState.leadDetail && insuranceState.leadDetail.prev_status_id) ? +insuranceState.leadDetail.prev_status_id : 0;
        let statusId = (insuranceState.leadDetail && insuranceState.leadDetail.status_id) ? +insuranceState.leadDetail.status_id : 0;

        // if (+statusId === STATUS_SUB_STATUS['status']['lost']) {
        //     subStatusId = insuranceState?.leadDetail?.prev_sub_status_id;
        // }

        let classname = '';
        if (elm['activeRoutes'].includes(subRoute)) {

            classname = "active";
        }

        //FOR INSURANCE POLICY SHARED EQUAL VALUES ARE MATCHING
        if (elm.key === "insurance_policy_shared") {
            // if(subStatusId == elm.completedOnSubStatus){
            if (insuranceState.leadDetail.policy_shared_customer_source || (allStatus && allStatus.includes('3-7')) || insuranceState.leadDetail.is_policy_shared_sfa) {
                classname += " completed";
            }

        } else if (elm.key === "outbound_payment" && +insuranceState.leadDetail.inbound_payment_from === 3 && insuranceState.leadDetail.outbound_broker_id) { //IF FROM CUSTOMER AND BROKER AMOUNT PAID
            classname += " active";
            if (insuranceState.leadDetail.outbound_sales_team_id || (allStatus && allStatus.includes('5-12'))) {
                classname += " completed";

            }

        } else {
            //CHECK IF SUBSTATUS IS GREATER THAN COMPLETED
            if (prevStatusId && prevStatusId !== 6 && +statusId === 6 && elm.completedOnSubStatus) {
                // && ![13, 14,15,16,17,18,19,20].includes(+subStatusId)) {

                classname += " completed";

            } else if (statusId !== 6 && elm.completedOnSubStatus) {
                // && ![13, 14,15,16,17,18,19,20].includes(+subStatusId)) {
                classname += " completed";

            }
        }

        if (+insuranceState.leadDetail['soft_approval_is_skipped'] && elm['isSkippable']) {
            classname = "active completed";

        }
        // console.log(elm, classname, subStatusId, insuranceState.leadDetail)
        // if(elm.key=="payment_from_broker") console.log(elm, classname, subStatusId)

        if (!HELPER.isUserHasUpdateAccess({ permissionID: elm.isLinkAccessible, accessType: 'isVisible', leadDetails: insuranceState?.leadDetail })) {
            classname = "hide";

        }
        return classname;

    }

    let showMarkAsLost = (statusId < STATUS_SUB_STATUS['status']['insurance_payment']) ? true : false;            // after payment is done hide mark as lost button 
    let showReopen = (prevStatusId && +prevStatusId === STATUS_SUB_STATUS['status']['insurance_policy']) ? false : true;
    return (
        <div className="lead-detail-tabs">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                <Nav variant="pills" className="flex-column" onSelect={(e: any) => setActiveTab(e)}>
                    <div className="tab-list">
                        <Nav.Item>
                            <Nav.Link eventKey="first">Insurance Details</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second">Quote Details</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="third">Docs Upload</Nav.Link>
                        </Nav.Item>
                    </div>
                    {statusId && +statusId !== STATUS_SUB_STATUS['status']['lost'] && showMarkAsLost && HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['lost'], accessType: 'isVisible' } ) && ((insuranceState?.leadDetail?.application_type_id === APP_TYPE_AGENCY && insuranceState?.leadDetail?.agency_id && ![AGENCY_STATUS['inprogress'], AGENCY_STATUS['rejected']].includes(+insuranceState?.leadDetail?.agency_status)) || insuranceState?.leadDetail?.application_type_id !== APP_TYPE_AGENCY) ? <button className="lost-btn" onClick={() => showModalMarkAsLost(true)}>Mark as Lost</button> : ""}

                    {statusId && +statusId === STATUS_SUB_STATUS['status']['lost'] && showReopen && HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['re-open'], accessType: 'isVisible' }) ? <button className="reopen-btn" onClick={() => showReopenPopup(true)}> Re-Open</button> : ""}

                </Nav>

                <Tab.Content>
                    <Tab.Pane eventKey="first">
                        <div className="detail-form-sec">

                            <div className={`menu-tab-left-panel ${(+insuranceState.leadDetail.application_type_id === APP_TYPE_AGENCY) ? "menu-tab-left-panel-agency" : ""}`}>
                                <div className="dealer-tab-left-panel nav">
                                    <ul>

                                        {
                                            leftSideTabs.map((elm: any, key: number) => {

                                                return elm.child.length && elm.isVisible
                                                    ?
                                                    <li key={elm.key}>
                                                        <Dropdown data-id={menuExpanded[elm.key]} className={` ${getTabClass(elm)} d-inline mx-2 ${(!menuExpanded[elm.key] ? 'shows' : '')}`} autoClose={false} show={menuExpanded[elm.key]} >
                                                            <Dropdown.Toggle id="dropdown-autoclose-false " onClick={(e) => navClickHandler(e, { ...elm, type: 'toggleHandler' })}>

                                                                <span className="img-type"></span>
                                                                {elm.name}

                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu >

                                                                <ul>
                                                                    {
                                                                        elm.child.map((el: any) =>
                                                                            el.isVisible && <li key={el.key}>
                                                                                <NavLink to={el['url']}
                                                                                    onClick={(e) => navClickHandler(e, el)}
                                                                                    className={getTabClass(el)}

                                                                                >
                                                                                    <span className="img-type"></span>
                                                                                    {el.name}
                                                                                </NavLink>

                                                                            </li>
                                                                        )
                                                                    }

                                                                </ul>

                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </li>
                                                    :
                                                    elm.isVisible && <li key={elm.key}>
                                                        <NavLink to={`${elm['url']}`}
                                                            className={getTabClass(elm)}

                                                            onClick={(e) => navClickHandler(e, elm)}>
                                                            <span className="img-type"></span>
                                                            {elm.name}</NavLink>
                                                    </li>

                                            })
                                        }

                                    </ul>

                                </div>
                            </div>
                            <div className="route-files">
                                {
                                    leadHashId && pageSwitch(subRoute, {
                                        "personal-details": <CustomerDetails formType={subRoute} leadHashId={props.leadHashId} />,
                                        "residential-details": <CustomerDetails formType={subRoute} leadHashId={props.leadHashId} />,
                                        "vehicle-details": <CustomerDetails formType={subRoute} leadHashId={props.leadHashId} />,
                                        "other-details": <CustomerDetails formType={subRoute} leadHashId={props.leadHashId} />,
                                        "payment-details": <CustomerDetails formType={subRoute} leadHashId={props.leadHashId} />,
                                        "sent-to-insurance-broker": <SentToInsuranceBroker formType={subRoute} leadHashId={props.leadHashId} />,
                                        "insurance-policy-shared": <InsurancePolicyShared formType={subRoute} activeTab={activeTab} leadHashId={props.leadHashId} />,
                                        "soft-approval-received": <UsePolicyForm formType={subRoute} />,
                                        "insurance-policy-generated": <UsePolicyForm formType={subRoute} />,
                                        "payment-from-customer-to-partner-broker": <PaymentType formType={1} leadHashId={leadHashId} payment_type={3} />,
                                        "payment-customer": <PaymentType formType={3} leadHashId={leadHashId} payment_type={1} />,
                                        "payment-sales-team": <PaymentType formType={2} leadHashId={leadHashId} payment_type={1} />,
                                        "payment-broker": <PaymentType formType={1} leadHashId={leadHashId} payment_type={1} />,
                                        "payment-to-broker": <PaymentType formType={1} leadHashId={leadHashId} payment_type={2} />,
                                        "payment-to-sales-team": <PaymentType formType={2} leadHashId={leadHashId} payment_type={2} />,

                                        "payment-from-agency-to-oto-insurance": <AgencyInboundOutBoundPayment payment_type={INBOUND_PAYMENT_FROM_OTO_INSURANCE} leadHashId={leadHashId} formType={INSURANCE_PAYMENT_FROM_AGENCY_TO_OTO_INSURANCE} />,
                                        "payment-from-oto-to-insurance-partner": <AgencyInboundOutBoundPayment payment_type={INBOUND_PAYMENT_FROM_OTO_INSURANCE} leadHashId={leadHashId} formType={INSURANCE_PAYMENT_FROM_OTO_TO_INSURANCE_PARTNER} />,
                                        "payment-from-agency-to-insurance-partner": <AgencyInboundOutBoundPayment payment_type={INBOUND_PAYMENT_FROM_INSURANCE_PARTNER} leadHashId={leadHashId} formType={INSURANCE_PAYMENT_FROM_AGENCY_TO_INSURANCE_PARTNER} />,
                                        "payment-from-insurance-partner-to-oto": <AgencyInboundOutBoundPayment payment_type={INBOUND_PAYMENT_FROM_INSURANCE_PARTNER} leadHashId={leadHashId} formType={INSURANCE_PAYMENT_FROM_INSURANCE_PARTNER_TO_OTO} />,
                                        "referral-fee-payout": <AgencyInboundOutBoundPayment formType={REFERRAL_FEE_PAYOUT} leadHashId={leadHashId} payment_type={REFERRAL_FEE_PAYOUT_OUTBOUND} />,

                                        default: <CustomerDetails formType={subRoute} leadHashId={props.leadHashId} />
                                    })
                                }

                            </div>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                        {
                            activeTab && activeTab === 'second' ?
                                <LoanExpected />
                                : null
                        }
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                        {
                            activeTab && activeTab === 'third' ?
                                <DocumentUpload />
                                : null
                        }
                    </Tab.Pane>
                </Tab.Content>

            </Tab.Container>
            {markLostPopup ?
                <MarkAsLostPopup
                    markLostPopup={markLostPopup}
                    showModalMarkAsLost={(e: boolean) => showModalMarkAsLost(e)}
                    statusId={statusId}
                    leadHashId={leadHashId} /> : ''}

            {reopenPopup ?
                <ReopenPopup
                    reopenPopup={reopenPopup}
                    showReopenPopup={(e: boolean) => showReopenPopup(e)}
                    leadHashId={leadHashId} /> : ''}

        </div >
    )
}

