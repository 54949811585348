import React, { useState, ChangeEvent, useEffect } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { sharePolicyDetails, setInboundPaymentFromResponse, updateLeadDetailsInStore } from "@src/store/slices/insurance";
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { APP_TYPE_AGENCY, APP_TYPE_RENEWAL, WHATSAPP_WEB_URL } from "@src/config/constant";
import { useNavigate } from 'react-router-dom';
import FullPageLoader from '@common/FullPageLoader';
import Modal from '@common/ModalPopup';
import { APP_TYPE_WITH_UCF, INBOUND_PAYMENT_FROM_CUSTOMER, WITH_UCF_PAYMENT_OPTION, STANDALONE_PAYMENT_OPTION, INBOUND_PAYMENT_FROM_SALES_TEAM, APP_TYPE_STANDALONE, SHARE_METHOD, ROLE_FEATURES, DOCUMENT_CATEGORY, CURRENCY_SYMBOL, PHILIPPINE_CURRENCY, STATUS_SUB_STATUS, ALLOW_LEAD_SOURCE, INS_TYPE_CTPL, DSA_LEAD_SOURCE, MARKETING_LEAD_SOURCE, INSTALLMENT, AGENCY_PAYMENT_OPTION, INBOUND_PAYMENT_FROM_OTO_INSURANCE, AGENCY_STATUS } from "@src/config/constant";
import LeadRemarksButton from '../../common/LeadRemarksButton';
import * as HELPER from "../../../../config/helper";
import ReactSelect from '@common/ReactSelect';
import { getDocumentListing } from '../../../../store/slices/common';
import { NavLink } from 'react-router-dom';

const CONFIRMATION_POPUP_VALUE = [
    { value: 'yes', label: "Yes" },
    { value: 'no', label: "No" }
];

const ShareDetailsForm = (props: any) => {
    const [switchValue, setSwitchValue] = useState<string[]>([]);
    const [selectedDocType, setSelectedDocType] = useState<any>([]);
    let [documentCategoryList, setDocumentCategoryList] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [showFirstSubmitDocs, setShowFirstSubmitDocs] = useState<any>([]);
    const [showDisbursalDocs, setShowDisbursalDocs] = useState<any>([]);
    const [showPaymentConfirmation, setShowPaymentConfirmation] = useState<boolean>(false);
    const [paymentConfirmationData, setPaymentConfirmationData] = useState<any>({ is_paid: '', paid_by: '' });
    const [showPaymentProofOptions, sethSowPaymentProofOptions] = useState<any>(WITH_UCF_PAYMENT_OPTION);
    const [sendToSfaPolicyShared, setSendToSfaPolicyShared] = useState((props.templateType === "customer") ? true : false);
    const [selectedTab, setSelectedTab] = useState("first")

    const [errField, setErrField] = useState<any>({
        is_paid: "",
        paid_by: "",
    });
    const [policyShareModal, setPolicyShareModal] = useState<any>(false);
    const navigate = useNavigate();
    const dispatch: any = useDispatch();

    const commonState = useSelector((state: any) => state.common);
    const insuranceState = useSelector((state: any) => state.insurance);


    useEffect(() => {
        //IN CASE OF POLICY SHARED SHOW PAYMENT PROOF POPUP
        if (props.templateType === "customer") {
            if (insuranceState.leadDetail && insuranceState.leadDetail.application_type_id && !insuranceState.leadDetail.inbound_payment_from && props.activeTab === "first" && !insuranceState.getInboundPaymentFrom && insuranceState.leadDetail.status_id !== STATUS_SUB_STATUS['status']['lost']) {
                //FOR AGENCY IF POLICY SHARE THEN SHOW PAYMENT PROOF POPUP 
                if([APP_TYPE_AGENCY].includes(insuranceState.leadDetail.application_type_id)){
                    if(+insuranceState?.leadDetail?.sub_status_id === +STATUS_SUB_STATUS['subStatus']['policy_shared']){
                        sethSowPaymentProofOptions(AGENCY_PAYMENT_OPTION);
                        setShowPaymentConfirmation(true);
                    }
                }else{

                    if(!insuranceState.leadDetail.policy_shared_customer_source){

                        //IN CASE OF WITH UCF
                        if (+insuranceState.leadDetail.application_type_id === APP_TYPE_WITH_UCF) {
                            sethSowPaymentProofOptions(WITH_UCF_PAYMENT_OPTION);
                            setShowPaymentConfirmation(true);
                            
                        } else { //IN CASE OF STANDALONE
                            sethSowPaymentProofOptions(STANDALONE_PAYMENT_OPTION);
                            setShowPaymentConfirmation(true);
                        }
                    }
                }
            }
        }
         // hide remark modal
         setPolicyShareModal(false);
         // eslint-disable-next-line
    }, [props.templateType, insuranceState.leadDetail.application_type_id, insuranceState.leadDetail.policy_shared_customer_source, insuranceState.leadDetail.inbound_payment_from]);

    useEffect(() => {
        setDocumentCategoryList(commonState.documentList);
    }, [commonState.documentList]);

    useEffect(() => {
        //IN CASE OF BROKER SET DATA
        if (insuranceState.leadDetail.policy_shared_broker_source && props.templateType === "broker") {

            let savedShareValues = insuranceState.leadDetail.policy_shared_broker_source ? insuranceState.leadDetail.policy_shared_broker_source.split(",") : [];

            let switchValueUpdated = savedShareValues.map((elm: any) => { return (+elm === SHARE_METHOD["email"]) ? "email" : ((+elm === SHARE_METHOD["whatsapp"]) ? "whatsapp" : ((+elm === SHARE_METHOD["viber"]) ? "viber" : "")) });

            setSwitchValue([...switchValueUpdated]);

            let savedShareDocuments = insuranceState.leadDetail.policy_shared_broker_documents ? insuranceState.leadDetail.policy_shared_broker_documents.split(",").map((elm: any) => +elm) : [];
            setSelectedDocType([...savedShareDocuments]);
        }

        //IN CASE OF CUSTOMER SET DATA
        if (insuranceState.leadDetail.policy_shared_customer_source && props.templateType === "customer") {

            let savedShareValues = insuranceState.leadDetail.policy_shared_customer_source ? insuranceState.leadDetail.policy_shared_customer_source.split(",") : [];

            let switchValueUpdated = savedShareValues.map((elm: any) => { return (+elm === SHARE_METHOD["email"]) ? "email" : ((+elm === SHARE_METHOD["whatsapp"]) ? "whatsapp" : ((+elm === SHARE_METHOD["viber"]) ? "viber" : "")) });
            setSwitchValue([...switchValueUpdated]);

            let savedShareDocuments = insuranceState?.leadDetail?.policy_shared_customer_documents?.split(",").map((elm: any) => +elm) || [];
            setSelectedDocType([...savedShareDocuments]);
        }

        //FOR STANDALONE
        if (insuranceState.leadDetail && +insuranceState.leadDetail.application_type_id === APP_TYPE_STANDALONE) {
            sethSowPaymentProofOptions(STANDALONE_PAYMENT_OPTION);
        }
        // eslint-disable-next-line
    }, [insuranceState.leadDetail]);

    useEffect(() => {
        //SET FIRST SUBMIT DOCUMENTS
        if (props.getDocumentsIds.firstSubmit) {
            let getFirstSubmitDocs: any = []//documentCategoryList.filter((elm: any) => props.getDocumentsIds.firstSubmit.includes(+elm.value));
            for (let parent of documentCategoryList) {
                if (parent?.child.length) {
                    let parentArray: any = {};
                    //CHECK IN CHILD DOCS
                    for (let childs of parent.child) {
                        if (props.getDocumentsIds.firstSubmit.includes(+childs.value)) {
                            parentArray = JSON.parse(JSON.stringify(parent));
                            break;
                        }
                    }
                    //FILTER MATCHED CHILDS
                    if (parentArray.child) {

                        parentArray['child'] = parentArray.child.filter((ch: any) => props.getDocumentsIds.firstSubmit.includes(+ch.value));
                        getFirstSubmitDocs.push(parentArray);
                    }

                } else {
                    //CHECK IN PARENT 
                    if (props.getDocumentsIds.firstSubmit.includes(+parent?.value)) getFirstSubmitDocs.push(parent);
                }
            }
            setShowFirstSubmitDocs([...getFirstSubmitDocs]);
        }
        //SET FIRST DISBURSAL DOCUMENTS
        if (props.getDocumentsIds.disbursal) {
            let getDisbursalDocs = documentCategoryList.filter((elm: any) => props.getDocumentsIds.disbursal.includes(+elm?.value));
            setShowDisbursalDocs([...getDisbursalDocs]);
        }
    }, [props.getDocumentsIds, documentCategoryList]);

    const handleButtonChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSwitchValue((prevSwitchValue) =>
            event.target.checked
                ? [...prevSwitchValue, event.target.value]
                : prevSwitchValue.filter((el) => el !== event.target.value)
        );
    };


    const changeDocTypeHandler = (e: React.ChangeEvent<HTMLInputElement>, doc: any, subType: string | null = null) => {
        let updatedSelectedDocType: any = selectedDocType;

        if (e.target.checked) {

            if (!doc.child.length) {
                updatedSelectedDocType.push(doc.value);
            }
            for (let child of doc.child) {
                updatedSelectedDocType.push(child.value);
            }
        } else {
            updatedSelectedDocType = updatedSelectedDocType.filter((val: any) => +val !== +doc.value);

            if (doc.child.length) {
                let getChilds = doc.child.map((c: any) => c.value);
                updatedSelectedDocType = updatedSelectedDocType.filter((val: any) => !getChilds.includes(val));

            }
        }
        setSelectedDocType([...updatedSelectedDocType]);
    };

    // function of validation RequiredField
    const validationRequiredField = () => {
        const { customer_name, mobile, email, payment_method_id, payment_term_id, per_house_no, per_street, per_region, per_city, per_barangay, per_postal_code, plate_no, fuel_type, is_under_financing, insurance_policy_exists, vehicle_seat, insurance_type_id, mv_number, pay_tenure, expected_installment, lead_source, referrer_mobile, referrer_name, lead_sub_source, application_type_id, paid_by_customer, premium_amount, premium_amount_date } = insuranceState.leadDetail;
        // expected date validation
        if (pay_tenure && application_type_id === APP_TYPE_STANDALONE && payment_term_id === INSTALLMENT) {
            const months = parseInt(pay_tenure.split(' ')[0], 10);
            // Check each installment date
            for (let i = 1; i <= months; i++) {
                if (!expected_installment || (expected_installment && !expected_installment[i])) {
                    toast.error("All fields are required.")
                    return false;
                }
            }
        }
//|| ([APP_TYPE_AGENCY].includes(application_type_id) && +paid_from_agency === 1 && (!premium_amount || (!pay_tenure && !premium_amount_date))
        if (
            !customer_name || (![APP_TYPE_AGENCY].includes(application_type_id) && !mobile) || (![APP_TYPE_AGENCY].includes(application_type_id) && !email) || !payment_method_id || !payment_term_id ||
            !per_house_no || !per_street || !per_region || !per_city || !per_barangay || !per_postal_code ||
            !fuel_type || !is_under_financing || !insurance_policy_exists || !vehicle_seat ||
            (![APP_TYPE_AGENCY].includes(application_type_id) && !lead_source) || ([APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(application_type_id) && +paid_by_customer === 1 && (!premium_amount || (!pay_tenure && !premium_amount_date)))
        ) {
            toast.error("All fields are required. Please fill in all the Customer Details.");
            return;
        }

        if (premium_amount && premium_amount > insuranceState?.leadDetail?.car_fmv) {
            toast.error("Total Premium Amount should not be greater than FMV")
            return;
        }
        // for CTPL plate no or mv number required
        if (+insurance_type_id === INS_TYPE_CTPL && (!plate_no && !mv_number)) {
            toast.error("All fields are required. Please fill plate no or mv number");
            return false;
        }
        // Check if any of the required fields are empty
        if (ALLOW_LEAD_SOURCE.includes(lead_source) && ((!referrer_mobile || referrer_mobile.trim() === "") && (!referrer_name || referrer_name.trim() === ""))) {
            toast.error("All fields are required. Please fill in all the Other Details.");
            return false;
        }
        if (+lead_source === DSA_LEAD_SOURCE && (!referrer_name || referrer_name.trim() === "")) {
            toast.error("All fields are required. Please fill in all the Other Details.");
            return false;
        }
        if (+lead_source === MARKETING_LEAD_SOURCE && !lead_sub_source) {
            toast.error("All fields are required. Please fill in all the Other Details.");
            return false;
        }
        return true;
    };


    /**
     * SUBMIT HANDLER
     * @returns 
     */
    const submitHandler = async (params: any) => {

        //CHECK FIRST SUBMIT DOC STATUS
        if (props.templateType === "broker") {

            //IF BROKER ALREADY SKIPPED THEN NAVIGATE
            if (insuranceState.leadDetail.application_type_id === APP_TYPE_AGENCY && (params?.sent_to_broker_skip && (insuranceState.leadDetail.is_policy_shared_broker_skipped || insuranceState.leadDetail.policy_shared_broker_source))) {
                navigate(`/details/soft-approval-received/${insuranceState.leadDetail.hashId}`);
                return false;
            }

            const isValid = await validationRequiredField()
            if (!isValid) {
                return false;
            }

            if (+insuranceState.leadDetail.sub_status_id === 3) {
                toast.error("Currently lead is under approval for Sales Manager.");
                return false;
            }


            let mandatoryDocUploaded = true;
            if (commonState && commonState.documentList) {
                let documentList: any = JSON.parse(JSON.stringify(commonState.documentList))
                //IN CASE OF AGENCY SHOW INSTALMENT DOCS FOR DOC TYPE 1
                // if(insuranceState.leadDetail.application_type_id === APP_TYPE_AGENCY && insuranceState.leadDetail.payment_tenure_id && props.templateType === "broker"){
                //     console.log("asdhalsdjlas", props)
                //     documentList = documentList.map((e: any) => { 
                //         if(e?.child?.length && e.parent_id === 12){ 
                //             e.doc_type_id = 1;
                //             e.label = "Proof of Payment"
                //             // e.is_required = true
                //             e.child = e.child.map((child: any)=> { 
                //                 if(child.value>14 && child.value<27) child.doc_type_id = props.docType; child.is_required = true; return child 
                //             })
                //         }
                //         return e;
                //     });
                //     console.log(documentList)
                // }
                let docList = documentList.filter((docs: any) => +docs.doc_type_id === DOCUMENT_CATEGORY['firstSubmit']['doc_type_id']) //GET FIRST SUBMIT DOCS

                let uploadedImages = insuranceState.leadDetail.images.map((doc: any) => doc.doc_id);
                let conditionalMandatoryDocs: any = []
                //IN DOC LIST MATCH NOT UPLOADED DOCS
                for (let docs of docList) {
                    if (docs.conditional_mandatory && docs.conditional_mandatory.length) conditionalMandatoryDocs.push(+docs.value) //PUSH CONDITIONAL MANDATORY DOCS
                    if (!uploadedImages.includes(docs.value) && docs.is_required && +docs.value !== 4 && +docs.value !== 27) { //SKIP VEHICLE

                        mandatoryDocUploaded = false;
                        break;
                    } else if (docs?.child?.length) { //CHECK MANFATORY IMAGES                        
                        //FOR VEHICLE DOC CHILDS
                        for (let childdoc of docs.child) {
                            if (childdoc.is_required && !uploadedImages.includes(childdoc.value)) {
                                mandatoryDocUploaded = false;
                            }
                        }
                    }
                }
                //check if any conditional mandatory doc is not uploaded
                let uploadedConditionalMandDocs = uploadedImages && uploadedImages.filter((e: any) => conditionalMandatoryDocs.includes(e))
                if (conditionalMandatoryDocs.length && (!uploadedConditionalMandDocs || !uploadedConditionalMandDocs.length)) mandatoryDocUploaded = false
            }

            if (!mandatoryDocUploaded) {
                toast.error("Please upload all mandatory documents");
                return false;

            }
            if (+insuranceState.leadDetail.doc_status_id !== 3) {
                toast.error("Please approve all documents");
                return false;

            }
        }

        //IN CASE OF UCF ASK PAYMENT PROOF
        if (insuranceState.leadDetail && !insuranceState.leadDetail.inbound_payment_from && props.templateType === "customer" && (paymentConfirmationData['is_paid'] !== "yes" || (paymentConfirmationData['is_paid'] === "yes" && paymentConfirmationData['paid_by'] === "")) && !insuranceState.getInboundPaymentFrom && ![APP_TYPE_AGENCY].includes(insuranceState.leadDetail.application_type_id)) {
            setShowPaymentConfirmation(true);
            return false;
        }

        //CHECK POST DISBURSAL DOC STATUS
        if (props.templateType === "customer") {
            let getDisbursalDocs = insuranceState.leadDetail.images.filter((img: any) => +img.doc_type_id === DOCUMENT_CATEGORY['disbursal']['doc_type_id']);
            let getRequiredDisbursalDocs = insuranceState.leadDetail.images.filter((img: any) => +img.doc_type_id === DOCUMENT_CATEGORY['disbursal']['doc_type_id'] && +img.doc_id === DOCUMENT_CATEGORY['disbursal']['childs']['policy_shared']); //INSURANCE POLICY DOCUMENT REQUIRED
            let getApprovedDocs = getDisbursalDocs.filter((approved: any) => approved.doc_id && +approved.is_approved === 1);

            if (!getRequiredDisbursalDocs.length) {
                toast.error("Please upload all mandatory documents");
                return false;
            } else if (getApprovedDocs.length !== getDisbursalDocs.length) {
                toast.error("Please approve all Disbursal documents");
                return false;
            }
        }

        if (!switchValue?.length && ((props.templateType === "broker" && !params.sent_to_broker_skip) || (props.templateType === "customer" && selectedTab === "second"))) {
            // if(props.templateType === "broker" && params.sent_to_broker_skip) return true;
            // if(props.templateType === "customer" && selectedTab === "first") return true;


            toast.error("Please select at least one of the source Email/Whatsapp");
            return false;
        }

        setLoading(true);

        let getDocumentUrls = [];
        let emailTempDocs = "";
        let openWhatsapp = false;
        let waMsg = "";

        //IF WA SELECTED THEN PREPARE MSG
        if (insuranceState.leadDetail && insuranceState.leadDetail.images && switchValue.includes("whatsapp") ) {

            if((props.templateType === "customer" && selectedTab === "first" && insuranceState.leadDetail.application_type_id === APP_TYPE_AGENCY)){
                //SKIP FOR SEND TO SFA
            }else{

                openWhatsapp = true;
                getDocumentUrls = insuranceState.leadDetail.images.filter((docs: any) => selectedDocType.includes(+docs.doc_id));
                
                for (let doc of getDocumentUrls) {
                    if (doc.path.length) {
                        emailTempDocs += `${doc.doc_name}: \n`;
                        emailTempDocs += `${doc.path} \n`;
                        
                    }
                    
                }
                let formatMsg = props.templateData.replaceAll('<p>', '').replaceAll('</p>', "\n").replaceAll('<br>', "\n").replaceAll('<br/>', "\n").replaceAll('&nbsp;', "").replaceAll(PHILIPPINE_CURRENCY, CURRENCY_SYMBOL) + '\n\n' + emailTempDocs;
                waMsg = WHATSAPP_WEB_URL + encodeURIComponent(formatMsg);
                // console.log(waMsg)
            }
        }

        let paymentFrom = 0;
        if (insuranceState.getInboundPaymentFrom === "broker") {
            paymentFrom = 1;
        } else if (insuranceState.getInboundPaymentFrom === "sales_team") {
            paymentFrom = 2;
        } else if (insuranceState.getInboundPaymentFrom === "customer") {
            paymentFrom = 3;
        } else if (insuranceState.getInboundPaymentFrom === "oto_insurance") {
            paymentFrom = 4;
        } else if (insuranceState.getInboundPaymentFrom === "insurance_partner") {
            paymentFrom = 5;
        }

        // return false;
        let submitParams: any = { "source": switchValue, selectedDocType, leadId: insuranceState.leadDetail.hashId, type: (props.templateType === "broker") ? '1' : '2', payment_from: paymentFrom };

        //REPLACE REQUEST DATA IF SENT TO BROKER SKIPPED
        if (params?.sent_to_broker_skip) {
            submitParams['source'] = undefined;
            submitParams['selectedDocType'] = undefined;
            submitParams = { ...submitParams, sent_to_broker_skip: 1 }

        }
        
        if (props.templateType === "customer" && selectedTab === "first" && insuranceState.leadDetail.application_type_id === APP_TYPE_AGENCY) {

            if (!sendToSfaPolicyShared) {
                toast.error("Please select send to Agent")
                setLoading(false)
                return false;
            }

            //IF CHECKED
            submitParams['source'] = undefined;
            submitParams['selectedDocType'] = undefined;
            submitParams = { ...submitParams, sendToSfaPolicyShared: 1 }
        }
        
        // return false;
        sharePolicyDetails(submitParams).then((res: any) => {
            setLoading(false);
            if (res.status === 200) {
                // here with payload pass for fetching other details document
                const payload: any = {
                    leadId: insuranceState.leadDetail.hashId,
                    source: 'web'
                };

                dispatch(getDocumentListing(payload));

                if (res.data && Object.keys(res.data).length) {
                    dispatch(updateLeadDetailsInStore({ ...insuranceState.leadDetail, ...res.data }));
                }

                if (props.templateType === "broker") {
                    navigate(`/details/soft-approval-received/${insuranceState.leadDetail.hashId}`);
                } else if (props.templateType === "customer") { //WITH UCF AND INBOUND PAYMENT FROM CUSTOMER                  

                    //IN CASE OF AGENCY
                    if (+insuranceState.leadDetail.application_type_id === APP_TYPE_AGENCY) {

                        //IN CASE OF UCF ASK PAYMENT PROOF
                        // if (insuranceState.leadDetail && !insuranceState.leadDetail.inbound_payment_from && props.templateType === "customer" && (paymentConfirmationData['is_paid'] !== "yes" || (paymentConfirmationData['is_paid'] === "yes" && paymentConfirmationData['paid_by'] === "")) && !insuranceState.getInboundPaymentFrom && ![APP_TYPE_AGENCY].includes(insuranceState.leadDetail.application_type_id)) {
                        if(!insuranceState.leadDetail.inbound_payment_from){

                            setShowPaymentConfirmation(true);
                            sethSowPaymentProofOptions(AGENCY_PAYMENT_OPTION);
                        }else{
                            if ([INBOUND_PAYMENT_FROM_OTO_INSURANCE].includes(+insuranceState.leadDetail.inbound_payment_from)) {
                                navigate(`/details/payment-from-agency-to-oto-insurance/${insuranceState.leadDetail.hashId}`);
    
                            } else { //insurance_partner
                                navigate(`/details/payment-from-agency-to-insurance-partner/${insuranceState.leadDetail.hashId}`);
    
                            }
                        }

                            // return false;
                        // }
                        
                        
                    }
                    else if (+insuranceState.leadDetail.application_type_id === APP_TYPE_WITH_UCF) { //WITH UCF
                        if (["customer", "sales_team"].includes(paymentConfirmationData['paid_by']) || [INBOUND_PAYMENT_FROM_CUSTOMER, INBOUND_PAYMENT_FROM_SALES_TEAM].includes(+insuranceState.leadDetail.inbound_payment_from)) {

                            navigate(`/details/payment-to-broker/${insuranceState.leadDetail.hashId}`);
                        }
                        else {
                            navigate(`/details/payment-from-customer-to-partner-broker/${insuranceState.leadDetail.hashId}`);
                        }
                    } else {
                        if (paymentConfirmationData['paid_by'] === "customer" || [INBOUND_PAYMENT_FROM_CUSTOMER].includes(+insuranceState.leadDetail.inbound_payment_from)) {
                            navigate(`/details/payment-customer/${insuranceState.leadDetail.hashId}`);
                        } else {
                            navigate(`/details/payment-from-customer-to-partner-broker/${insuranceState.leadDetail.hashId}`);
                        }
                    }
                }

                if (openWhatsapp) window.open(waMsg, '_blank', 'noreferrer');
            }

        }).catch(err => setLoading(true));

    }

    /**
     * PAYMENT PROOF 
     * @param e 
     * @param key 
     */
    const changeHandler = (e: any, key: string) => {
        // let paymentConfirmationData
        setPaymentConfirmationData({ ...paymentConfirmationData, [key]: e ? e.value : "" })
        errField[key] = '';
        setErrField({ ...errField });
    }

    /**
     * PAYMENT FROM CONFIRMATION HANDLER
     */
    const paymentConfirnationHandler = () => {
    if (validForm()) {
        if (paymentConfirmationData['is_paid'] === "yes" && paymentConfirmationData['paid_by']) {
            // props.setInboundPaymentFrom(paymentConfirmationData['paid_by']);
            dispatch(setInboundPaymentFromResponse(paymentConfirmationData['paid_by']));

            //IF PAYMENT DONE TO BROKER THEN SHARE DOCs
            let applicationType = +insuranceState.leadDetail.application_type_id
            if ([APP_TYPE_AGENCY].includes(applicationType)) {

                let navigationUrl = "insurance-policy-shared";

                if (paymentConfirmationData['paid_by'] === "oto_insurance") {
                    navigationUrl = "payment-from-agency-to-oto-insurance";
                } else { //insurance_partner
                    navigationUrl = "payment-from-agency-to-insurance-partner";
                }

                navigate(`/details/${navigationUrl}/${insuranceState.leadDetail.hashId}`);

            }
            else if (paymentConfirmationData['paid_by'] === "broker" || [APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(applicationType)) {
                // submitHandler();
            } else {
                let navigationUrl = "payment-customer";
                if (paymentConfirmationData['paid_by'] === "broker") {
                    navigationUrl = "payment-broker";
                } else if (paymentConfirmationData['paid_by'] === "sales_team") {
                    navigationUrl = "payment-sales-team";
                }
                navigate(`/details/${navigationUrl}/${insuranceState.leadDetail.hashId}`);
            }
        }
        setShowPaymentConfirmation(false);
    }
    }

    //Validation 
    const validForm = () => {
        let formIsValid = true;
        setErrField({
            is_paid: "",
            paid_by: "",
          
        });
        if (paymentConfirmationData.is_paid === "") {
            formIsValid = false;
            setErrField((prevState:any) => ({
                ...prevState,
                is_paid: "This field is required",
            }));
        } 
        if (paymentConfirmationData.paid_by === "") {
            formIsValid = false;
            setErrField((prevState:any) => ({
                ...prevState,
                paid_by: "This field is required",
            }));
        }
       
        return formIsValid;
    };
    
    useEffect(()=>{
        //IN CASE OF AGENCY SHOW PAYMENT POPUP AFTER POLICY SHARED
        if (props.templateType === "customer" && +insuranceState.leadDetail.application_type_id === APP_TYPE_AGENCY && insuranceState.leadDetail.sub_status_id === +STATUS_SUB_STATUS['subStatus']['policy_shared'] && !showPaymentConfirmation && !insuranceState.leadDetail.inbound_payment_from) { //WITH UCF AND INBOUND PAYMENT FROM CUSTOMER    
            sethSowPaymentProofOptions(AGENCY_PAYMENT_OPTION);
            setShowPaymentConfirmation(true);

        }
    }, [props])

    const renderForm = () => {
        return (
            <>
                <fieldset className="from-group">
                    <div className="custom-control custom-checkbox">

                        <input
                            id="email"
                            type="checkbox"
                            onChange={handleButtonChange}
                            className="custom-control-input "
                            name="send-method-email"
                            value={"email"}
                            checked={(switchValue.includes("email"))}
                        />{" "}
                        <label className="custom-control-label">Email </label>
                    </div>

                    <div className=" custom-control custom-checkbox">
                        <input
                            id="whatsapp"
                            type="checkbox"
                            onChange={handleButtonChange}
                            name="send-method-wa"
                            value={"whatsapp"}
                            className="custom-control-input"
                            checked={(switchValue.includes("whatsapp"))}

                        />{" "}
                        <label className="custom-control-label">Whatsapp</label>
                    </div>


                    <div className=" custom-control custom-checkbox" style={{ "visibility": "hidden" }}>
                        <input
                            id="viber"
                            type="checkbox"
                            onChange={handleButtonChange}
                            name="send-method-viber"
                            value={"viber"}
                            className="custom-control-input"
                            checked={(switchValue.includes("viber"))}

                        />{" "}
                        <label className="custom-control-label">Viber</label>
                    </div>

                </fieldset>
                <div className='doc-attech-heading'>
                    <h3>Documents Attached</h3>
                </div>
                <div className="lead-form-filed send-financer">

                    <div className="financer-doc-list">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Nav variant="pills" className="flex-column tab-line">
                                <div className="tab-list">

                                    <Nav.Item>
                                        <Nav.Link eventKey="first">First Submit</Nav.Link>
                                    </Nav.Item>
                                    {
                                        props.templateType === "customer" &&
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">Disbursal</Nav.Link>
                                        </Nav.Item>
                                    }

                                </div>
                            </Nav>

                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className='contentWrapperList insurance-broker-doc-attech-list'>

                                        {
                                            showFirstSubmitDocs.length > 0 && showFirstSubmitDocs.map((doc: any, key: number) =>

                                                doc.child.length ?

                                                    <Accordion allowZeroExpanded key={doc.label}>


                                                        <AccordionItem >
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton>
                                                                    <div className=" custom-control custom-checkbox">
                                                                        <input
                                                                            id="pending"
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            onChange={(event) => changeDocTypeHandler(event, doc)}
                                                                            checked={doc.child.find((childs: any) => selectedDocType.includes(childs['value']))}
                                                                        />
                                                                        <label className="custom-control-label">{doc.label}</label>
                                                                    </div>
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel>
                                                                <div className="sub-list">
                                                                    <ul className="">

                                                                        {
                                                                            doc.child.map((childCat: any, childKey: number) =>
                                                                                <li key={childCat.label}>
                                                                                    <div className=" custom-control custom-checkbox">
                                                                                        <input
                                                                                            id="pending"
                                                                                            type="checkbox"
                                                                                            className="custom-control-input"
                                                                                            onChange={(event) => changeDocTypeHandler(event, childCat)}
                                                                                            checked={selectedDocType.includes(childCat['value'])}

                                                                                        />
                                                                                        <label className="custom-control-label">{childCat.label}</label>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        }

                                                                    </ul>
                                                                </div>
                                                            </AccordionItemPanel>
                                                        </AccordionItem>



                                                    </Accordion>

                                                    :

                                                    <div className=" custom-control custom-checkbox" key={doc.label}>
                                                        <input
                                                            id="insurancepolicy"
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            onChange={(event) => changeDocTypeHandler(event, doc)}
                                                            checked={selectedDocType.includes(doc['value'])}

                                                        />
                                                        <label className="custom-control-label">{doc.label}</label>
                                                    </div>

                                            )
                                        }
                                    </div>

                                </Tab.Pane>
                                {
                                    props.templateType === "customer" &&

                                    <Tab.Pane eventKey={"second"}>
                                        <div className='contentWrapperList'>


                                            {
                                                showDisbursalDocs.length > 0 && showDisbursalDocs.map((doc: any, key: number) =>
                                                    // <div className='approvalWrapper'>
                                                    <div className="custom-control custom-checkbox" key={doc.label}>
                                                        <input
                                                            id="insurancepolicy"
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            onChange={(event) => changeDocTypeHandler(event, doc)}
                                                            checked={selectedDocType.includes(doc['value'])}
                                                        //disabled={insuranceState.leadDetail.status_id >= 2}

                                                        />
                                                        <label className="custom-control-label">{doc.label}</label>
                                                    </div>
                                                    // {/* </div> */}
                                                )

                                            }

                                        </div>

                                    </Tab.Pane>

                                }

                            </Tab.Content>

                        </Tab.Container>

                    </div>
                </div>
            </>

        )
    }

    const sendToSfaHandler = () => {
        setSendToSfaPolicyShared(!sendToSfaPolicyShared)
    }

    const handleTabSelect = (eventKey: any) => {
        setSelectedTab(eventKey);
    }

    return (

        <div className="form-tab-right-panel">
            <FullPageLoader show={loading} />

            <div className="lead-detail-from-outer">
                <div className={" switch-toggle"}>
                    <div className="lead-form-heading">

                        <h2>{props.title}</h2>

                        {((+insuranceState?.leadDetail?.application_type_id === APP_TYPE_AGENCY) && props.templateType === "broker") && !insuranceState.leadDetail.is_sent_to_broker && !insuranceState?.leadDetail?.policy_shared_broker_source?.trim() && +insuranceState?.leadDetail?.status_id !== STATUS_SUB_STATUS['status']['lost'] &&
                            (+insuranceState?.leadDetail?.application_type_id === APP_TYPE_AGENCY && insuranceState?.leadDetail?.agency_id && ![AGENCY_STATUS['inprogress'], AGENCY_STATUS['rejected']].includes(insuranceState?.leadDetail?.agency_status)) &&
                            // eslint-disable-next-line
                            <NavLink className="skip-txt" to={`javascript:;`} onClick={() => submitHandler({ sent_to_broker_skip: 1 })}>Skip</NavLink>
                        }
                    </div>
                    <div className="fe-status-form">

                        {
                            ((+insuranceState?.leadDetail?.application_type_id === APP_TYPE_AGENCY) && props.templateType === "customer") ?
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <Nav variant="pills" className="flex-column tab-line" onSelect={(k) => handleTabSelect(k)}>
                                        <div className="tab-list">

                                            <Nav.Item>
                                                <Nav.Link eventKey="first">SFA APP</Nav.Link>
                                            </Nav.Item>
                                            {
                                                props.templateType === "customer" &&
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second">EMAIL/WHATSAPP</Nav.Link>
                                                </Nav.Item>
                                            }

                                        </div>
                                    </Nav>
                                    <Tab.Content className='m-xl-t'>
                                        <Tab.Pane eventKey="first">
                                            <fieldset className="from-group">
                                                <div className="custom-control custom-checkbox">

                                                    <input
                                                        id="policy_shared_sfa"
                                                        type="checkbox"
                                                        onChange={sendToSfaHandler}
                                                        className="custom-control-input "
                                                        name="send-method-email"
                                                        value={"policy_shared_sfa"}
                                                        checked={sendToSfaPolicyShared}
                                                        disabled={insuranceState?.leadDetail?.is_policy_shared_sfa}
                                                    />{" "}
                                                    <label className="custom-control-label">Send to Agent</label>
                                                </div>
                                            </fieldset>

                                        </Tab.Pane>

                                        <Tab.Pane eventKey="second">

                                            {
                                                renderForm()
                                            }

                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                                :
                                renderForm()
                        }

                    </div>

                    <div>

                        <div className="btn-save-remarks m-xl-t">
                            {

                                HELPER.isUserHasUpdateAccess({ permissionID: (props.templateType === "broker" ? ROLE_FEATURES['send_to_insurance_broker'] : ROLE_FEATURES['insurance_policy_shared']), accessType: 'edit', leadDetails: insuranceState.leadDetail, pageSlug: (props.templateType === "broker" ? "sent_to_insurance_broker" : "policy_shared"), selectedTab })

                                    ?
                                    <button type="button" className="btn-primary" onClick={submitHandler}>Save & Next</button>
                                    :
                                    <button type="button" className="btn-primary" disabled>Save & Next</button>

                            }
                            {insuranceState?.leadDetail?.leadId && <LeadRemarksButton leadId={insuranceState.leadDetail.hashId}  policyShareModal = {policyShareModal}/>}

                        </div>
                    </div>
                </div>

                <div className="view-timeline-popup image-timeline-popup fe-comment-popup">
                    <Modal show={showPaymentConfirmation} handleClose={() => { setShowPaymentConfirmation(false); setPaymentConfirmationData({ is_paid: "", paid_by: "" }) }} >
                        <div className="modal-header">
                            <h2>Insurance Premium Amount</h2>
                        </div>
                        <div className="modal-body" >

                            {
                                // insuranceState?.leadDetail?.application_type_id == APP_TYPE_AGENCY
                                //     ?
                                //     <>
                                //         <ReactSelect options={CONFIRMATION_POPUP_VALUE} handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} placeholder={"Paid By Customer?"} fieldKey="is_paid" fieldSetClassName={"col-md-6"} value={CONFIRMATION_POPUP_VALUE.find((el: any) => el.value === paymentConfirmationData?.is_paid) || null} />


                                //         <ReactSelect options={showPaymentProofOptions} handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} placeholder={"To"} fieldKey="paid_by" fieldSetClassName={"col-md-6"} value={showPaymentProofOptions.find((elm: any) => elm.value === paymentConfirmationData?.paid_by) || null} />
                                //     </>
                                //     :
                                <>
                                    {/* <ReactSelect options={CONFIRMATION_POPUP_VALUE} handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} placeholder={"Paid By Customer?"} fieldKey="is_paid" fieldSetClassName={"col-md-6"} value={CONFIRMATION_POPUP_VALUE.find((el: any) => el.value === paymentConfirmationData?.is_paid) || null} />


                                        <ReactSelect options={showPaymentProofOptions} handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} placeholder={"To"} fieldKey="paid_by" fieldSetClassName={"col-md-6"} value={showPaymentProofOptions.find((elm: any) => elm.value === paymentConfirmationData?.paid_by) || null} /> */}
                                </>
                            }

                            <ReactSelect
                                options={CONFIRMATION_POPUP_VALUE}
                                handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)}
                                placeholder={(+insuranceState?.leadDetail?.application_type_id === +APP_TYPE_AGENCY) ? "Paid From Agency?" : "Paid By Customer?"}
                                fieldKey="is_paid"
                                fieldSetClassName={"col-md-6"}
                                value={CONFIRMATION_POPUP_VALUE.find((el: any) => el.value === paymentConfirmationData?.is_paid) || null}
                                errorField={errField.is_paid}
                            />


                            <ReactSelect
                                options={showPaymentProofOptions}
                                handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)}
                                placeholder={"To"}
                                fieldKey="paid_by"
                                fieldSetClassName={"col-md-6"}
                                value={showPaymentProofOptions.find((elm: any) => elm.value === paymentConfirmationData?.paid_by) || null}
                                errorField={errField.paid_by}
                            />


                            <button className="btn-primary m-xl-t" onClick={paymentConfirnationHandler}>Submit</button>
                        </div>
                    </Modal>
                </div>
            </div >
        </div>

    );
};


export default ShareDetailsForm;
