import { useEffect } from "react";

const Modal = (params: any) => {
    let { handleClose, show, children, hideClose } = params;
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    useEffect(()=>{

        if(show) document.body.classList.add("overflow-hidden");

        return ()=>{
            document.body.classList.remove("overflow-hidden");

        }
    }, [show])
    return (
        <div className={showHideClassName}>
            <section className='modal-main'>
                {children}
                <button
                    onClick={handleClose}
                    className={`close_icn ${hideClose ? "hide" : ""}`}
                >
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};

export default Modal;