import { updateMasterRates, fetchTraRates } from '../../../store/slices/common';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as HELPER from "../../../config/helper";
import { ROLE_FEATURES,APP_AGENCY, APP_TYPE_AGENCY, APP_TYPE_STANDALONE } from '@src/config/constant';
import ReactSelect from '@common/ReactSelect';
import DatePicker from "react-multi-date-picker";
import CurrencyInputField from "@src/common/CurrencyInputField";

type InputFieldType = {
    apptype: { label: string, value: number } | null,
    partner: { label: string, value: number } | null,
    vehicle_usage: { label: string, value: number } | null,
    vehicle_type: { label: string, value: number } | null
}


export default function TraRate(props: any): ReturnType<React.FC> {
    let { callfor, appTypeList, partnerList, userId, handleLoader } = props
   
    let [inputField, setInputFields] = useState<InputFieldType>({ apptype: null, partner: null, vehicle_usage: null, vehicle_type: null})
    let [traRange, setTraRange] = useState<any>([{min_year:'',max_year:'',premium_amount:''}])
    let [deleted_id, setDeleted_id] = useState<any>([])
    let [filteredPartnerList, setFilteredPartnerList] = useState<[]>()
    let [errors, setErrors] = useState<any>({})
    let [vehicleType, setVehicleType] = useState<Array<any>>([])
    let [vehicleUsage, setVehicleUsage] = useState<Array<any>>([])
    let [partnerTRAData, setPartnerTRAData] = useState<any>([])

    let commonState = useSelector((state: any) => state.common)

    let isRateEditable = HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['update_module'], accessType: 'edit' });
    

    /**
     * function to handle change in inputs
     * @param name 
     * @param value 
     */
    const handleChange = (name: string, value: any) => {
        let updatedFields = {...inputField}

        if (name === 'apptype') {
            if (value) {
                if (filteredPartnerList?.length) {
                    let updatedList: any = partnerList && partnerList.filter((e: any) => e.application_type_id.includes(value.value))
                    setFilteredPartnerList(updatedList)
                    autoFillTraData({ apptype: value, partner: inputField.partner, vehicle_type: inputField.vehicle_type })

                }
            }
        }
        if (name === 'partner') {
            if (value) {
                autoFillTraData({ apptype: inputField.apptype, partner: value, vehicle_type: inputField.vehicle_type })
            }
        }

        //clear vehicle_type on vehicle usage selection
        if(name === 'vehicle_usage' && value?.value) {
            let vehicleType = commonState?.common?.vehicle_type?.filter((ele: any) => ele.vehicle_usage === value?.value)
            updatedFields['vehicle_type'] = null
            setVehicleType(vehicleType || [])
            setTraRange([])
        }

        //auto fill tra's when vehicle type is changed
        if (name === 'vehicle_type' && value.value) {
            let traRange = partnerTRAData?.filter((e: any) => e.vehicle_type_id === value.value)
            setTraRange(traRange)
            setErrors({})
        }

        setInputFields((prev: any) => {
            return {
                ...updatedFields,
                [name]: value
            }
        })
    
    }

    const handleChangeDateType = (selectedOption: any, key: string,index:number) => {    
        let updatedTraRange: any = traRange;
        if (key === 'min_max') {
            if (selectedOption.length === 2) {
                updatedTraRange[index]["min_year"] = selectedOption[0] ? selectedOption[0].format("YYYY") : '';
                updatedTraRange[index]["max_year"] = selectedOption[1] ? selectedOption[1].format("YYYY") : '';

                setErrors((prev: any) => ({
                    ...prev,
                    [`traRange[${index}].${key}`]:''
                }));
            } else {
                updatedTraRange[index]["min_year"] = selectedOption[0]?.format("YYYY");
                updatedTraRange[index]["max_year"] = ''; // Reset end date
            } 
        }
        if (key === 'premium_amount') {
            updatedTraRange[index]["premium_amount"] =(selectedOption?.target?.value)? HELPER.removeCurrencyFormatting(selectedOption?.target?.value):'';

            if(selectedOption?.target?.value){
                setErrors((prev: any) => ({
                    ...prev,
                    [`traRange[${index}].${key}`]:''
                }));
            }
            
        }
        
        setTraRange(updatedTraRange);
    }

    const addTraRange = () => {
        if (traRange?.length < 5) {
            let traRangeData:any=traRange;
            let traobj={min_year:'',max_year:'',premium_amount:''};
            traRangeData.push(traobj);
            if(traRangeData.length){
                setTraRange([...traRangeData]);
            }
        }
        else{
            toast.error('Cannot add more than 5')
        }
    };

    const showDeletePopUp = async (index:number) => {
        let traRangeData:any=traRange;
        if(traRangeData.length){
            let deletedtra=traRangeData[index];
            if(deletedtra && deletedtra.id){
                setDeleted_id([...deleted_id,deletedtra.id])
            }
            traRangeData.splice(index, 1);
            setTraRange([...traRangeData]);
        }
    }
    

    const checkValidation = (): boolean => {
        let error = false
        let errorFields: any = {}
        setErrors({})
        // Loop over tra range array for validation
        traRange.forEach((traData: any, index: number) => {
            if(traData.min_year==='' || traData.max_year==='' || traData.min_year===undefined || traData.max_year===undefined){
                setErrors((prev: any) => ({
                    ...prev,
                    [`traRange[${index}].min_max`]: true
                }));
                error = true;
            }
            if(traData.premium_amount==='' || traData.premium_amount===undefined){
                setErrors((prev: any) => ({
                    ...prev,
                    [`traRange[${index}].premium_amount`]: true
                }));
                error = true; 
            }
        });

        ['vehicle_type', 'vehicle_usage'].forEach((ele: string) => {
            if (!inputField[ele as keyof typeof inputField]?.value) {
                errorFields[ele] = true
                error = true
            }
        })

        if(Object.keys(errorFields).length) setErrors((prev: any) => ({...prev, ...errorFields}))

        return error
    }

    const checkTraRangeValidation = (): boolean => {
        let error = false;
        if(traRange.length){
            let years = traRange.map((obj:any) => ({start:Number(obj.min_year),end:Number(obj.max_year)}));
            years.sort((a:any, b:any) => a["start"] - b["start"]);

        // Check for conflicts
            for (let i = 0; i < years.length - 1; i++) {
                if (years[i].end >= years[i + 1].start) {
                    error = true;
                    break;
                }
            }
        }
        return error
    }

    /**
     * executes on Save button click
     */
    const handleSubmit = async () => {
        let isErrorExist = checkValidation()

        if (!isErrorExist) {
            let ischeckTraData=checkTraRangeValidation();
            if (!ischeckTraData) {
                handleLoader(true)
                let key = callfor;
                let apiParams: any = { user_id: userId }
                apiParams[key] = {
                    application_type_id:inputField.apptype?.value,
                    partner_id:inputField.partner?.value,
                    vehicle_type: inputField.vehicle_type?.value
                }
                
                if(deleted_id.length){
                    apiParams[key].delete_id=deleted_id;
                }
                if(traRange.length){
                    apiParams[key].range_arr=traRange//.map((ele: any) => );
                }

                if(!traRange?.length && deleted_id?.length) {
                    apiParams[key].range_arr = partnerTRAData?.filter((ele: any) => deleted_id.includes(ele.id))
                }

                let updateTRARate: any = await updateMasterRates(apiParams);
    
                if (updateTRARate && updateTRARate.status && updateTRARate.status === 200) {
                    toast.success(`TRA Rate Updated Successfully`)
                    autoFillTraData({ apptype: {value: inputField.apptype?.value}, partner: inputField.partner, vehicle_type: inputField['vehicle_type'] }) //call api to update ids of newly added entries

                }
                handleLoader(false)
            }else{
                toast.error(`Please select valid range`);
            }

           
        }

    }

    /**
    * function for displaying prefilled rates
    * @param apptype 
    */
    const autoFillTraData = async (params: any) => {
        let application_type_id = params.apptype?.value
        let partner_id = params.partner?.value;
        let vehicle_type = params.vehicle_type?.value

        handleLoader(true)
        let fetchTraRate: any = await fetchTraRates({ application_type_id: application_type_id, partner_id: partner_id });
        if (fetchTraRate && fetchTraRate.status && fetchTraRate.status === 200) {
            let data: any = fetchTraRate.data;
            if (data["traDataArr"].length) {
                let traDataRange: any = data["traDataArr"];
                setPartnerTRAData(traDataRange)
                traDataRange = traDataRange.filter((data: any) => data.vehicle_type_id === vehicle_type)

                setTraRange([...traDataRange]);
                setErrors({})
            } else {
                let traDataRange: any = [{ min_year: '', max_year: '', premium_amount: '' }];
                setTraRange([...traDataRange]);
            }
        }
        handleLoader(false)
    }

    useEffect(() => {
        if (appTypeList && partnerList) {
            let preSelectedPartner: any = null;
            let updatedPartnerList:any = partnerList;
            let { vehicle_usage, vehicle_type } = commonState?.common || {}

            let preSelectedApptype = (appTypeList && appTypeList.find((el:any)=>(el.value===APP_AGENCY))) || {}
            let preSelectedVehicleUsage = vehicle_usage?.[0]
            let preSelectedVehicleType = vehicle_type?.filter((ele: any) => ele.vehicle_usage === preSelectedVehicleUsage?.value)
           
            if (updatedPartnerList.length && preSelectedApptype) {
                updatedPartnerList = updatedPartnerList.filter((e: any) => e.application_type_id.includes(preSelectedApptype.value))
            }
          
            if (updatedPartnerList.length > 0) {
                preSelectedPartner = updatedPartnerList[0];
            }

            let inputObj = {
                apptype: preSelectedApptype, partner: preSelectedPartner,
                vehicle_usage: preSelectedVehicleUsage, vehicle_type: preSelectedVehicleType?.[0]
            }
            setFilteredPartnerList(updatedPartnerList)
            setVehicleUsage(vehicle_usage || [])
            setVehicleType(preSelectedVehicleType || [])
            setInputFields(inputObj);
            autoFillTraData(inputObj);

        }
        // eslint-disable-next-line
    }, [appTypeList,partnerList])
   
    return (
        <div className="calculator-form">

            <div className="row">
                <ReactSelect options={appTypeList}
                    value={inputField && inputField.apptype}
                    handleChange={(e: any, fieldKey: string) => handleChange(fieldKey, e)}
                    placeholder="Application Type*" fieldKey="apptype" key="apptype"
                    errorField={errors?.apptype ? "This field is required" : ""}
                    fieldSetClassName={"col-md-6"}
                />

                <ReactSelect options={filteredPartnerList}
                    value={inputField && inputField.partner}
                    handleChange={(e: any, fieldKey: string) => handleChange(fieldKey, e)}
                    placeholder="Insurance Partner Name*" fieldKey="partner" key="partner"
                    errorField={errors?.insurance_type ? "This field is required" : ""}
                    fieldSetClassName={"col-md-6"}
                />
            </div>
            <div className="row">
                <ReactSelect options={vehicleUsage}
                    value={inputField && inputField.vehicle_usage}
                    handleChange={(e: any, fieldKey: string) => handleChange(fieldKey, e)}
                    placeholder="Vehicle Usage*" fieldKey="vehicle_usage" key="vehicle_usage"
                    errorField={errors?.vehicle_usage ? "This field is required" : ""}
                    fieldSetClassName={"col-md-6"}
                />

                <ReactSelect options={vehicleType}
                    value={inputField && inputField.vehicle_type}
                    handleChange={(e: any, fieldKey: string) => handleChange(fieldKey, e)}
                    placeholder="Vehicle Type*" fieldKey="vehicle_type" key="vehicle_type"
                    errorField={errors?.vehicle_type ? "This field is required" : ""}
                    fieldSetClassName={"col-md-6"}
                />
            </div>  
           
            {
                traRange?.map((traobj:any,index:any) => (
                    
                    <div className="row" key={index} id={index}>
                        <fieldset className='multi-datepicker material tra-datepicker col-md-6'>
                            <div className="datepicker">
                                <DatePicker
                                    value={(traobj.min_year && traobj.max_year)?[new Date(`${traobj.min_year}-01-01`),new Date(`${traobj.max_year}-01-01`)]:undefined}
                                    onChange={(e: any) => handleChangeDateType(e,'min_max',index)}
                                    placeholder="Year Range"
                                    range
                                    onlyYearPicker
                                    disabled={props.disable}
                                    calendarPosition="bottom-left"
                                    fixMainPosition

                                />
                                </div>
                                {
                                    (traRange[index].min_year || traRange[index].max_year)
                                    ?
                                    <label title="Year Range" data-label="Year Range" className={`form-label datepick-heading`}></label>
                                    : undefined
                                }
                                {
                                errors && errors[`traRange[${index}].min_max`] ?
                                <span className="error-txt tra-error">{"Please select year range"}</span>
                                : null
                        }
                        </fieldset>
                         
                        <fieldset className='col-md-5'>
                        <div className="material">
                        <CurrencyInputField
                            inputProps={{
                            id: "premium_amount",
                            type: "text",
                            name: "premium_amount",
                            placeholder: " ",
                            value: traobj?.premium_amount || "",
                            className: "form-input",
                            disabled: false
                            }}
                            onChange={(e: any) => handleChangeDateType(e,'premium_amount',index)}
                        />
                        <label data-label="Premium Amount" className="form-label"></label>
                        {
                            errors && errors[`traRange[${index}].premium_amount`] ?
                                <span className="error-txt tra-error">{"Please enter premium amount"}</span>
                                : null
                        }

                        </div>
                        </fieldset>
                        {
                          traRange.length > 0  && <div className='cl-md-2 tra-delete' ><i className="ic-cancel template-delete-icon pointer" onClick={() => showDeletePopUp(index)}></i></div>
                        }
                        
                    </div>
                )) 
            }  
            <div className="row">
                {
                    traRange?.length < 5 &&
                        <div className="col-md-12">
                            <button className="btn-line" onClick={addTraRange}>
                            Add Range
                            </button>
                        </div>
                }
                {
                    isRateEditable ?
                        <div className="col-md-12 p-lg-t">
                            <button className="btn-primary" onClick={() => handleSubmit()}>Update</button>
                        </div>
                    : ''
                }

</div>
        </div>
    )
}