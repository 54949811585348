import React, { useState, useEffect } from 'react'
import InsuranceService from 'services/InsuranceService';
import FullPageLoader from '@src/common/FullPageLoader';
import { APP_TYPE_RENEWAL, DOCUMENT_CATEGORY, DOC_UPLOAD_VALIDATION } from '@src/config/constant';
import { addDocument } from '@src/store/slices/common';
import { toast } from 'react-toastify';
import * as HELPER from '../../../config/helper';
import secureStorage from '@src/config/encrypt';
import { useNavigate } from 'react-router-dom';
import { updateLeadDetailsInStore } from '@src/store/slices/insurance';
import { useSelector, useDispatch } from 'react-redux';


interface MainProps {
    leadId: any
    onBackfunction: any
    finalData: any,
    apptype: any
}
const DocumentForm = (props: MainProps) => {
    const [docList, setDocList] = useState<Array<any>>([])
    let [inputField, setInputField] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(false)
    const [errField, setErrField] = useState({})

    let authUserInfo = secureStorage.getItem('authUserInfo');
    let userId = authUserInfo && authUserInfo.user_data && authUserInfo.user_data.id
    const insuranceState = useSelector((state: any) => state.insurance)
    const dispatch = useDispatch()

    let navigate = useNavigate()

    useEffect(() => {
        fetchDocumentList(props.leadId)
       // eslint-disable-next-line
    }, [props.leadId])

    const fetchDocumentList = async (leadId: any) => {
      
        let params: any = {
            leadId: leadId
        }
                
        setLoading(true)
        let result = await InsuranceService.getDocumentList(params)
        setLoading(false)
        if (result?.data?.data) {
            setDocList(result.data.data)
            //prefill renewal documents
            if(props.apptype === APP_TYPE_RENEWAL) handleFilesForRenewalApplication()
        }
    }
    const handleFileChange = async (e: any, fieldItem: any) => {
        let selectedFile = e.target.files;
        let validateFile = await HELPER.ValidateFileInput(selectedFile)

        if (validateFile) {
            e.target.value = null
            return
        }
        const maxSelectedFiles = inputField[fieldItem.value] ? Array.from(inputField[fieldItem.value]).concat(Array.from(selectedFile)) : selectedFile;

        // console.log(fieldItem)
        if (fieldItem.max_upload)
            if (maxSelectedFiles.length > fieldItem.max_upload) {
                setErrField({ ...errField, [fieldItem.value]: 'Maximum 5 Documents are allowed' });
                e.target.value = null
                // setInputField({ ...inputField, [fieldItem.value]: '' });


            } else {


                setInputField({ ...inputField, [fieldItem.value]: maxSelectedFiles });
                // setInputField({ ...inputField, [fieldItem.value]: selectedFile });
                setErrField({ ...errField, [fieldItem.value]: '' })
            }
    }

    const submitDocument = async () => {
        //check if optional mandatory documents uploaded
        setLoading(true)
        let optionalMandatoryDocs = docList?.filter((doc: any) => doc.conditional_mandatory.length)?.map((e: any) => e.value)
        let isOptionalDocUploaded = inputField ? Object.keys(inputField).filter((fl: any) => optionalMandatoryDocs?.includes(+fl)) : ''
        
        if (optionalMandatoryDocs && optionalMandatoryDocs.length && (!isOptionalDocUploaded || !isOptionalDocUploaded.length)) {
            let msg = docList?.filter((doc: any) => doc.conditional_mandatory.length)?.map((e: any) => e.label)?.join(' Or ')
            toast.error(`Please upload atleast 1 document of ${msg}`)
            setLoading(false)
            return
        }

        if (validateErr(docList)) {
            for(let updatdDocType of Object.keys(inputField)){
                if(props?.finalData?.apptype === 3 ){
                    if(+updatdDocType === 62 && +props?.finalData?.paid_by_customer === 2){
                        delete inputField[updatdDocType]
                    }
                }
            }

            if (Object.keys(inputField).length) {
                let docArray = []
                let formdata = new FormData()
                //fetch lead_id from params
                let queryParams = new URLSearchParams(window.location.search)
                let leadId: any = queryParams.get('lead_id')

                formdata.append('lead_id', leadId)
                formdata.append('user_id', userId)
                formdata.append('isNewCreated', "1")
                formdata.append('apptype', props.apptype)

                for (let key in inputField) {
                    let docType = DOCUMENT_CATEGORY['firstSubmit']['doc_type_id']

                    let files = inputField[key]
                    for (let file of files) {
                        let docObj = {
                            document_id: key,
                            type: 'add',
                            document_type_id: +docType
                        }
                        docArray.push(docObj)
                        formdata.append('files', file)
                    }
                }

                formdata.append('doc_path', JSON.stringify(docArray))
                //api call
                let uploadDocument: any = await addDocument(formdata)
                setLoading(false)

                //if upload successfull
                if (uploadDocument && uploadDocument.status === 200) {
                    // let details = { doc_status_id: DOC_STATUS['2'] }
                    dispatch(updateLeadDetailsInStore({}))
                    navigate(`/details/personal-details/${leadId}`)
                }
            }
            setLoading(false)
        }
        else{
            toast.error("Please select all mandatory documents")
            setLoading(false)
        }
    }

    const validateFileInput = (files: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                let errorFlag = false
                let allowedExtensions = ['data:application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'data:application/pdf', 'data:application/msword', 'data:text/csv']

                for (let file of files) {
                    const base64PreviewImg = await HELPER.toBase64(file)
                    const filetype: any = await HELPER.detectMimeType(base64PreviewImg, { allowedExtensions })
                    const fileSize = file.size

                    if (!filetype) {
                        toast.error("File format should be jpg, jpeg, png, pdf, doc, docx, csv")
                        errorFlag = true
                        break
                    } else if (fileSize < DOC_UPLOAD_VALIDATION['minSize'] || fileSize > DOC_UPLOAD_VALIDATION['maxSize']) {
                        toast.error("file size should be between 25kb to 5mb")
                        errorFlag = true
                        break
                    }
                }

                return resolve(errorFlag)
            } catch (error) {
                return resolve(true)
            }
        })
    }

    const validateErr = (list: Array<any>) => {
        let errorOb: any = {}
        let isError = true;

        for(let ob of list){
            if (ob.child.length) {
                for(let childdoc of ob.child){
                    if(childdoc.is_required && !inputField[childdoc.value]){
                        isError = false;
                        errorOb[childdoc.value] = `${childdoc.label} Field is required`;   
                    }
                }
            }          
            else if(ob.is_required && !inputField[ob.value]) {
                isError = false;
                errorOb[ob.value] = `${ob.label} Field is required`;
            }
        }

        setErrField((prev)=> ({...prev, ...errorOb}));
        return isError;
    }

    const updateSelectedFiles = (files: any) => {

        setInputField({ ...files })

    }

    const handleFilesForRenewalApplication = async () => {
        setLoading(true)
        let documents = insuranceState?.leadDetail?.documents?.filter((doc: any) => doc.doc_type_id === DOCUMENT_CATEGORY['firstSubmit']['doc_type_id'])
        if (documents?.length) {
            let updatedDocs: any = {}

            //get url and file extension of each file
            let prevDocs = documents.map((doc: any) => {
                let file = doc?.path?.split('?')?.[0]
                file = file?.split('/')
                file = file?.[file?.length - 1] || ''
                return {
                    url: doc.path,
                    fileName: file,
                    doc_id: doc.doc_id
                }
            })

            //fetch all images blob
            if(prevDocs?.length) {
                prevDocs = await HELPER.convertImgsToFiles(prevDocs)
            }

            for (let doc of prevDocs) {
                let newFile: any = doc.file

                if(!newFile) continue

                if (updatedDocs[doc.doc_id]) {
                    const dataTransfer = new DataTransfer();
                    const oldFiles = updatedDocs[doc.doc_id]
                    // Copy existing files into the new DataTransfer object
                    for (let i = 0; i < oldFiles.length; i++) {
                        dataTransfer.items.add(oldFiles[i]);
                    }
                    
                    dataTransfer.items.add(newFile)
                    updatedDocs[doc.doc_id] = dataTransfer.files || []
                } else {
                    let updatedDocList: any = new DataTransfer();
                    updatedDocList.items.add(newFile)
                    updatedDocs[doc.doc_id] = updatedDocList?.files || []
                }

            }
            setInputField((prev: any) => ({ ...prev, ...updatedDocs }))
        }
        setLoading(false)
    }

    return (
        <React.Fragment>
            <FullPageLoader show={loading} />
            <div className='loan-filed-sec'>
                <div className="row vehicle-details">
                    <div className="loan-btn-heading">
                        <h3>Documents</h3>
                        <div className="">
                            <button className="btn-line m-sm-r" onClick={props.onBackfunction}>Back </button>
                            <button className="btn-primary " onClick={submitDocument}>Submit</button> 
                        </div>
                    </div>

                    <div className='col-md-12 insurance-doc-upload-outer insurance-doc-upload-main'>
                        {
                            <DocumentView docList={docList || []} errField={errField} handleFileChange={handleFileChange} filesData={inputField} updateSelectedFiles={updateSelectedFiles} />
                        }
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}

export default DocumentForm;

export const DocumentView = (props: any) => {

    const [loading, setLoading] = useState(false);

    let response = props?.docList?.map((item: any, index: number) => {
        if (item?.child?.length) {
            return <DocumentView docList={item.child} handleFileChange={props.handleFileChange} parent={item.label} errField={props.errField} key={index} updateSelectedFiles={props.updateSelectedFiles} filesData={props.filesData} for={props?.for} />
        }
        let parentName = (props.parent) ? props.parent : ''

        const removeSelectedFile = (item: any, fileIdx: any) => {
            setLoading(true);
            let selectedFiles = props.filesData;
            //CREATE NORMAL ARRAY & REMOVE DELETED FILE
            const newFileList: any = Array.from(selectedFiles[item.value]).filter((file: any, key: number) => { return key !== +fileIdx; });

            //CREATE FILE ARRAY
            let updatedDocList: any = new DataTransfer();
            for (let doc of newFileList) {
                let newFile: any = new File([doc], doc.name, { type: doc.type })
                updatedDocList.items.add(newFile);

            }
            selectedFiles[item.value] = updatedDocList.files.length ? updatedDocList.files : undefined;
            if(!selectedFiles[item.value]) delete selectedFiles[item.value]

            //UPDATE PARENT STATE
            props.updateSelectedFiles(selectedFiles);

            //UPDATE INPUT FILE
            const fileBox = document.getElementById(`upload-image-input-${item.value}`) as any;
            fileBox.files = updatedDocList.files;

            setTimeout(()=>{

                setLoading(false);
            },500)

        }
        return (
            <div className={`insurance-doc-upload insurance-image-uplaod-view ${props.errField[item.value] ? 'insurance-image-uplaod-view-err' : '' } ${(index === 0 && item.parent_id === item.value) ? `p-t-70` : ''}`} key={index}>
                <FullPageLoader show={loading} />

                {
                    !(item?.src?.length)
                        ?
                        <>
                            {
                                props?.for === 'agency'
                                    ?
                                    <span>{item.label + ` ${item.label.includes('Other') ? "" : 'Documents'}`}</span>
                                    :
                                    undefined
                            }
                            <div className="document-image-container ">
                                <div role="presentation" className="add-new-image upload-input-bx">
                                    {
                                        <input type="file" className="upload-image-input" id={`upload-image-input-${item.value}`} name="documents[]" onChange={(e: any) => props.handleFileChange(e, item)} multiple />
                                    }
                                    <i className="ic-add"> </i>
                                    <span>Upload</span>
                                </div>
                                <p className='doc-tag-name'>{(item.parent_id !== DOCUMENT_CATEGORY['firstSubmit']['childs']['credit_advice_doc'].value ? parentName : '') + ' ' + item.label + (item.is_required ? '*' : (item.conditional_mandatory && item.conditional_mandatory.length) ? "**" : '')}</p>
                                {
                                    (props.errField[item.value]) ?
                                        <span className="error-txt" style={{ position: 'relative' }}>{props.errField[item.value]}</span> : null
                                }
                            </div>
                        </>
                        : null
                }
                {
                    item?.src?.length
                        ?
                        item.src.map((img: any, idx: number) => {
                            let splitName = img.split('?')
                            let splitPathName = splitName?.[0]?.split('.')
                            let fileType = (splitPathName[splitPathName.length - 1])?.toLowerCase()
                            fileType = fileType === 'csv' ? 'excel' : (fileType === 'doc' || fileType === 'docx') ? fileType = 'word' : fileType

                            return (
                                <div className="document-image-container" key={idx}>
                                    <div role="presentation" className="add-new-image">
                                        {
                                            fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' ?
                                                <img src={img} alt={"document_image_" + idx} />
                                                : <a href={img} target="_blank" rel="noreferrer"><i className={`ic-${fileType}-file icons-normal`}></i></a>
                                        }
                                    </div>
                                    <p className='doc-tag-name'>{((item.parent_id !== DOCUMENT_CATEGORY['firstSubmit']['childs']['credit_advice_doc'].value) ? parentName : '') + ' ' + item.label}</p>

                                </div>
                            )
                        })
                        :

                        props.filesData && (props.filesData[item.value])
                            ?
                            Object.keys(props.filesData[item.value]).map((file: any, key: number) => {
                                let newfile = props.filesData[item.value][file]
                                let fileType = newfile?.name?.split('.')
                                fileType = fileType && fileType[fileType?.length - 1]
                                fileType = fileType && fileType.toLowerCase()
                                fileType = ['csv', 'xls', 'xlsx'].includes(fileType) ? 'excel' : (fileType === 'doc' || fileType === 'docx') ? fileType = 'word' : fileType

                                return (
                                    <div className="document-image-container" key={key}>
                                        <div role="presentation" className="add-new-image">
                                            <span className='doc-close-icn' onClick={() => removeSelectedFile(item, file)}><i className='ic-clearclose'></i></span>
                                            {fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' ? <img src={URL.createObjectURL(props.filesData[item.value][file])} alt="" /> : <a href={URL.createObjectURL(props.filesData[item.value][file])} target="_blank" rel="noreferrer"><i className={`ic-${fileType}-file icons-normal`}></i></a>}


                                        </div>
                                        <p className='doc-tag-name'>{parentName + ' ' + item.label}</p>

                                    </div>
                                )
                            }
                            )
                            :
                            ""


                }


            </div>)
    })
    return response;
}


export const DocumentViewForAgency = (props: any) => {

    const [loading, setLoading] = useState(false);

    let response = props?.docList && props?.docList?.length > 0 && props?.docList?.map((item: any, index: number) => {
        if (item?.child?.length) {
            return <DocumentViewForAgency docList={item.child} handleFileChange={props.handleFileChange} parent={item.label} errField={props.errField} key={index} updateSelectedFiles={props.updateSelectedFiles} filesData={props.filesData} for={props?.for} />
        }
        let parentName = (props.parent) ? props.parent : ''

        const removeSelectedFile = (item: any, fileIdx: any) => {
            setLoading(true);
            let selectedFiles = props.filesData;
            //CREATE NORMAL ARRAY & REMOVE DELETED FILE
            const newFileList: any = Array.from(selectedFiles[item.value]).filter((file: any, key: number) => { return key !== +fileIdx; });

            //CREATE FILE ARRAY
            let updatedDocList: any = new DataTransfer();
            for (let doc of newFileList) {
                let newFile: any = new File([doc], doc.name, { type: doc.type })
                updatedDocList.items.add(newFile);

            }
            selectedFiles[item.value] = updatedDocList.files.length ? updatedDocList.files : undefined;
            if(!selectedFiles[item.value]) delete selectedFiles[item.value]

            //UPDATE PARENT STATE
            props.updateSelectedFiles(selectedFiles);

            //UPDATE INPUT FILE
            const fileBox = document.getElementById(`upload-image-input-${item.value}`) as any;
            fileBox.files = updatedDocList.files;

            //for ORCR image recognition API call
            if(item.value === DOCUMENT_CATEGORY['firstSubmit']['childs']['orcr'] && props.for === 'agency') {
                props.setOrcrApiCall(false)
            }

            setTimeout(()=>{

                setLoading(false);
            },500)

        }
        return (
            <div className={""} key={index}>
                <FullPageLoader show={loading} />
                <div className='row'>
                {
                    !(item?.src?.length)
                        ?
                        <>
                            <div className='col-md-12'>
                            <div className='upload-heading-text '> 
                            {
                                props?.for === 'agency'
                                    ?
                                    
                                        <h3>{item.label + ` ${item.label.includes('Other') ? "" : 'Documents'}`}</h3>
                                  
                                    
                                    :
                                    undefined
                            }
                              </div>
                            </div>
                            <div className="col-md-2 p-lg-t">
                                <div role="presentation" className="add-document-proof">
                                    {
                                        <input type="file" id={`upload-image-input-${item.value}`} name="documents[]" onChange={(e: any) => props.handleFileChange(e, item)} multiple title={""} />
                                    }
                                    <i className="ic-add"> </i>
                                    <span>Upload</span>
                                </div>
                                <p className='doc-tag-name'>{(item.parent_id !== DOCUMENT_CATEGORY['firstSubmit']['childs']['credit_advice_doc'].value ? parentName : '') + ' ' + item.label + (item.is_required ? '*' : (item.conditional_mandatory && item.conditional_mandatory.length) ? "**" : '')}</p>
                                {
                                    (props.errField[item.value]) ?
                                        <span className="error-txt" style={{ position: 'relative' }}>{props.errField[item.value]}</span> : null
                                }
                            </div>

                       
                        </>
                        : null
                }
                {
                    item?.src?.length
                        ?
                        item.src.map((img: any, idx: number) => {
                            let splitName = img.split('?')
                            let splitPathName = splitName?.[0]?.split('.')
                            let fileType = (splitPathName[splitPathName.length - 1])?.toLowerCase()
                            fileType = ['csv', 'xls', 'xlsx'].includes(fileType) ? 'excel' : (fileType === 'doc' || fileType === 'docx') ? fileType = 'word' : fileType

                            return (
                                <div className="document-image-container" key={idx}>
                                    <div role="presentation" className="add-new-image">
                                        {
                                            fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' ?
                                                <img src={img} alt={"document_image_" + idx} />
                                                : <a href={img} target="_blank" rel="noreferrer"><i className={`ic-${fileType}-file icons-normal`}></i></a>
                                        }
                                    </div>
                                    <p className='doc-tag-name'>{((item.parent_id !== DOCUMENT_CATEGORY['firstSubmit']['childs']['credit_advice_doc'].value) ? parentName : '') + ' ' + item.label}</p>

                                </div>
                            )
                        })
                        :

                        props.filesData && (props.filesData[item.value])
                            ?
                            Object.keys(props.filesData[item.value]).map((file: any, key: number) => {
                                let newfile = props.filesData[item.value][file]
                                let fileType = newfile?.name?.split('.')
                                fileType = fileType && fileType[fileType?.length - 1]
                                fileType = fileType && fileType.toLowerCase()
                                fileType = ['csv', 'xls', 'xlsx'].includes(fileType) ? 'excel' : (fileType === 'doc' || fileType === 'docx') ? fileType = 'word' : fileType

                                return (
                                    <div className="col-md-2 p-lg-t" key={key}>
                                        <div role="presentation" className="upload-image-proof">
                                            <span className='close-icn' onClick={() => removeSelectedFile(item, file)}>
                                                <i className='ic-clearclose'></i></span>
                                            {fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' ? <img src={URL.createObjectURL(props.filesData[item.value][file])} alt="" /> : <a href={URL.createObjectURL(props.filesData[item.value][file])} target="_blank" rel="noreferrer"><i className={`ic-${fileType}-file icons-normal`}></i></a>}
 
                                        </div>
                                        <p className='doc-tag-name'>{parentName + ' ' + item.label}</p>

                                    </div>
                                )
                            }
                            )
                            :
                            ""


                }

   </div>

            </div>)
    })
    return response;
}