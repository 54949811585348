import React from "react";  
import { FcGoogle } from "react-icons/fc";   
import {REDIRECT_URL} from '@src/config/constant';
 

function GoogleLoginComponent(props:any) {   
  const handleClick = (e:any) => { 
    e.preventDefault();
    // Perform any additional logic here if needed
    window.location.href = REDIRECT_URL + window.location.href;
  }; 
    return (
        <button type="button" className="btn-line m-md-l btn-google" onClick={handleClick}>
            <FcGoogle className=""/>
            {/* <a className="m-xs-l" href={REDIRECT_URL + window.location.href} onClick={handleClick}> */}
                <span className="m-xs-l">Sign in with Google</span>
            {/* </a>  */}
        </button> 
    );
}

export default GoogleLoginComponent;
