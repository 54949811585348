/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
import { API_URL, APIV, apikey } from '@src/config/constant';
import secureStorage from '@src/config/encrypt';
import { toast } from 'react-toastify';
import * as HELPER from "@src/config/helper";

 
const Axios = axios.create();
Axios.interceptors.response.use(
    (response) => {    
        if (response.data && response?.data?.status === 400 && response?.data?.error === "keys_expired") {
            window.location.reload()
        }

        if(response && response.headers && +response.headers.isresponseencrypted){ 
            
            response.data = HELPER.DecryptResponse(response)

        }else{
            
        }
        return response;
    },
    (error) => {

        // handle error
        if (error.response) {

            if (+error.response.status === 503) {
            } else if (+error.response.status === 401) {
                secureStorage.removeItem('authUserInfo');
                window.location.href = '/login';
            } else if (+error.response.status === 400) {

                let errorDecrypt = HELPER.DecryptResponse(error.response)
                error.response.data = errorDecrypt?.data || errorDecrypt; 

            }

        }

        return error.response;
    });

(window as any).commonErrorToast = function (error: any) {
    if (typeof error == "string") toast.error(error);
    else if (error.message) toast.error("Helllo", error.message);
}
const MasterSerive = {

    getToken() {
        return (secureStorage.getItem('authUserInfo') && secureStorage.getItem('authUserInfo')['token'])
            ? secureStorage.getItem('authUserInfo')['token']
            : ''
    },
    delete(URL: string, body: object, headers: any) {
        headers = headers || {};
        if (APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang') || 'en';
        // headers['apikey'] = apikey;
        URL = (URL[0] === '/') ? URL.substring(1) : URL;
        return Axios.delete(`${API_URL.GETWAY_API}${URL}`, { data: body, headers })
    },
    post: async(URL: string, body?: object, headers?: any) => { 
        headers = headers || {};
        if (APIV) headers['apiv'] = APIV;
        headers['Authorization'] = MasterSerive.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang') || 'en';
        
        if(URL === "/common/uploadAndReadDocument"){
            headers['apikey'] = apikey;
        }        
        headers['Isresponseencrypted'] = 1;
        
        URL = (URL[0] === '/') ? URL.substring(1) : URL;

        let encryptedData: any = (URL.indexOf("getPublicKey") === -1) ? await MasterSerive.encryptRequestData(URL, headers, body) : {body, headers}
        return Axios.post(`${API_URL.GETWAY_API}${URL}`, encryptedData?.body, { headers: encryptedData.headers })
    },
    put(URL: string, body?: object, headers?: any) {
        headers = headers || {};
        if (APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang') || 'en';
        // headers['apikey'] = apikey;
        URL = (URL[0] === '/') ? URL.substring(1) : URL;
        return Axios.put(`${API_URL.GETWAY_API}${URL}`, body, { headers })
    },
    get: async(URL: string, query?: any, headers?: any) => {
        headers = headers || {};
        if (APIV) headers['apiv'] = APIV;
        headers['Authorization'] = MasterSerive.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang') || 'en';
        // headers['apikey'] = apikey;
        URL = (URL[0] === '/') ? URL.substring(1) : URL;

        let encryptedData: any = (URL.indexOf("getPublicKey") === -1) ? await MasterSerive.encryptRequestData(URL, headers, query) : {body: query, headers}

        return Axios.get(`${API_URL.GETWAY_API}${URL}`,
            { params: encryptedData.body, headers: encryptedData.headers })
    },
    encryptRequestData: async (URL: string, headers: any, body: any) => {
        return new Promise(async (resolve)=>{

            let encryptedReqData = "";
            let encPubKey = secureStorage.getItem('encryptionPublicKey');

            if(URL.indexOf('/getPublicKey') === -1){

                // body = body && Object.keys(body).length ? JSON.stringify(body) : "";
                let {encryptedReqData, iv, symmetricKey} = HELPER.encryptAesKey({encPubKey, body, headers, URL});
                headers['isresponseencrypted'] = (typeof headers['isresponseencrypted'] !== 'undefined') ? headers['isresponseencrypted'] : 1;
                headers['encryptedIV'] = iv;
                headers['encryptedSymmetricKey'] = symmetricKey;
                body = {requestData:encryptedReqData};

                if(encryptedReqData instanceof FormData){

                        body = encryptedReqData//{requestData:encryptedReqData};
                }

                //if(encryptedReqData)

                resolve({body, headers, encryptedReqData})
            }else{
                resolve({body, headers, encryptedReqData})
            }
        })
    }
}

export default MasterSerive;
