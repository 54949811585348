import { NavLink } from "react-router-dom";

import secureStorage from "@src/config/encrypt";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

const OcrHeader = () => {
    
    const logOutUser = async () => {
        let ocrAuth = secureStorage.getItem("ocrAuth")
        if (ocrAuth) {
            secureStorage.removeItem("ocrAuth")
            toast.success('LogOut Successfully')
        }
    };

    return (
        <header>
            <div className="container-fluid" >
                <div className="header-main">
                    <div className="logo">
                        <img src={'images/ocr_logo2.png'} className="" alt="insurance-logo" />

                    </div>
                    <div className="right-panel-naviganion menupanel">


                        <div className="user-detail ">
                            <ul>

                                <li className="dropdownmenu ocr-dropdown"><NavLink to="#">
                                    <span className="userarc">
                                        {"O"}
                                    </span>
                                    <div className="username">
                                        <span>{"Oto User"}</span>
                                        <span className="user-degintion"></span>

                                    </div>
                                </NavLink>
                                    <ul className="submenu">
                                        <li><NavLink to="#" onClick={logOutUser}>Logout</NavLink> </li>
                                    </ul>
                                </li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div >
        </header>

    );
}

export default OcrHeader;