import React, { useEffect, useState } from 'react';
import { DobCalendar, ExpectedInstallmentDate, InsuranceDate } from '../../../../common/DatePicker';
import { useSelector, useDispatch } from 'react-redux';
import InputField from "../../../../common/InputField"
import { getMasterConfig } from '../../../../store/slices/common';
import ReactSelect from '@common/ReactSelect';
import { APP_TYPE_RENEWAL, APP_TYPE_STANDALONE, APP_TYPE_WITH_UCF, INS_TYPE_CTPL, PAYMENT_TERM_INSTALLMENT, APP_TYPE_AGENCY } from "@src/config/constant";
import { times } from 'lodash';
import { nthNumber, removeCurrencyFormatting } from '@src/config/helper';
import CurrencyInputField from '@src/common/CurrencyInputField';


export default function PersonalDetailsForm(props: any): ReturnType<React.FC> {

    const [minDateForAgency, setMinDateForAgency] = useState(null);

    const dispatch: any = useDispatch();
    const commonState = useSelector((state: any) => state.common);
    const insuranceState = useSelector((state: any) => state.insurance)
    const { application_type_id,bulk_payment_id } = insuranceState?.leadDetail || {}

    const [enableFutureDates, setEnableFutureDate] = useState(false);

    let paymentTermOptions = commonState?.common?.payment_term
    if (application_type_id !== APP_TYPE_AGENCY && insuranceState?.leadDetail?.insurance_type_id === INS_TYPE_CTPL && commonState?.common?.payment_term) {
        paymentTermOptions = commonState.common.payment_term.filter((e: any) => e.value !== PAYMENT_TERM_INSTALLMENT)
    }

    const checkFutureDateApplication = () => {
        let enableFutureDates = commonState?.common?.payment_method?.find((v: any) => v.label === "Post Dated Cheque")
        enableFutureDates = (enableFutureDates && (personalDetails.payment_method_id || insuranceState?.leadDetail?.payment_method_id) === enableFutureDates.value && (personalDetails.payment_term_id || insuranceState?.leadDetail?.payment_term_id) === PAYMENT_TERM_INSTALLMENT) ? true : false

        return setEnableFutureDate(enableFutureDates)
    }

    useEffect(() => {
        if (Object.keys(commonState.common).length === 0) {
            dispatch(getMasterConfig());
        }
        // eslint-disable-next-line
    }, []);

    const changeHandler = (value: any, key: string) => {
        let formFields: any = personalDetails;
        if (["dob", "to_date", "premium_amount_date"].includes(key)) {
            formFields = { ...formFields, [key]: value ? new Date(value).toISOString() : '' }
        } else if (["customer_type", "payment_method_id", "payment_term_id", "copy_of_insurance", "payment_tenure_id", "credit_term", "payment_way", "paid_from_agency", "paid_to_whom"].includes(key)) {
            formFields = { ...formFields, [key]: value.value }
            if (key === 'payment_method_id') formFields = { ...formFields, payment_term_id: null, payment_tenure_id: null, expected_installment_date: null }
            if (key === 'payment_term_id') formFields = { ...formFields, payment_tenure_id: 0 }

            if(key === "credit_term") formFields = { ...formFields, expected_credit_term: formFields?.credit_term }
        } else if ((key.includes('expected_installment') || key.includes('actual_installment')) && insuranceState.leadDetail.application_type_id !== APP_TYPE_AGENCY) {
            const keyName = key.split('-')
            const installmentNum = +keyName[1]
            const fieldKey = key.includes('expected_installment') ? 'expected_installment' : 'actual_installment'

            const isDate = ['expected_installment_date', 'actual_installment_date'].includes(keyName?.[0]) ? true : false
            const totalInstallments = commonState?.common?.payment_tenure?.find((pt: any) => personalDetails.payment_tenure_id === pt.value)?.label?.split(' ')?.[0]
            const installmentDates = JSON.parse(JSON.stringify({ ...formFields?.[fieldKey], [installmentNum]: { ...formFields?.[fieldKey]?.[installmentNum] || null } }))

            //reset all next installment dates
            if (isDate) {
                for (let i: any = installmentNum + 1; i <= totalInstallments; i++) {
                    let nextDateVal = new Date(installmentDates[i]?.date).getTime()
                    let currDateVal = new Date(value).getTime()
                    if (nextDateVal <= currDateVal) {
                        delete installmentDates[i]?.date
                    }
                }
            }
            // formFields = { ...formFields, [keyName[0]]: { ...installmentDates, [installmentNum]: value } }
            installmentDates[installmentNum][isDate ? 'date' : 'amount'] = value

            formFields = {
                ...formFields,
                [fieldKey]: {
                    ...installmentDates
                }
            }

        } else if (key.includes('expected_installment') && insuranceState.leadDetail.application_type_id === APP_TYPE_AGENCY) {
            const keyName = key.split('-')
            const installmentNum = +keyName[1]
            const isDate = keyName?.[0] === 'expected_installment_reference_no_date' ? true : false
            const totalInstallments = commonState?.common?.payment_tenure?.find((pt: any) => personalDetails.payment_tenure_id === pt.value)?.label?.split(' ')?.[0]
            const installmentDates = JSON.parse(JSON.stringify(({ ...formFields?.['expected_installment_reference_no'], [installmentNum]: { ...formFields?.['expected_installment_reference_no']?.[installmentNum] || null } })))

            //reset all next installment dates
            if (isDate) {
                for (let i: any = installmentNum + 1; i <= totalInstallments; i++) {
                    let nextDateVal = new Date(installmentDates[i]?.date).getTime()
                    let currDateVal = new Date(value).getTime()
                    if (nextDateVal <= currDateVal) {
                        delete installmentDates[i]?.date
                    }
                }
            }

            // formFields = { ...formFields, [keyName[0]]: { ...installmentDates, [installmentNum]: value } }
            if(isDate){

                installmentDates[installmentNum]['date'] = value
            }else{
                installmentDates[installmentNum]['reference_no'] = value?.target?.value || ""

            }

            formFields = {
                ...formFields,
                'expected_installment_reference_no': {
                    ...installmentDates
                }
            }

            //for enabling/disabling future dates in actual
            //instalments
            checkFutureDateApplication()

        }
         else if (key === "premium_amount") {
            formFields = { ...formFields, [key]: removeCurrencyFormatting(value?.target?.value) }
        }
        else {
            formFields = { ...formFields, [key]: value?.target?.value }
        }
        props.changeHandler(formFields, "personal-details");
    }

    //SET MINDATE FOR AGENCY 
    useEffect(() => {
        if (insuranceState?.leadDetail?.application_type_id === APP_TYPE_AGENCY) {
            let now: any = new Date();
            let oneYrBefore: any = new Date((now.getFullYear()-1) +'-'+ (now.getMonth()+1) +'-'+ now.getDate())//new Date();
            setMinDateForAgency(oneYrBefore)
        }
    }, [insuranceState?.leadDetail?.application_type_id]);

    let { personalDetails, errors, formType } = props;
    
    let paymentwayDisable=(application_type_id === APP_TYPE_AGENCY && bulk_payment_id)?true:false;

    let disable = (insuranceState?.leadDetail?.application_type_id === APP_TYPE_WITH_UCF && insuranceState?.leadDetail?.dob !== '') ? true : false;

    return (

        <div>
            {
                formType === "payment-details" ? <h2>Payment Details</h2> : <h2>Personal Information</h2>
            }

            <div className='row'>
                {
                    formType === "personal-details" &&
                    <>
                        <fieldset className="col-md-6">
                            <div className="material" title={personalDetails?.customer_name}>
                                <InputField
                                    inputProps={{
                                        id: "customer_name",
                                        name: "customer_name",
                                        className: "form-input",
                                        type: "text",
                                        value: personalDetails.customer_name || '',
                                        maxLength: '',
                                        disabled: props.disable,
                                        placeholder: " "
                                    }}
                                    onChange={(e: any) => changeHandler(e, 'customer_name')}
                                />

                                <label data-label="Customer Name*" className="form-label"></label>
                                {
                                    errors.customer_name ?
                                        <span className="error-txt">{errors.customer_name}</span>
                                        : ''
                                }
                            </div>
                        </fieldset>

                        <fieldset className="col-md-6">
                            <div className="material" title={personalDetails?.mobile}>
                                <InputField
                                    inputProps={{
                                        id: "mobile",
                                        name: "mobile",
                                        className: "form-input",
                                        type: "text",
                                        value: (personalDetails.mobile) ? personalDetails.mobile : '',
                                        maxLength: "10",
                                        disabled: (application_type_id === APP_TYPE_AGENCY) ? false : true,
                                        placeholder: " "

                                    }}
                                    onChange={(e: any) => changeHandler(e, 'mobile')}
                                />
                                <label data-label={`Customer Mob No ${application_type_id !== APP_TYPE_AGENCY ? '*' : ''}`} className="form-label"></label>
                                {
                                    errors.mobile ?
                                        <span className="error-txt">{errors.mobile}</span>
                                        : ''
                                }
                            </div>
                        </fieldset>

                        <fieldset className="col-md-6">
                            <div className="material" title={personalDetails?.alternate_mobile}>
                                <InputField
                                    inputProps={{
                                        id: "alternate_mobile",
                                        name: "alternate_mobile",
                                        className: "form-input",
                                        type: "text",
                                        value: personalDetails.alternate_mobile || '',
                                        maxLength: "10",
                                        disabled: false,
                                        placeholder: " "
                                    }}
                                    onChange={(e: any) => changeHandler(e, 'alternate_mobile')}
                                />
                                <label data-label="Customer Alt Mob No" className="form-label"></label>
                                {
                                    errors.alt_mobile ?
                                        <span className="error-txt">{errors.alt_mobile}</span>
                                        : ''
                                }
                            </div>
                        </fieldset>

                        <fieldset className="col-md-6">
                            <div className="material" title={personalDetails?.email}>
                                <InputField
                                    inputProps={{
                                        id: "email",
                                        name: "email",
                                        className: "form-input",
                                        type: "text",
                                        value: personalDetails.email || '',
                                        maxLength: "",
                                        disabled: false,
                                        placeholder: " "
                                    }}
                                    onChange={(e: any) => changeHandler(e, 'email')}
                                />
                                <label data-label={`Customer Email ID${insuranceState?.leadDetail?.application_type_id === 2 ? '*' : ''}`} className="form-label"></label>
                                {
                                    errors.email ?
                                        <span className="error-txt">{errors.email}</span>
                                        : ''
                                }
                            </div>
                        </fieldset>

                        <fieldset className="col-md-6 date-picker-detail">
                            <div className={"material animation-effect" + (personalDetails.dob ? ' active-label' : '')}>
                                <div className="datepicker">
                                    <DobCalendar
                                        onChange={(e: Date) => changeHandler(e, 'dob')}
                                        placeholder=" "
                                        selected={personalDetails?.dob || ''}
                                        dateFormat="dd MMM, yyyy"
                                        disabled={disable}
                                    />

                                    <label data-label={`Customer DOB`} className="form-label"></label>
                                    {
                                        errors.dob ?
                                            <span className="error-txt">{errors.dob}</span>
                                            : ''
                                    }
                                </div>
                            </div>
                        </fieldset>

                        {
                            application_type_id !== APP_TYPE_AGENCY
                                ?
                                <ReactSelect
                                    options={commonState?.common?.customer_type}
                                    value={commonState?.common?.customer_type?.find((v: any) => personalDetails.customer_type === v.value) || null}
                                    handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} placeholder="Customer Type"
                                    fieldKey="customer_type"
                                    fieldSetClassName="col-md-6"
                                    isDisabled={false}
                                    errorField={errors.customer_type} />
                                : null
                        }
                    </>
                }

                {/* {
                    insuranceState?.leadDetail?.application_type_id !== APP_TYPE_AGENCY &&
                    <ReactSelect
                        options={commonState?.common?.customer_type}
                        value={commonState?.common?.customer_type?.find((v: any) => personalDetails.customer_type === v.value) || null}
                        handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} placeholder="Customer Type"
                        fieldKey="customer_type"
                        fieldSetClassName="col-md-6"
                        isDisabled={false}
                        errorField={errors.customer_type} />
                } */}



                {
                    ((+insuranceState?.leadDetail?.paid_by_customer === 1 && formType === "payment-details") || (+insuranceState?.leadDetail?.paid_by_customer === 2 && formType === "personal-details") || (+insuranceState?.leadDetail?.paid_from_agency === 1 && formType === "payment-details")) &&
                    <>
                        <ReactSelect
                            options={insuranceState?.leadDetail?.application_type_id === APP_TYPE_AGENCY ? commonState?.common?.payment_method_agency : commonState?.common?.payment_method}
                            value={commonState?.common?.payment_method?.find((v: any) => personalDetails.payment_method_id === v.value) || {}}
                            placeholder={"Payment Method*"}
                            // isDisabled={insuranceState?.leadDetail?.is_payment_freezed || false}
                            isDisabled={false}
                            fieldKey="payment_method_id"
                            handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} fieldSetClassName="col-md-6"
                            errorField={errors.payment_method_id} />

                        <ReactSelect
                            options={paymentTermOptions}
                            value={paymentTermOptions?.find((v: any) => personalDetails.payment_term_id === v.value) || null}
                            placeholder={"Payment Term*"}
                            isDisabled={false}
                            handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} fieldKey="payment_term_id"
                            fieldSetClassName="col-md-6"
                            errorField={errors.payment_term_id} />

                        {
                            commonState?.common?.payment_term?.find((v: any) => personalDetails?.payment_term_id === v.value)?.label === "Installment"
                                ?
                                <ReactSelect
                                    options={commonState?.common?.payment_tenure?.filter((pay: any) => pay.payment_method === personalDetails.payment_method_id && pay.payment_term === personalDetails.payment_term_id)}
                                    value={commonState?.common?.payment_tenure?.find((v: any) => personalDetails.payment_tenure_id === v.value) || null}
                                    placeholder={"Payment Tenure*"}
                                    isDisabled={false}
                                    fieldKey="payment_tenure_id"
                                    handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)}
                                    fieldSetClassName="col-md-6"
                                    errorField={errors.payment_tenure_id} />
                                : ''
                        }
                        {
                            personalDetails?.payment_tenure_id && [APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(insuranceState?.leadDetail?.application_type_id)
                                ?
                                (
                                    <>
                                        {
                                            times(commonState?.common?.payment_tenure?.find((pt: any) => personalDetails.payment_tenure_id === pt.value)?.label?.split(' ')[0], (idx: number) => {
                                                const placeholder = `Expected ${idx + 1}${nthNumber(idx + 1)} Installment`
                                                return (
                                                    <React.Fragment key={'expected_installment' + idx + 1}>
                                                        {/** Adding expected installment amount using expected installment date fieldItem */}
                                                        <fieldset className="col-md-6" key={'expected_installment_amount' + idx + 1}>
                                                            <div className="material">
                                                                <CurrencyInputField
                                                                    inputProps={{
                                                                        id: 'expected' + (idx + 1) + "_amount",
                                                                        type: "text",
                                                                        name: 'expected' + (idx + 1) + "_amount",
                                                                        placeholder: " ",
                                                                        value: personalDetails?.expected_installment?.[idx + 1]?.amount || '',
                                                                        className: "form-input",
                                                                        disabled: false,
                                                                    }}

                                                                    onChange={(event: any) => {
                                                                        changeHandler((removeCurrencyFormatting(event?.target?.value) || 0), `expected_installment_amount-${idx + 1}`)
                                                                    }}
                                                                />
                                                                <label data-label={`Expected ${idx + 1}${nthNumber(idx + 1)} Amount*`} className="form-label label-txt"></label>
                                                                {
                                                                    errors?.expected_installment?.[idx + 1]?.amount ?
                                                                        <span className="error-txt">{errors?.expected_installment?.[idx + 1]?.amount}</span>
                                                                        : ''
                                                                }
                                                            </div>
                                                        </fieldset>
                                                        <fieldset className="col-md-6 date-picker-detail">
                                                            <div className={"material animation-effect" + (personalDetails?.expected_installment?.[idx + 1]?.date ? ' active-label' : '')}>
                                                                <ExpectedInstallmentDate
                                                                    onChange={(e: Date) => changeHandler(e, `expected_installment_date-${idx + 1}`)}
                                                                    selected={personalDetails?.expected_installment?.[idx + 1]?.date}
                                                                    dateFormat="dd MMM, yyyy"
                                                                    disabled={disable}
                                                                    installmentNumber={idx + 1}
                                                                    prevInstallmentDate={personalDetails?.expected_installment?.[idx]?.date || null}
                                                                    text={placeholder + " Date*"}
                                                                    source="detailSection"
                                                                />
                                                                {
                                                                    errors?.expected_installment?.[idx + 1]?.date ?
                                                                        <span className="error-txt">{errors?.expected_installment?.[idx + 1]?.date}</span>
                                                                        : ''
                                                                }
                                                            </div>
                                                        </fieldset>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                        {
                                            formType === "payment-details" &&
                                            times(commonState?.common?.payment_tenure?.find((pt: any) => personalDetails.payment_tenure_id === pt.value)?.label?.split(' ')[0], (idx: number) => {
                                                const placeholder = `${idx + 1}${nthNumber(idx + 1)} Installment`
                                                return (
                                                    <React.Fragment key={'actual_installment' + idx + 1}>
                                                        {/** Adding expected installment amount using expected installment date fieldItem */}
                                                        <fieldset className="col-md-6" key={'actual_installment_amount' + idx + 1}>
                                                            <div className="material">
                                                                <CurrencyInputField
                                                                    inputProps={{
                                                                        id: 'actual' + (idx + 1) + "_amount",
                                                                        type: "text",
                                                                        name: 'actual' + (idx + 1) + "_amount",
                                                                        placeholder: " ",
                                                                        value: personalDetails?.actual_installment?.[idx + 1]?.amount || '',
                                                                        className: "form-input",
                                                                        disabled: false,
                                                                    }}

                                                                    onChange={(event: any) => {
                                                                        changeHandler((removeCurrencyFormatting(event?.target?.value) || 0), `actual_installment_amount-${idx + 1}`)
                                                                    }}
                                                                />
                                                                <label data-label={`${idx + 1}${nthNumber(idx + 1)} Installment  Amount`} className="form-label"></label>
                                                                {
                                                                    errors?.actual_installment?.[idx + 1]?.amount ?
                                                                        <span className="error-txt">{errors?.actual_installment?.[idx + 1]?.amount}</span>
                                                                        : ''
                                                                }
                                                            </div>
                                                        </fieldset>
                                                        <fieldset className="col-md-6 date-picker-detail">
                                                            <div className={"material animation-effect datepicker-width-400 " + (personalDetails?.actual_installment?.[idx + 1]?.date ? ' active-label' : '')}>
                                                                {/* <ExpectedInstallmentDate
                                                                    onChange={(e: Date) => changeHandler(e, `actual_installment_date-${idx + 1}`)}
                                                                    selected={personalDetails?.actual_installment?.[idx + 1]?.date}
                                                                    dateFormat="dd MMM, yyyy"
                                                                    disabled={disable}
                                                                    installmentNumber={idx + 1}
                                                                    prevInstallmentDate={personalDetails?.actual_installment?.[idx]?.date || null}
                                                                    text={placeholder + " Date"}
                                                                    source="detailSection"
                                                                /> */}
                                                                <InsuranceDate
                                                                    text={placeholder + " Date & Time"}
                                                                    insurancePolicyDate={personalDetails?.actual_installment?.[idx + 1]?.date ? new Date(personalDetails.actual_installment?.[idx + 1].date) : ''}
                                                                    onChange={(e: Date) => changeHandler(e, `actual_installment_date-${idx + 1}`)}
                                                                    installment={true}
                                                                    enableAllDates={enableFutureDates ? true : false} />
                                                                {
                                                                    errors?.actual_installment?.[idx + 1]?.date ?
                                                                        <span className="error-txt">{errors?.actual_installment?.[idx + 1]?.date}</span>
                                                                        : ''
                                                                }
                                                            </div>
                                                        </fieldset>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </>
                                )
                                : undefined
                        }
                    </>
                }

                {
                    formType === "payment-details" &&

                    <>

                        {
                            [APP_TYPE_AGENCY].includes(insuranceState?.leadDetail?.application_type_id) &&
                            <ReactSelect
                                options={commonState?.common?.credit_terms}
                                value={commonState?.common?.credit_terms?.find((v: any) => (+personalDetails.expected_credit_term) === +v.value) || null}
                                placeholder={"Credit Term"}
                                isDisabled={false}
                                fieldKey="credit_term"
                                handleChange={(e: any, fieldKey: string) => changeHandler(e, 'credit_term')}
                                fieldSetClassName="col-md-6"
                                errorField={errors.credit_term} />
                        }


                        <fieldset className="col-md-12">
                            <div className="material" title={personalDetails?.premium_amount || ''}>
                                <CurrencyInputField
                                    inputProps={{
                                        id: "amount",
                                        type: "text",
                                        name: "premium_amount",
                                        placeholder: " ",
                                        value: personalDetails?.premium_amount || "",
                                        className: "form-input",
                                        disabled: false
                                        
                                    }}
                                    onChange={(e: any) => changeHandler(e, 'premium_amount')}
                                />

                                <label data-label={`Total Premium Amount Received ${[APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(insuranceState?.leadDetail?.application_type_id) ? "*" : ""}`} className="form-label"></label>
                                {
                                    errors.premium_amount ?
                                        <span className="error-txt">{errors.premium_amount}</span>
                                        : ''
                                }
                            </div>
                        </fieldset>

                        {
                            !personalDetails?.payment_tenure_id && [APP_TYPE_STANDALONE, APP_TYPE_RENEWAL, APP_TYPE_AGENCY].includes(insuranceState?.leadDetail?.application_type_id) &&

                            <fieldset className="col-md-12 date-picker-detail">
                                <div className={"material animation-effect" + (personalDetails?.premium_amount_date ? ' active-label' : '')}>
                                    <div className="datepicker">
                                        <InsuranceDate
                                            text={`Premium Amount Received Date & Time ${[APP_TYPE_STANDALONE, APP_TYPE_RENEWAL].includes(insuranceState?.leadDetail?.application_type_id) ? "*" : ""}`}
                                            insurancePolicyDate={personalDetails.premium_amount_date ? new Date(personalDetails.premium_amount_date) : null}
                                            onChange={(e: Date) => changeHandler(e, 'premium_amount_date')}
                                            minDate={minDateForAgency}
                                            enableAllDates={([APP_TYPE_AGENCY].includes(insuranceState?.leadDetail?.application_type_id) ? true : false)} 
                                            disabled={false}
                                            
                                            />
                                        {/* <label data-label={"Premium Amount Received Date and Time*"} className="form-label"></label> */}
                                        {
                                            errors.premium_amount_date ?
                                                <span className="error-txt">{errors.premium_amount_date}</span>
                                                : ''
                                        }
                                    </div>
                                </div>
                            </fieldset>
                        }


                        {
                            !personalDetails?.payment_tenure_id && [APP_TYPE_AGENCY].includes(insuranceState?.leadDetail?.application_type_id) &&
                            <fieldset className="col-md-12">
                                <div className="material" title={personalDetails?.payment_reference_no}>
                                    <InputField
                                        inputProps={{
                                            id: "payment_reference_no",
                                            name: "payment_reference_no",
                                            className: "form-input",
                                            type: "text",
                                            value: personalDetails.payment_reference_no || '',
                                            maxLength: "",
                                            disabled: false,
                                            placeholder: " "
                                        }}
                                        onChange={(e: any) => changeHandler(e, 'payment_reference_no')}
                                    />
                                    <label data-label={`Payment Reference No.`} className="form-label"></label>
                                    {
                                        errors.payment_reference_no ?
                                            <span className="error-txt">{errors.payment_reference_no}</span>
                                            : ''
                                    }
                                </div>
                            </fieldset>

                        }


                        {
                            personalDetails?.payment_tenure_id > 0 && [APP_TYPE_AGENCY].includes(insuranceState?.leadDetail?.application_type_id) &&
                            times(commonState?.common?.payment_tenure?.find((pt: any) => personalDetails.payment_tenure_id === pt.value)?.label?.split(' ')[0], (idx: number) => {
                                const placeholder = `Premium amount Date & Time ${idx + 1}`
                                return (

                                    <div key={'expected_installment_reference_no' + idx + 1} className='agency-payment-form'>
                                        {/** Adding expected installment amount using expected installment date fieldItem */}

                                        <fieldset className="col-md-12 date-picker-detail">
                                            <div className={"material animation-effect" + (personalDetails?.expected_installment_reference_no?.[idx + 1]?.date ? ' active-label' : '')}>
                                                <ExpectedInstallmentDate
                                                    onChange={(e: Date) => changeHandler(e, `expected_installment_reference_no_date-${idx + 1}`)}
                                                    selected={personalDetails?.expected_installment_reference_no?.[idx + 1]?.date}
                                                    dateFormat="dd MMM, yyyy h:mm aa"
                                                    disabled={disable}
                                                    installmentNumber={idx + 1}
                                                    prevInstallmentDate={personalDetails?.expected_installment_reference_no?.[idx]?.date || null}
                                                    text={placeholder}
                                                    source="detailSection"
                                                    showTimeSelect={true}
                                                    minDateAgency={minDateForAgency}
                                                />
                                                {
                                                    errors?.expected_installment?.[idx + 1]?.date ?
                                                        <span className="error-txt">{errors?.expected_installment?.[idx + 1]?.date}</span>
                                                        : ''
                                                }
                                            </div>
                                        </fieldset>

                                        <fieldset className="col-md-12" key={'expected_reference_no' + idx + 1}>
                                            <div className="material">
                                                <InputField
                                                    inputProps={{
                                                        id: 'expected' + (idx + 1) + "_reference_no",
                                                        name: 'expected' + (idx + 1) + "_reference_no",
                                                        className: "form-input",
                                                        type: "text",
                                                        maxLength: "",
                                                        value: personalDetails?.expected_installment_reference_no?.[idx + 1]?.reference_no || '',
                                                        disabled: false,
                                                        placeholder: " "
                                                    }}
                                                    onChange={(e: any) => changeHandler(e, `expected_installment_reference_no_amount-${idx + 1}`)}
                                                />

                                                <label data-label={`Payment Reference no ${idx + 1}`} className="form-label label-txt"></label>
                                                {
                                                    errors?.expected_installment?.[idx + 1]?.reference_no ?
                                                        <span className="error-txt">{errors?.expected_installment?.[idx + 1]?.reference_no}</span>
                                                        : ''
                                                }
                                            </div>
                                        </fieldset>

                                    </div>
                                )
                            })

                        }
                    </>
                }

                {
                    formType === "personal-details" &&

                    <ReactSelect
                        options={commonState?.common?.YesAndNo}
                        value={commonState?.common?.YesAndNo?.find((v: any) => personalDetails.copy_of_insurance === v.value) || null}
                        placeholder={insuranceState?.leadDetail?.application_type_id !== APP_TYPE_WITH_UCF ? "Physical Copy of Insurance Required" : "Physical Copy of Insurance Required *"}
                        isDisabled={false}
                        handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} fieldKey="copy_of_insurance"
                        fieldSetClassName="col-md-12"
                        errorField={errors.copy_of_insurance} />
                }


                {
                    formType === "personal-details" && [APP_TYPE_AGENCY].includes(insuranceState?.leadDetail?.application_type_id) &&
                    <>
                        <ReactSelect
                            options={commonState?.common?.YesAndNo}
                            value={commonState?.common?.YesAndNo?.find((v: any) => +personalDetails.paid_from_agency === +v.value) || []}
                            placeholder={"Paid From Agency*"}
                            isDisabled={+insuranceState.leadDetail.paid_from_agency === 1 ? true : false}
                            handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} fieldKey="paid_from_agency"
                            fieldSetClassName="col-md-6"
                        />
                        {
                            +personalDetails?.paid_from_agency === 1 &&
                            <ReactSelect
                                options={commonState?.common?.paid_to_whom_agency}
                                value={commonState?.common?.paid_to_whom_agency?.find((v: any) => +personalDetails.paid_to_whom === +v.value) || []}
                                placeholder={"Paid to whom*"}
                                isDisabled={insuranceState.leadDetail.bulk_payment_id}
                                handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} fieldKey="paid_to_whom"
                                fieldSetClassName="col-md-6"
                                errorField={errors['paid_to_whom']}

                            />
                        }

                        {
                            +personalDetails?.paid_from_agency === 1 &&
                            <ReactSelect
                                options={commonState?.common?.payment_way}
                                value={commonState?.common?.payment_way?.find((v: any) => +personalDetails.payment_way === +v.value) || []}
                                placeholder={"Payment Way*"}
                                isDisabled={insuranceState.leadDetail.isPaymentWayDisabled || paymentwayDisable}
                                handleChange={(e: any, fieldKey: string) => changeHandler(e, fieldKey)} fieldKey="payment_way"
                                fieldSetClassName="col-md-6"
                                errorField={errors['payment_way']}

                            />
                        }
                    </>
                }

            </div>
        </div>
    );
}
